import { productsNames } from '../../data/ui';

export const removeKeysPlans = (plans, isPlan = false) => {
  const newArr = plans.map((plan) => {
    const { hora, array_dias, plan_id, ...plan_ } = plan;
    return {
      ...plan_,
      productsOf: isPlan
        ? productsNames.plans
        : productsNames.personalisedPlans,
    };
  });

  return newArr;
};
