import { Link } from 'react-router-dom';
import { WrapperForm } from '../../wrappers/WrapperForm';
import { NavbarMain } from '../../components';
export const PageMessageNewPassword = () => {
  return (
    <>
      <NavbarMain />
      <WrapperForm image="register" title="¡Contraseña creada con éxito!">
        <p className="paragraph font__klinicSlabbold my__3">
          Excelente, ahora puedes usar tu nueva contraseña para acceder a tu
          cuenta de Don Santi
        </p>
        <div className="my__3 text__center">
          <Link to="/inicio-sesion">
            <button className="btn btn__outline__white">Iniciar Sesión</button>
          </Link>
        </div>
      </WrapperForm>
    </>
  );
};
