import setBillInfo from './sendBill';

const baseUrl = process.env.REACT_APP_API_PRO;
const urlApiSunat = process.env.REACT_APP_API_SUNAT;

const fetchNotToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}api/v1/${endpoint}`;

  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchWithToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}api/v1/${endpoint}`;
  const token = JSON.parse(localStorage.getItem('auth'))?.token || '';

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer  ' + token,
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchWithTokenWithoutBody = async (endpoint) => {
  const token = JSON.parse(localStorage.getItem('auth'))?.token || '';
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${baseUrl}api/v1/${endpoint}`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

const fetchFile = (endpoint, data, method = 'POST') => {
  const url = `${baseUrl}api/v1/${endpoint}`;
  const token = JSON.parse(localStorage.getItem('auth'))?.token || '';

  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('Authorization', 'Bearer ' + token);

  const formdata = new FormData();
  formdata.append('venta_id', data.id);
  formdata.append('voucher', data.file);

  const requestOptions = {
    method,
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  };

  return fetch(url, requestOptions);
};

 /* CREATE Document ---------------------------------------------- */
const createDocument = async (typeDocument, documentValue, documentId, fileName , serieId,infoProducts) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const info = setBillInfo(fileName,typeDocument,documentValue,documentId,serieId,infoProducts);
  const body = JSON.stringify(info);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${urlApiSunat}personas/v1/sendBill`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export { fetchNotToken, fetchWithToken, fetchFile  , fetchWithTokenWithoutBody , createDocument};
