import { productsNames } from '../../../data/ui';
import { fetchFile, fetchWithToken } from '../../../helpers/api/fetch';
import { methods_api } from '../../../helpers/api/methods_api';
import { typesInformation } from '../../types/e-commerce';
import { typesSale } from '../../types/e-commerce/sale';

export const addPlanToShoppingCart = (plan) => ({
  type: typesSale.addplan,
  payload: plan,
});

export const addPromotionToShoppingCart = (promotion) => ({
  type: typesSale.addPromotion,
  payload: promotion,
});

export const addPersonalisedBreadToShoppingCart = (bread) => ({
  type: typesSale.addPersonalisedBread,
  payload: bread,
});

export const addProductToShoppingCart = (product) => ({
  type: typesSale.addProduct,
  payload: product,
});

export const addPersonalisedPlanToShoppingCart = (plan) => ({
  type: typesSale.addPersonalisedPlan,
  payload: plan,
});

export const calculateProductsTotal = () => {
  return (dispatch, getState) => {
    const {
      planes,
      promociones,
      arma_tu_pan,
      productos,
      planes_personalizados,
    } = getState().sale;

    const products_total =
      planes.length +
      planes_personalizados.length +
      promociones.length +
      arma_tu_pan.length +
      productos.length;

    dispatch(setProductsTotal(products_total));
    sessionStorage.setItem('products_total', JSON.stringify(products_total));
  };
};

const setProductsTotal = (total) => ({
  type: typesSale.calculateProductsTotal,
  payload: total,
});
export const updateNote = ({ id, note, productsOf }) => {
  return (dispatch, getState) => {
    let list_products;

    if (productsOf === productsNames.plans) {
      const { planes } = getState().sale;
      list_products = planes;
    } else if (productsOf === productsNames.promotions) {
      const { promociones } = getState().sale;
      list_products = promociones;
    } else if (productsOf === productsNames.personalisedBread) {
      const { arma_tu_pan } = getState().sale;
      list_products = arma_tu_pan;
    } else if (productsOf === productsNames.products) {
      const { productos } = getState().sale;
      list_products = productos;
    } else if (
      productsOf === productsNames.tabAllProducts ||
      productsOf === productsNames.tabBreads
    ) {
      const { planes_personalizados } = getState().sale;
      list_products = planes_personalizados;
    } else if (productsOf === productsNames.personalisedPlans) {
      const { planes_personalizados } = getState().sale;
      list_products = planes_personalizados;
    }

    const newProducts = list_products.map((product) => {
      if (product.id === id) {
        product.preferencia = note;
      }
      return product;
    });

    if (productsOf === productsNames.plans) {
      dispatch(updatePlans(newProducts));
      dispatch(updateSales(productsNames.plans));
    } else if (productsOf === productsNames.promotions) {
      dispatch(updatePromotions(newProducts));
      dispatch(updateSales(productsNames.promotions));
    } else if (productsOf === productsNames.personalisedBread) {
      dispatch(updatePersonalisedBreads(newProducts));
      dispatch(updateSales(productsNames.personalisedBread));
    } else if (productsOf === productsNames.products) {
      dispatch(updateProducts(newProducts));
      dispatch(updateSales(productsNames.products));
    } else if (
      productsOf === productsNames.tabAllProducts ||
      productsOf === productsNames.tabBreads
    ) {
      dispatch(updatePersonalisedPlans(newProducts));
      dispatch(updateSales(productsNames.personalisedPlans));
    } else if (productsOf === productsNames.personalisedPlans) {
      dispatch(updatePersonalisedPlans(newProducts));
      dispatch(updateSales(productsNames.personalisedPlans));
    }
  };
};

export const updateSubTotal = (price) => {
  return (dispatch, getState) => {
    const { productos, arma_tu_pan, promociones } = getState().sale;
    const subtotals = [];
    if (productos.length > 0) {
      productos.forEach((p) => {
        subtotals.push(p.subtotal_);
      });
    }
    if (arma_tu_pan.length > 0) {
      arma_tu_pan.forEach((p) => {
        subtotals.push(p.subtotal_);
      });
    }

    if (promociones.length > 0) {
      promociones.forEach((p) => {
        subtotals.push(p.subtotal_);
      });
    }

    let subtotal_ = subtotals.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    dispatch(setSubTotal(subtotal_));
  };
};

export const setSubTotal = (subtotal) => ({
  type: typesSale.calculateSubTotal,
  payload: subtotal,
});

export const updateSubTotalCant = (productsOf, cant_month, subtotal, id) => {
  return (dispatch, getState) => {
    let products_or_plans = [];
    if (productsOf === productsNames.personalisedBread) {
      const { arma_tu_pan } = getState().sale;
      products_or_plans = arma_tu_pan;
    } else if (productsOf === productsNames.personalisedPlans) {
      const { planes_personalizados } = getState().sale;
      products_or_plans = planes_personalizados;
    } else if (productsOf === productsNames.plans) {
      const { planes } = getState().sale;
      products_or_plans = planes;
    } else if (productsOf === productsNames.products) {
      const { productos } = getState().sale;
      products_or_plans = productos;
    } else if (productsOf === productsNames.promotions) {
      const { promociones } = getState().sale;
      products_or_plans = promociones;
    }

    if (products_or_plans.length > 0) {
      const arrProductsOrPlans = products_or_plans.map((product_or_plan) => {
        if (product_or_plan.id === id) {
          product_or_plan.subtotal_ = subtotal;
          product_or_plan.cantidad = cant_month;
        }
        if (
          (productsOf === productsNames.plans ||
            productsOf === productsNames.personalisedPlans) &&
          product_or_plan.id === id
        ) {
          product_or_plan.meses = cant_month;
        }
        return product_or_plan;
      });

      if (productsOf === productsNames.personalisedBread) {
        dispatch(updatePersonalisedBreads(arrProductsOrPlans));
        dispatch(updateSales(productsNames.personalisedBread));
      } else if (productsOf === productsNames.personalisedPlans) {
        dispatch(updatePersonalisedPlans(arrProductsOrPlans));
        dispatch(updateSales(productsNames.personalisedPlans));
      } else if (productsOf === productsNames.plans) {
        dispatch(updatePlans(arrProductsOrPlans));
        dispatch(updateSales(productsNames.plans));
      } else if (productsOf === productsNames.products) {
        dispatch(updateProducts(arrProductsOrPlans));
        dispatch(updateSales(productsNames.products));
      } else if (productsOf === productsNames.promotions) {
        dispatch(updatePromotions(arrProductsOrPlans));
        dispatch(updateSales(productsNames.promotions));
      }
      dispatch(updateSubTotal());
    }
  };
};

export const deleteProductCart = (productsOf, id) => {
  return (dispatch, getState) => {
    let products_or_plans = [];
    if (productsOf === productsNames.personalisedBread) {
      const { arma_tu_pan } = getState().sale;
      products_or_plans = arma_tu_pan;
    } else if (
      productsOf === productsNames.personalisedPlans ||
      productsOf === 'personalised_bread'
    ) {
      const { planes_personalizados } = getState().sale;
      products_or_plans = planes_personalizados;
    } else if (productsOf === productsNames.plans) {
      const { planes } = getState().sale;
      products_or_plans = planes;
    } else if (productsOf === productsNames.products) {
      const { productos } = getState().sale;
      products_or_plans = productos;
    } else if (productsOf === productsNames.promotions) {
      const { promociones } = getState().sale;
      products_or_plans = promociones;
    }

    let arrProductsOrPlans = [];
    if (products_or_plans.length > 0) {
      arrProductsOrPlans = products_or_plans.filter((product_or_plan) => {
        return product_or_plan.id !== id;
      });

      if (productsOf === productsNames.personalisedBread) {
        dispatch(updatePersonalisedBreads(arrProductsOrPlans));
        dispatch(updateSales(productsNames.personalisedBread));
      } else if (productsOf === productsNames.personalisedPlans) {
        dispatch(updatePersonalisedPlans(arrProductsOrPlans));
        dispatch(updateSales(productsNames.personalisedPlans));
      } else if (productsOf === productsNames.plans) {
        dispatch(updatePlans(arrProductsOrPlans));
        dispatch(updateSales(productsNames.plans));
      } else if (productsOf === productsNames.products) {
        dispatch(updateProducts(arrProductsOrPlans));
        dispatch(updateSales(productsNames.products));
      } else if (productsOf === productsNames.promotions) {
        dispatch(updatePromotions(arrProductsOrPlans));
        dispatch(updateSales(productsNames.promotions));
      }
      // window.location.reload();
    }
    dispatch(updateSubTotal());
    dispatch(calculateProductsTotal());
  };
};

export const updatePersonalisedBreads = (personalisedBreads) => ({
  type: typesSale.updatePersonalisedBreads,
  payload: personalisedBreads,
});

export const updatePersonalisedPlans = (personalisedPlans) => ({
  type: typesSale.updatePersonalisedPlans,
  payload: personalisedPlans,
});

export const updatePlans = (plans) => ({
  type: typesSale.updatePlans,
  payload: plans,
});

export const updatePromotions = (promotions) => ({
  type: typesSale.updatePromotions,
  payload: promotions,
});

export const updateProducts = (products) => ({
  type: typesSale.updateProducts,
  payload: products,
});

export const clearCart = () => {
  return (dispatch) => {
    dispatch(setClearCart());
    sessionStorage.clear();
  };
};

export const setClearCart = () => ({
  type: typesSale.clearCart,
});

export const updateSales = (productsOf) => {
  return (dispatch, getState) => {
    const {
      planes,
      productos,
      promociones,
      arma_tu_pan,
      planes_personalizados,
    } = getState().sale;

    if (productsOf === productsNames.plans) {
      sessionStorage.setItem('plans', JSON.stringify(planes));
    } else if (productsOf === productsNames.products) {
      sessionStorage.setItem('products', JSON.stringify(productos));
    } else if (productsOf === productsNames.promotions) {
      sessionStorage.setItem('promotions', JSON.stringify(promociones));
    } else if (productsOf === productsNames.personalisedBread) {
      sessionStorage.setItem('personalisedBread', JSON.stringify(arma_tu_pan));
    } else if (productsOf === productsNames.personalisedPlans) {
      sessionStorage.setItem(
        'personalisedPlan',
        JSON.stringify(planes_personalizados)
      );
    }
  };
};

export const setIdUser = (id) => ({
  type: typesSale.userId,
  payload: id,
});

export const toggleTermsPolicy = (isChecked) => ({
  type: typesSale.toggleTermsPolicy,
  payload: isChecked,
});

export const shoppingCartProducts = () => {
  return (dispatch, getState) => {
    const {
      planes,
      planes_personalizados,
      promociones,
      arma_tu_pan,
      productos,
    } = getState().sale;

    const products = [];

    if (planes.length > 0 || planes_personalizados.length > 0) {
      const arr_plans = [...planes, ...planes_personalizados];
      arr_plans.forEach(({ meses, imagen, nombre, precio }) => {
        const plan_ = {
          meses,
          imagen,
          nombre,
          precio,
          productOf: productsNames.plans,
        };
        products.push(plan_);
      });
    }

    if (
      promociones.length > 0 ||
      arma_tu_pan.length > 0 ||
      productos.length > 0
    ) {
      const products_ = [...arma_tu_pan, ...productos, ...promociones];
      products_.forEach(({ cantidad, imagen, nombre, precio }) => {
        const product = {
          cantidad,
          imagen,
          nombre,
          precio,
          productOf: productsNames.products,
        };
        products.push(product);
      });
    }

    return products;
  };
};

export const calculateTotal = (delivery_price = '') => {
  return (dispatch, getState) => {
    const { subtotal } = getState().sale;
    const {
      delivery: { district_price },
    } = getState().information;

    if (district_price && delivery_price === '') {
      const total = subtotal + parseFloat(district_price);
      dispatch(setTotal(total));
    } else if (delivery_price !== '') {
      const total = subtotal + delivery_price;
      dispatch(setTotal(total));
    } else if (district_price === '' && delivery_price === '') {
      dispatch(setTotal(0));
    }
  };
};

export const setTotal = (total) => ({
  type: typesSale.calculateTotal,
  payload: total,
});

export const setMethodPayment = (method) => ({
  type: typesSale.updateMethodPayment,
  payload: method,
});

export const setTypeDocument = (type_document) => ({
  type: typesSale.updateTypeDocument,
  payload: type_document,
});

export const getSales = () => {
  return async (dispatch) => {
    const id = JSON.parse(localStorage.getItem('auth')).id ?? 0;

    if (id === 0) {
      dispatch(setSales([]));
      return;
    }
    try {
      const resp = await fetchWithToken(methods_api.getSales);
      const body = await resp.json();
      if (body?.venta) {
        const sales = formattingOrders(body.venta);

        dispatch(setSales(sales));
      } else {
        dispatch(setSales([]));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

const setSales = (sale) => ({
  type: typesInformation.sale,
  payload: sale,
});

const formattingOrders = (sales) => {
  if (sales.length > 0) {
    const newSales = [];

    sales.forEach((sale) => {
      const detail = sale.detalle[0];

      const personalisedBreads = detail?.venta_arma_tu_pan ?? [];
      const plans = detail?.venta_plan ?? [];
      const promotions = detail?.venta_promocion ?? [];
      const products = detail?.venta_producto ?? [];

      const subtotal =
        parseFloat(sale.total) - parseFloat(sale.delivery_precio) + '';

      let newSale = {
        ...sale,
        productos: [],
        subtotal,
      };
      personalisedBreads?.forEach((bread) => {
        const producto = {
          name: bread.nombre_pan,
          cant: bread.cantidad,
          price: bread.precio,
          note: bread.preferencias,
          bread: bread.producto_pan,
          fill: bread.producto_relleno,
          total: bread.total,
          imagen: bread.producto_pan_imagen,
          productOf: productsNames.personalisedBread,
        };
        newSale.productos.push(producto);
      });
      plans?.forEach((plan) => {
        const producto = {
          name: plan.plan,
          month: plan.meses,
          price: plan.precio,
          note: plan.preferencias,
          days: plan.texto_dias,
          products: plan.productos,
          productOf: productsNames.plans,
          total: plan.total,
          imagen: plan.imagen,
        };
        newSale.productos.push(producto);
      });

      promotions?.forEach((promotion) => {
        const producto = {
          name: promotion.promocion,
          cant: promotion.cantidad,
          price: promotion.precio,
          note: promotion.preferencias,
          products: promotion.productos,
          productOf: productsNames.promotions,
          total: promotion.total,
          imagen: promotion.imagen,
        };
        newSale.productos.push(producto);
      });

      products?.forEach((product) => {
        const producto = {
          name: product.producto,
          cant: product.cantidad,
          price: product.precio,
          note: product.preferencias,
          productOf: productsNames.products,
          total: product.total,
          imagen: product.producto_imagen,
        };
        newSale.productos.push(producto);
      });

      delete newSale.detalle;
      newSales.push(newSale);
    });
    return newSales;
  }
};

export const updateRuc = (ruc) => ({
  type: typesSale.updateRuc,
  payload: ruc,
});
export const updateRazon = (razon) => ({
  type: typesSale.updateRazon,
  payload: razon,
});
export const updateDireccion = (direccion) => ({
  type: typesSale.updateDireccion,
  payload: direccion,
});

export const updateIp = (ip) => ({
  type: typesSale.ip,
  payload: ip,
});

export const getSessionKey = () => {
  return async (dispatch, getState) => {
    const sale = getState().sale;
    const { method_pay, type_document } = getState().sale;
    const {
      products_total,
      planes_personalizados,
      subtotal,
      total,
      sale_id,
      ...data
    } = sale;

    const new_sale = {
      ...data,
      privacy_policy: 1,
      planes: [],
      promociones: [],
      arma_tu_pan: [],
      productos: [],
    };

    const arr_plans = [...sale.planes, ...sale.planes_personalizados];

    if (arr_plans.length > 0) {
      const plans = formattingProducts(arr_plans);
      new_sale.planes = plans;
    }

    if (sale.promociones.length > 0) {
      const promotions = formattingProducts(sale.promociones);
      new_sale.promociones = promotions;
    }

    if (sale.productos.length > 0) {
      const products = formattingProducts(sale.productos);
      new_sale.productos = products;
    }

    if (sale.arma_tu_pan.length > 0) {
      const personalisedBreads = formattingProducts(
        sale.arma_tu_pan,
        productsNames.personalisedBread
      );
      new_sale.arma_tu_pan = personalisedBreads;
    }

    let sale_;
    if (method_pay === 2) {
      const { file, ...data } = new_sale;
      sale_ = { ...data };
    } else {
      sale_ = new_sale;
    }

    if (type_document === 0) {
      delete sale_.ruc;
      delete sale_.fiscal_address;
      delete sale_.business_name;
    }
    try {
      const resp = await fetchWithToken(methods_api.sale, sale_, 'POST');
      const body = await resp.json();
      dispatch(setSaleID(body.venta[0].id));
      return body;
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const formattingSale = (file = '') => {
  return async (dispatch, getState) => {
    const sale = getState().sale;
    const { method_pay } = getState().sale;
    const {
      products_total,
      planes_personalizados,
      subtotal,
      total,
      sale_id,
      ...data
    } = sale;

    const new_sale = {
      ...data,
      privacy_policy: 1,
      planes: [],
      promociones: [],
      arma_tu_pan: [],
      productos: [],
    };

    if (method_pay === 2) {
      new_sale.file = file;
    }

    const arr_plans = [...sale.planes, ...sale.planes_personalizados];

    if (arr_plans.length > 0) {
      const plans = formattingProducts(arr_plans);
      new_sale.planes = plans;
    }

    if (sale.promociones.length > 0) {
      const promotions = formattingProducts(sale.promociones);
      new_sale.promociones = promotions;
    }

    if (sale.productos.length > 0) {
      const products = formattingProducts(
        sale.productos,
        productsNames.products
      );
      new_sale.productos = products;
    }

    if (sale.arma_tu_pan.length > 0) {
      const personalisedBreads = formattingProducts(
        sale.arma_tu_pan,
        productsNames.personalisedBread
      );
      new_sale.arma_tu_pan = personalisedBreads;
    }

    const { success, msg, info } = await dispatch(makePurchase(new_sale));
    if (success) {
      return { success, info: info };
    } else {
      return { success, msg };
    }
  };
};

const formattingProducts = (array, productOf = '') => {
  const newArr = [];

  array.forEach((item) => {
    const { id, imagen, precio, subtotal_, ...data_ } = item;
    const products_ = {
      ...data_,
      productos: [],
    };

    if (productOf === productsNames.products) {
      delete products_.nombre;
    }

    if (item.productos.length > 0 && productOf === '') {
      item.productos.forEach(({ product_id, product_cant }) => {
        const product_ = {
          producto_id: product_id,
          cantidad: product_cant,
        };
        products_.productos.push(product_);
      });

      newArr.push(products_);
    } else if (
      item.productos.length === 0 ||
      productOf === productsNames.personalisedBread
    ) {
      delete products_.productos;
      newArr.push(products_);
    }
  });

  return newArr;
};

const makePurchase = (sale) => {
  return async (dispatch, getState) => {
    const { method_pay, type_document } = getState().sale;
    let sale_;
    if (method_pay === 2) {
      const { file, ...data } = sale;
      sale_ = { ...data };
    } else {
      sale_ = sale;
    }

    if (type_document === 0) {
      delete sale_.ruc;
      delete sale_.fiscal_address;
      delete sale_.business_name;
    }
    // debugger;
    try {
      const resp = await fetchWithToken(methods_api.sale, sale_, 'POST');
      const body = await resp.json();

      if (body.status === 'error') {
        return { success: false, msg: body.message };
      }
      if (body?.venta.length > 0) {
        dispatch(setSaleID(body.venta[0].id));
        if (method_pay === 2) {
          const { success } = await dispatch(uploadVoucher(sale.file));
          if (success) {
            const { success } = await dispatch(makePurchaseFinal());
            return { success };
          }
        } else {
          // const { success } = await dispatch(makePurchaseFinal());
          // return { success };
          return { success: true, msg: ' Bien hecho', info: body };
        }
      }
    } catch (error) {
      // debugger;
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

const setSaleID = (id) => ({
  type: typesSale.saleId,
  payload: id,
});

const uploadVoucher = (file) => {
  return async (dispatch, getState) => {
    const { sale_id } = getState().sale;

    try {
      const resp = await fetchFile(methods_api.voucher, { file, id: sale_id });
      const body = await resp.json();

      if (body.status === 'success') {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      // console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const makePurchaseFinal = () => {
  return async (dispatch, getState) => {
    const { sale_id, user_id } = getState().sale;
    try {
      const resp = await fetchWithToken(
        methods_api.finalSales,
        { sale_id, user_id },
        'POST'
      );
      const body = await resp.json();

      if (body.status === 'success') {
        return { success: true };
      } else {
        return { success: false, msg: body.message };
      }
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};
