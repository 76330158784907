import { Children } from 'react';
import { useSelector } from 'react-redux';

export const ModalProductList = () => {
  const {
    modals: { product_ },
  } = useSelector((state) => state.ui);
  const { list } = product_.product;
  return (
    <>
      {list.length > 0 ? (
        <div className="my__2">
          <ul className="list__products">
            {Children.toArray(
              list.map(({ product_name, product_price = '' }) => (
                <li className="flex">
                  <p>
                    <span>{product_name}</span>
                    {product_price !== '' && <span>{product_price}</span>}
                  </p>
                </li>
              ))
            )}
          </ul>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
