import { v4 as uuidv4 } from 'uuid';

export const productMatchingTitle = (
  titles = [],
  arrProducts = [],
  pronombres = []
) => {
  if (arrProducts.length === 0) return [];
  let productos = [];

  titles.forEach((title, index) => {
    const groups = arrProducts[index][`grupo_${index + 1}`];
    const filterProducts = groups.filter((group) => (group.titulo = title));

    let selectedProduct = '';
    if (filterProducts.length === 1) selectedProduct = groups[0].producto_id;
    const pronoun = pronombres.filter(
      (pronoun) => pronoun.categoria.toLowerCase() === title.toLowerCase()
    )[0].value;

    const products = {
      group_title: title,
      group_id: uuidv4(),
      categoria_nombre_s:
        groups.filter((group) => (group.titulo = title))[0]
          .producto_categoria_s ?? '',
      products: filterProducts,
      pronoun,
      selectedProduct,
    };
    productos.push(products);
  });
  return productos;
};
