import { useDispatch, useSelector } from 'react-redux';

import { changeCartTab } from '../../../redux/actions/ui';

import { WrapperPage } from '../../../wrappers/WrapperPage';

import { Banner } from '../../../components';
import { TabCustomizedPlan } from './tabs/TabCustomizedPlan';

import { Tabs } from './Tabs';
import { TabAllProducts } from './tabs/TabAllProducts/index.js';
import { TabPromotions } from './tabs/TabPromotions';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const PageMenu = () => {
  const history = useHistory();
  const tabs = {
    tab1: 'promotions',
    tab2: 'make__plan',
    tab3: 'all__menu',
  };
  const {
    activatedTabs: { menu },
  } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  useEffect(() => {
    if (history.location.hash) {
      window.scroll({
        top: 830,
        left: 100,
        behavior: 'smooth',
      });
    }

    const cartTab = sessionStorage.getItem('cartTab');
    if (cartTab) {
      dispatch(changeCartTab(cartTab));
    }
  }, [dispatch]);

  const changeTab = (tab) => {
    sessionStorage.setItem('cartTab', tab);
    dispatch(changeCartTab(tab));
  };

  return (
    <WrapperPage>
      <Banner name="delicacies" text="Nuestras delicias y promociones" />
      <Tabs
        tab1Activated={menu === tabs.tab1}
        action1Activated={() => changeTab(tabs.tab1)}
        tab2Activated={menu === tabs.tab2}
        action2Activated={() => changeTab(tabs.tab2)}
        tab3Activated={menu === tabs.tab3}
        action3Activated={() => changeTab(tabs.tab3)}
      />
      {menu === tabs.tab1 && <TabPromotions />}
      {menu === tabs.tab2 && <TabCustomizedPlan />}
      {menu === tabs.tab3 && <TabAllProducts />}
    </WrapperPage>
  );
};
