import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { createPassword } from '../redux/actions/auth';

import { useTooglePass } from '../hooks/useTooglePass';

import { MessageError } from '../components/messages';
import { Spinner } from '../components';

export const FormCreatePassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.ui);
  const { togglePass, showPass, inputPassword } = useTooglePass();
  const {
    togglePass: togglePass2,
    showPass: showPass2,
    inputPassword: inputPassword2,
  } = useTooglePass();

  const [messageError, setMessageError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessageError('');
    const password1 = inputPassword.current.value;
    const password2 = inputPassword2.current.value;
    if (password1 !== password2) {
      setMessageError('Las contraseñas no coindicen');
      return;
    }

    const res = await dispatch(
      createPassword({
        token,
        password: password1,
        password_confirmation: password2,
      })
    );

    if (res.success) {
      history.push('/contrasena-creada');
    } else {
      setMessageError(res.msg);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form__control">
        <label htmlFor="pass">Contraseña</label>
        <div className="input__w__icon">
          <input
            type="password"
            ref={inputPassword}
            placeholder="Ingrese su contraseña"
            required
          />
          {!showPass ? (
            <i className="fas fa-eye-slash" onClick={togglePass}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass}></i>
          )}
        </div>
      </div>
      <div className="form__control">
        <label htmlFor="pass">Confirmar contraseña</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Vuelva a ingresar su contraseña"
            ref={inputPassword2}
            required
          />
          {!showPass2 ? (
            <i className="fas fa-eye-slash" onClick={togglePass2}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass2}></i>
          )}
        </div>
      </div>

      {messageError !== '' && <MessageError message={messageError} />}
      {loading.createPassword && <Spinner />}

      <div className="mt__4 text__center">
        <button className="btn btn__outline__white">
          Crear nueva contraseña
        </button>
      </div>
    </form>
  );
};
