import moment from 'moment';
export const OrderNumber = ({ action, open, order_num, date }) => {
  return (
    <div className="accordion__title" onClick={action}>
      <h1>PEDIDO #{order_num}</h1>
      <p>{moment(date).format('DD/MM/YYYY')}</p>
      {open ? (
        <i className="fas fa-chevron-up"></i>
      ) : (
        <i className="fas fa-chevron-down"></i>
      )}
    </div>
  );
};
