import React, { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsNames } from '../../data/ui';
import {
  setTabAllProductsShift,
  setTabBreadProductDays,
  setTabBreadProductPrice,
  setTabBreadProductShift,
} from '../../redux/actions/e-commerce/personalisedPlan';
import { addShiftChosenPlan } from '../../redux/actions/e-commerce/plans';
import { toggleModalDays } from '../../redux/actions/ui';
import { MessageError } from '../messages/MessageError';

export const InfoSelectedProducts = ({
  text__button,
  list_products = [],
  showDays = false,
  showTimetables = false,
  showOnlyPriceTotal = false,
  total = '0',
  action,
  productsOf = 'plans',
}) => {
  const isEmpyProductsList = list_products.length > 0 ? '' : 'empty';
  const dispatch = useDispatch();
  const [shift, setShift] = useState('');
  const [total_, setTotal_] = useState('0.00');
  const [selectedDay, setSelectedDay] = useState('');

  const {
    modals: { days },
    errorMessages: { infoSelectedProducts },
  } = useSelector((state) => state.ui);
  const {
    other_plan: { options },
  } = useSelector((state) => state.plans);

  // console.log(list_products);
  useEffect(() => {
    if (days.arr_days.length === 0) {
      setShift('');
      setTotal_('0.00');
      setSelectedDay('');
    }
  }, [days]);

  const openModalDays = () => {
    dispatch(toggleModalDays(true));
  };

  const changeDay = ({ target }) => {
    const index_ = parseInt(target.options[target.selectedIndex].value);
    const { index, price, days } = options.filter(
      (day_option) => day_option.index === index_
    )[0];
    setSelectedDay(index);
    setTotal_(price);
    dispatch(setTabBreadProductDays(days));

    dispatch(setTabBreadProductPrice(parseInt(price)));
  };

  const selectingShift = (shift) => {
    setShift(shift);
    const shift_num = parseInt(shift);
    if (productsOf === productsNames.plans) {
      dispatch(addShiftChosenPlan(shift_num));
    } else if (productsOf === productsNames.tabAllProducts) {
      dispatch(setTabAllProductsShift(shift_num));
    } else if (productsOf === productsNames.tabBreads) {
      dispatch(setTabBreadProductShift(shift_num));
    }
  };
  return (
    <div className="col-lg-10 mx__auto">
      <div className="divider mt__2 mb__3"></div>
      <div className="row">
        {!showOnlyPriceTotal && (
          <div className="col-lg-4 mb__3">
            {showDays && !days.selectedDays && (
              <div className="form__control mt__0">
                <label htmlFor="">Días de entrega</label>
                <button onClick={openModalDays}>
                  {days.arr_days.length === 0
                    ? 'Seleccione los días de entrega'
                    : days.arr_days
                        .map((day) => day.name.substring(0, 3))
                        .join(', ')}
                </button>
              </div>
            )}
            {days.selectedDays && (
              <div className="form__control mt__0">
                <label htmlFor="">Días de entrega</label>
                <select onChange={changeDay} value={selectedDay}>
                  <option value="" disabled>
                    Seleccione días
                  </option>
                  {Children.toArray(
                    options.map(({ text_days, index }) => (
                      <option value={index}>{text_days}</option>
                    ))
                  )}
                </select>
              </div>
            )}
            {showTimetables && (
              <div className="form__control">
                <label htmlFor="">Horario de entrega</label>
                <select
                  value={shift}
                  onChange={({ target }) => selectingShift(target.value)}
                >
                  <option value="" disabled>
                    Seleccione un horario de entrega
                  </option>
                  <option value="0">Mañana de 9 a 11 am.</option>
                  <option value="1">Tarde de 4 a 6 pm.</option>
                </select>
              </div>
            )}
          </div>
        )}
        <div
          className={` ${
            showOnlyPriceTotal
              ? 'mx__auto col-6 col-sm-8 col-md-8 col-lg-8'
              : 'col-lg-8'
          }`}
        >
          <h1 className="font__klinicSlabmedium title__lg text__center mb__2">
            Mis productos seleccionados:
          </h1>

          <div
            className={`grid__info__selected__products ${isEmpyProductsList}`}
          >
            {isEmpyProductsList === 'empty' ? (
              <h2 className="text__center">Su lista de productos está vacía</h2>
            ) : (
              <div className="list__selected__products">
                <ul>
                  {Children.toArray(
                    list_products.map(
                      ({ product_name, product_price = '', category }) => (
                        <li>
                          {category !== '' && (
                            <div className="d__flex">
                              <div className="dot__white"></div>
                              <p> {category}</p>
                            </div>
                          )}
                          <p className={category !== '' ? 'pl__1' : ''}>
                            {product_name}
                          </p>
                          {product_price !== '' && (
                            <span>S/.{product_price}</span>
                          )}
                        </li>
                      )
                    )
                  )}
                </ul>
              </div>
            )}

            <div className="total__price__products">
              <div className="total__price">
                <p>
                  Total: S/.
                  {days.selectedDays ? total_ : total}
                </p>
              </div>
              <span>*No se encuentra incluído el precio del delivery</span>
            </div>
          </div>
        </div>
      </div>
      {infoSelectedProducts.show && (
        <div className="row">
          <div className="col-md-8 mx__auto text__center">
            <MessageError
              message={infoSelectedProducts.message}
              bg__dark
              my__2={false}
            />
          </div>
        </div>
      )}
      <div className="row mt__3 text__center">
        <div className="col-lg-12">
          <button className="btn btn__outline__white" onClick={action}>
            {text__button}
          </button>
        </div>
      </div>
    </div>
  );
};
