const number = 997518833;
const message =
  '¡Hola, Don Santi! 🖐 Quiero hablar con alguien para más información, por favor.';
const message_2 = 'Don Santi - Plan personalizado';

export const social_media = {
  facebook: 'https://facebook.com/donsantiperu',
  whatsApp: `https://api.whatsapp.com/send?phone=+51${number}&text=${message}`,
  whatsApp_plan: `https://api.whatsapp.com/send?phone=+51${number}&text=${message_2}`,
  instagram: 'https://www.instagram.com/donsantiperu/',
  twitter: '',
  tiktok: 'https://www.tiktok.com/@donsantiperu',
  email: 'donsanti2022@gmail.com',
};
