const baseUrl = process.env.REACT_APP_API_PRO;

export default class ApiNiubiz {
  async authorization(transactionToken, purchaseNumber) {
    const token = JSON.parse(localStorage.getItem('auth'))?.token || '';
    const response = await fetch(`${baseUrl}api/v1/payAnswer`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        transactionToken: '' + transactionToken,
        purchaseNumber: parseInt(purchaseNumber),
      }),
    });

    return response.json();
  }
}
