import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import {
  ModalListDays,
  ModalProduct,
  ModalMakePurchase,
  ModalTermsNPolicity,
} from '../modals';

import { WrapperFooter } from './WrapperFooter';
import { NavbarMain, Footer } from '../components';
import { useEffect } from 'react';
import ModalProductInCart from '../modals/ModalProductInCart';

export const WrapperPage = ({ children, bg__dark = true }) => {
  const { loggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 30);
  }, []);
  return (
    <Fragment>
      <ModalListDays />
      <ModalProduct />
      <ModalTermsNPolicity />
      <ModalMakePurchase />
      <ModalProductInCart />

      <NavbarMain />
      <main className={bg__dark ? 'bg__main' : 'bg__don__santi'}>
        {children}
      </main>
      <WrapperFooter isLogged={loggedIn}>
        <Footer />
      </WrapperFooter>
    </Fragment>
  );
};
