export const methods_api = {
  login: 'login',
  register: 'nuevoUsuario',
  getInfoUser: 'verUsuario',
  getProducts: 'listaProductos',
  getCategories: 'listaCategorias',
  getProductsBuildBread: 'listaProductosPanRelleno?page=1',
  getPromotions: 'listaPromociones',
  getFeaturedProducts: 'listaProductosVip',
  getFeaturedPromotions: 'listaPromocionesVip',
  getPlans: 'listaPlanes',
  getDistricts: 'listaDistritos',
  getDocumentTypes: 'listaTipoDocumentos',
  getSales: 'ventas',
  updateInfoUser: 'actualizarUsuario',
  updateInfoDelivery: 'actualizarDelivery',
  resetPassword: 'resetPassword',
  changePassword: 'cambiarPassword',
  changePasswordLogin: 'cambiarPasswordlogin',
  sale: 'venta',
  finalSales: 'venta_final',
  voucher: 'voucher',
};
