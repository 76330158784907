import { CarouselHistory } from '../../../../components/carousels/listCarousels';
import { Link } from 'react-router-dom';
// import { Banner } from '../../../components';

export const SectionHistory = () => {
  return (
    <section className="container-fluid bg__secundary section__xl__py section__history">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx__auto">
            <Title />
            <Content />
          </div>
          <div className="col-lg-11 mx__auto">
            <div className="row">
              <CarouselHistory />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Title = () => {
  return (
    <h2 className="text__center font__escafinaHigh title__lg">
      Nuestra historia
    </h2>
  );
};

const Content = () => {
  return (
    <div className="banner__history banner__history__1">
      <div className="banner__history__content">
        <p>
          Soy el Avi, un abuelo, padre y amigo… Un español con corazón <br />{' '}
          peruano.
        </p>
        <Link to="/nuestra-historia" className="btn btn__primary">
          Conocer historia
        </Link>
      </div>
    </div>
  );
};
