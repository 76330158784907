import { Fragment, useState, useRef, useLayoutEffect, Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loggedIn } from '../../../../../redux/actions/auth';
import { updateInfoUser } from '../../../../../redux/actions/e-commerce';
import { editingUserInformation } from '../../../../../redux/actions/ui';

import {
  validateNumber,
  validateValueEmpty,
} from '../../../../../helpers/validations';

import {
  MessageError,
  MessageSuccess,
  MessageWarning,
} from '../../../../../components/messages';

import { LoadingProfile } from './LoadingProfile';

import { fieldErrorMessages } from '../../../../../data/ui';

export const EditUserInformation = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { user } = useSelector((state) => state.information);
  const { editing, documentTypes } = useSelector((state) => state.ui);

  const inputEmpty = '';

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  const [docTypeID, setDocTypeID] = useState('0');

  const ref__cellphone = useRef('');

  const canWrite = editing.user;
  useLayoutEffect(() => {
    setPayload(user);
    if (user?.document_type_id === '') {
      setDocTypeID('0');
      return;
    }
    setDocTypeID(user?.document_type_id);
  }, [user]);

  const goPageChangePassword = () => {
    history.push('/usuario/cambiar-contrasena');
  };

  const logout = () => {
    sessionStorage.clear();
    dispatch(loggedIn(false));
    history.push('/inicio');
  };

  const updateInfo = async () => {
    setErrorMessage('');
    if (canWrite) {
      setLoading(true);
      const cellphone = ref__cellphone.current.value;
      // debugger;
      if (
        (cellphone.length > 1 && !validateNumber(cellphone)) ||
        (cellphone.length > 1 && Math.sign(cellphone) !== 1)
      ) {
        editingMessages(fieldErrorMessages.invalidCel);
      } else if (Math.sign(payload.document_number) !== 1) {
        editingMessages('Por favor ingrese un nro de documento válido');
      } else if (cellphone.length < 9 && cellphone.length !== 0) {
        editingMessages(fieldErrorMessages.invalidCelNumbers);
      } else if (docTypeID === '0' && payload.document_number.length > 2) {
        editingMessages('Por favor seleccione un tipo de documento');
      } else {
        if (payload.dni === '') delete payload.dni;
        if (payload.cellphone === '') delete payload.cellphone;
        const { status, document_type_id, document_type, ...data } = payload;

        const updateUser = {
          ...data,
          document_type_id: parseInt(docTypeID) ?? 0,
        };

        if (updateUser.document_type_id === '0') {
          delete updateUser.document_number;
          delete updateUser.document_type_id;
        }

        const res = await dispatch(updateInfoUser(updateUser));
        if (res.success) {
          dispatch(editingUserInformation(false));
          setSuccessMessage(
            'Los datos personales de han actualizado correctamente 😁'
          );
          setTimeout(() => {
            window.scrollTo(0, 240);
            setSuccessMessage('');
          }, 1500);
        }
        if (!res.success) editingMessages(res.msg);
        window.scrollTo(0, 290);
        setLoading(false);
      }
    } else {
      dispatch(editingUserInformation(true));
    }
  };

  const editingMessages = (message) => {
    dispatch(editingUserInformation(true));
    setErrorMessage(message);
    setLoading(false);
  };

  const onChangeDocType = ({ target }) => {
    const doc_type_id = target.options[target.selectedIndex].value;
    setDocTypeID(doc_type_id);
  };

  const cancelUpdate = () => {
    window.location.reload();
  };

  return (
    <div className="tab__content">
      {!loading ? (
        <Fragment>
          <div className="info__user">
            <p>Nombres:</p>
            <input
              name="user_names"
              type="text"
              value={`${
                validateValueEmpty(payload?.name) ? inputEmpty : payload?.name
              }`}
              className={`${canWrite ? '' : 'text'}`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, name: target.value };
                })
              }
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Apellidos:</p>
            <input
              name="user_lastname"
              type="text"
              value={`${
                validateValueEmpty(payload?.last_name)
                  ? inputEmpty
                  : payload?.last_name
              }`}
              className={`${canWrite ? '' : 'text'}`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, last_name: target.value };
                })
              }
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Celular:</p>
            <input
              name="user_tel"
              type="tel"
              ref={ref__cellphone}
              value={`${
                validateValueEmpty(payload?.cellphone)
                  ? inputEmpty
                  : payload?.cellphone
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, cellphone: target.value };
                })
              }
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Correo electrónico:</p>
            <input
              name="user_email"
              type="email"
              value={`${
                validateValueEmpty(payload?.email) ? inputEmpty : payload?.email
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, email: target.value };
                })
              }
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Tipo de documento:</p>

            <select
              onChange={onChangeDocType}
              value={docTypeID}
              className={`${canWrite ? '' : 'text'}`}
              disabled={!canWrite}
            >
              <option value="0" disabled>
                Seleccione un tipo de documento
              </option>
              {Children.toArray(
                documentTypes.map(({ id, document_type }) => (
                  <option value={id}>{document_type}</option>
                ))
              )}
            </select>
          </div>
          <div className="info__user">
            <p>Nro de documento:</p>
            <input
              name="user_dni"
              type="number"
              className={`${canWrite ? '' : 'text'}`}
              value={`${
                validateValueEmpty(payload?.document_number)
                  ? inputEmpty
                  : payload?.document_number
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, document_number: target.value };
                })
              }
              readOnly={!canWrite}
            />
          </div>

          {errorMessage !== '' && (
            <MessageError message={errorMessage} bg__dark my__2={false} />
          )}
          {successMessage !== '' && <MessageSuccess />}

          {editing.user && (
            <MessageWarning message="Recuerda guardar tus cambios antes de salir" />
          )}
          <div className="actions">
            <button
              className="btn btn__white"
              onClick={updateInfo}
              type="button"
            >
              {editing.user ? 'Guardar' : 'Editar Perfil'}
            </button>

            {!editing.user ? (
              <>
                <button
                  className="btn btn__outline__white"
                  type="button"
                  onClick={goPageChangePassword}
                >
                  Cambiar contraseña
                </button>
                <button
                  className="btn btn__outline__white"
                  type="button"
                  onClick={logout}
                >
                  Cerrar Sesión
                </button>
              </>
            ) : (
              <button
                className="btn btn__outline__white"
                type="button"
                onClick={cancelUpdate}
              >
                Cancelar
              </button>
            )}
          </div>
        </Fragment>
      ) : (
        <LoadingProfile tab="Personales" />
      )}
    </div>
  );
};
