import { WrapperForm } from '../../wrappers/WrapperForm';
import { FormRegister } from '../../forms';
import { NavbarMain } from '../../components/NavbarMain';
import { ModalTermsNPolicity } from '../../modals';

export const PageRegister = () => {
  return (
    <>
      <ModalTermsNPolicity />
      <NavbarMain />
      <WrapperForm
        image="register"
        title="¡Te damos la bienvenida a Don Santi!"
      >
        <FormRegister />
      </WrapperForm>
    </>
  );
};
