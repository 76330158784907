export const typesPromotions = {
  featuredPromotions: '[PROMOTION] GET PROMOTIONS FEATURED',
  paginationPromotions: '[PROMOTION] GET PAGINATION PROMOTIONS',
  num_pages: '[PROMOTION] NUM_PAGES',

  chosenPromotion: '[PROMOTION] CHOSEN PROMOTION',
  addProductCategoryChosenPromotion:
    '[PROMOTION] ADD PRODUCT IN CATEGORY OF CHOSEN PROMOTION',
  addProductGroupChosenPromotion:
    '[PROMOTION] ADD PRODUCT IN GROUP OF CHOSEN PROMOTION',
  clearChosenPromotion: '[PROMOTION] CLEAR CHOSEN PROMOTION',

  addProductChosenPromotion: '[PROMOTION] ADD PRODUCT CHOSEN PROMOTION',
};
