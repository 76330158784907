export const typesUi = {
  districts: '[UI]  DISTRICTS',
  documentTypes: '[UI] DOCUMENT TYPES',

  addDaysModalDays: '[UI] ADD DAYS MODAL DAYS',
  addScreenModalDays: '[UI] ADD SCREEN MODAL DAYS',
  toggleModalDays: '[UI] MODAL DAYS',
  showSelectDays: '[UI] SHOW SELECT DAYS',

  openModalProduct: '[UI] OPEN MODAL PRODUCT',
  closedModalProduct: '[UI] CLOSED MODAL PRODUCT',

  toggleModalTermsNPolicity: '[UI] MODAL TERMS AND POLICITY',
  toggleModalMakePurchase: '[UI] MODAL MAKE PURCHASE',
  toggleModalEmailError: '[UI] MODAL EMAIL ERROR',
  toggleModalProductInCart: '[UI] MODAL PORDUCT IN CART',

  loadingLogin: '[UI] LOADING LOGIN',
  loadingRegister: '[UI] LOADING REGISTER',
  loadingPasswordReset: '[UI] LOADING PASSWORD RESET',
  loadingPasswordChange: '[UI] LOADING PASSWORD CHANGE',
  loadingCreatePassword: '[UI] LOADING CREATE PASSWORD',

  editingUserInformation: '[UI] EDITING USER INFORMATION',
  editingDeliveryInformation: '[UI] EDITING DELIVERY INFORMATION',

  activatedProfileTab: '[UI] PROFILE ACTIVATED',
  activatedCartTab: '[UI] CART ACTIVATED',

  errorMessageSelectedProducts: '[UI] ERROR MESSAGE IN SELECTED PLAN',
  addProductToCart: '[UI] ADD PRODUCT TO CART',
};
