import { Children, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePersonalisedPlan } from '../../../../redux/actions/e-commerce/personalisedPlan';

import { InfoSelectedProducts } from '../../../../components/cart';
import { productsNames } from '../../../../data/ui';

import { Products } from '../../CategoriesProducts/Products';
import { TabAllProductsLoading } from './TabAllProductsLoading';

export const TabAllProducts = () => {
  const dispatch = useDispatch();
  const { other_plan } = useSelector((state) => state.plans);

  const skeletons = [1, 2];
  const {
    personalisedPlanAllProducts: { products },
  } = useSelector((state) => state.plans);

  const validate = () => {
    dispatch(validatePersonalisedPlan(productsNames.tabAllProducts));
  };
  return (
    <Fragment>
      {other_plan.isLoading
        ? Children.toArray(skeletons.map(() => <TabAllProductsLoading />))
        : Children.toArray(
            other_plan.categories.map(
              ({
                productos,
                selectedProduct,
                categoria_nombre,
                categoria_id,
                categoria_nombre_s,
              }) => (
                <Products
                  isProducts
                  list={productos}
                  selected={selectedProduct}
                  title={categoria_nombre}
                  title_single={categoria_nombre_s}
                  id={categoria_id}
                />
              )
            )
          )}

      <InfoSelectedProducts
        text__button="Agregar nuevo plan al carrito"
        showDays
        showTimetables
        list_products={products}
        total={other_plan.price}
        productsOf={productsNames.tabAllProducts}
        action={validate}
      />
    </Fragment>
  );
};
