import { productsNames } from '../../../data/ui';

export const CartProductsHeader = ({ isPlans = false, productsOf }) => {
  const nameProducts = (name) => {
    switch (name) {
      case productsNames.personalisedBread:
        return 'Pan personalizado';
      case productsNames.personalisedPlans:
        return 'Plan personalizado';
      case productsNames.plans:
        return 'Plan';
      case productsNames.promotions:
        return 'Promoción';
      default:
        return 'Producto';
    }
  };

  const name_product = nameProducts(productsOf);

  return (
    <div className="grid__card__products products__header">
      <p className="card__product__header">{name_product}</p>
      <p className="card__product__header">Precio</p>
      <p className="card__product__header">{isPlans ? 'Meses' : 'Cantidad'}</p>
      <p className="card__product__header">Subtotal</p>
      <p></p>
    </div>
  );
};
