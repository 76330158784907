import { Redirect, Route, Switch } from 'react-router-dom';
import {
  PageMain,
  PagePlans,
  PageMenu,
  PageHistory,
  PageCart,
  PageCategoriesProducts,
  PageConfirmation
} from '../pages/e-commerce';
import { PagePlanCustomized } from '../pages/e-commerce/CustomizedPlan';

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/inicio" component={PageMain} />
      {/* <Route exact path="/planes" component={PagePlans} /> */}
      <Route exact path="/carta" component={PageMenu} />
      <Route exact path="/nuestra-historia" component={PageHistory} />
      <Route exact path="/carrito" component={PageCart} />
      <Route exact path="/plan-personalizado" component={PagePlanCustomized} />
      <Route exact path="/confirmacion-pago" component={PageConfirmation} />

      <Route
        exact
        path="/categorias-productos"
        component={PageCategoriesProducts}
      />
      <Redirect to="/inicio" />
    </Switch>
  );
};
