export const OrderPrices = ({ subtotal, total, delivery }) => {
  return (
    <>
      <div className="sale__prices">
        <div className="">
          <h4>Subtotal</h4>
          <p>S/ {subtotal}</p>
        </div>
        <div className="">
          <h4>Delivery</h4>
          <p>S/ {delivery}</p>
        </div>
      </div>
      <div className="divider__yellow mt__2"></div>
      <div className="sale__prices sale__total">
        <div className="">
          <h4 className="text__yellow">Total</h4>
          <p className="text__yellow">S/ {total}</p>
        </div>
      </div>
    </>
  );
};
