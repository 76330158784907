import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsPaginations } from '../../../../../redux/actions/e-commerce/products';

import { buildPaginationNum } from '../../../../../helpers/e_commerce';
import { SectionPagination } from '../../sections/SectionPagination';

export const Products = ({ categoryName, categoryId }) => {
  const { pagination, num_pages } = useSelector((state) => state.products);
  const { list, pageActivated, loading } = pagination;

  const [arrNumbers, setArrNumbers] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [page_activated, setPage_activated] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    let list__numbers = buildPaginationNum(num_pages);
    setArrNumbers(list__numbers);
  }, [num_pages]);

  useEffect(() => {
    setPage_activated(pageActivated);
  }, [pageActivated]);

  useEffect(() => {
    setListProducts([]);
    setListProducts(list);
  }, [list]);

  const nextPage = () => {
    changePage(page_activated + 1);
    scrollPagination();
  };
  const previousPage = () => {
    changePage(page_activated - 1);
    scrollPagination();
  };

  const changePage = (page) => {
    dispatch(getProductsPaginations(categoryId, page));
    scrollPagination();
  };

  const scrollPagination = () => {
    window.scroll(0, 790);
  };
  return (
    <SectionPagination
      list={listProducts}
      arrNumbers={arrNumbers}
      pageActivated={page_activated}
      changePage={changePage}
      nextPage={nextPage}
      previousPage={previousPage}
      loading={loading}
      categoryName={categoryName}
      products_of="all__products"
    />
  );
};
