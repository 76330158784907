import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Title } from './Title';
import { WrapperPage } from '../../../wrappers/WrapperPage';
import { PlanProducts } from './PlanProducts';
import { PromotionProducts } from './PromotionProducts';
import { useDispatch, useSelector } from 'react-redux';
import { clearChosenPromotion } from '../../../redux/actions/e-commerce/promotions';
import { clearChosenPlan } from '../../../redux/actions/e-commerce/plans';

export const PageCategoriesProducts = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [list_products_of, setList_products_of] = useState('');

  const { chosen_promotion } = useSelector((state) => state.promotions);
  const categories_promotions = chosen_promotion.categories;
  const groups_promotions = chosen_promotion.products;

  const { chosen_plan } = useSelector((state) => state.plans);
  const categories_plans = chosen_plan.categories;
  const groups_plans = chosen_plan.products;
  const [arr_titles, setTitles] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setList_products_of(state?.list_products);
    return () => {
      dispatch(clearChosenPromotion());
      dispatch(clearChosenPlan());
    };
  }, [state, dispatch]);

  const build_titles = useCallback(() => {
    let titles = [];
    if (state?.list_products === 'promotions') {
      titles = [
        ...categories_promotions.map(
          (title) =>
            title.categoria_nombre.charAt(0).toUpperCase() +
            title.categoria_nombre.slice(1)
        ),
        ...groups_promotions.map(
          (title) =>
            title.group_title.charAt(0).toUpperCase() +
            title.group_title.slice(1)
        ),
      ];
    } else if (state?.list_products === 'plans') {
      titles = [
        ...categories_plans.map(
          (title) =>
            title.categoria_nombre.charAt(0).toUpperCase() +
            title.categoria_nombre.slice(1)
        ),
        ...groups_plans.map(
          (title) =>
            title.group_title.charAt(0).toUpperCase() +
            title.group_title.slice(1)
        ),
      ];
    }
    setTitles(titles);
    if (titles.length === 0) {
      setRedirect(true);
    }
  }, [
    categories_plans,
    categories_promotions,
    groups_plans,
    groups_promotions,
    state,
  ]);

  useLayoutEffect(() => {
    build_titles();
  }, [build_titles]);

  if (redirect) {
    return <Redirect to="/inicio" />;
  }
  return (
    <WrapperPage>
      <div className="section__lg__py">
        <Title page={list_products_of} titles_categories={arr_titles} title={list_products_of === 'plans' ? chosen_plan.plan_name : chosen_promotion.promotion_name}/>
        <Divider />

        {list_products_of === 'plans' && <PlanProducts />}
        {list_products_of === 'promotions' && <PromotionProducts />}
      </div>
    </WrapperPage>
  );
};

export const Divider = () => {
  return (
    <div className="col-lg-8 mx__auto">
      <div className="my__4">
        <div className="divider"></div>
      </div>
    </div>
  );
};
