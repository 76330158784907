import { Children, Fragment, useLayoutEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { updateInfoUser } from '../../../../redux/actions/e-commerce';

import { validateEmail, validateNumber } from '../../../../helpers/validations';
import { BuyDetailsActions } from '../../../../components/buyDetails/BuyDetailsActions';
import { MessageError } from '../../../../components/messages';
import { fieldErrorMessages } from '../../../../data/ui';

export const PersonalData = ({ step, prevStep, nextStep, setDocument }) => {
  const { user, delivery } = useSelector((state) => state.information);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { documentTypes } = useSelector((state) => state.ui);
  const [docTypeID, setDocTypeID] = useState(1);

  const [showBtnsActions, setShowBtnsActions] = useState(false);
  /* REFS ------------------------------ */
  const name_ref = useRef('');
  const lastName_ref = useRef('');
  const cellPhone_ref = useRef('');
  const email_ref = useRef('');
  const ref_document_number = useRef('');

  useLayoutEffect(() => {
    if (user?.document_type_id === '') {
      setDocTypeID('1');
    } else {
      setDocTypeID(user?.document_type_id);
    }

    if (name_ref.current.value !== '') {
      setShowBtnsActions(true);
    }
  }, [user]);

  const updateUserInformation = async () => {
    setLoading(true);

    const name = name_ref.current.value;
    const last_name = lastName_ref.current.value;
    const cellphone = cellPhone_ref.current.value;
    const email = email_ref.current.value;
    const document_number = ref_document_number.current.value;
    const user = {
      name,
      last_name,
      cellphone,
      document_number,
      document_type_id: docTypeID,
      email,
    };

    if (name === '' || name.length < 2) {
      editingMessages(fieldErrorMessages.enterName);
    } else if (last_name === '' || last_name.length < 2) {
      editingMessages(fieldErrorMessages.enterLastName);
    } else if (cellphone === '') {
      editingMessages(fieldErrorMessages.enterCel);
    } else if (
      cellphone.length < 1 ||
      !validateNumber(cellphone) ||
      Math.sign(cellphone) !== 1
    ) {
      editingMessages(fieldErrorMessages.invalidCel);
    } else if (cellphone.length < 9) {
      editingMessages(fieldErrorMessages.invalidCelNumbers);
    } else if (!validateEmail(email)) {
      editingMessages(fieldErrorMessages.invalidEmail);
    } else if (Math.sign(user.document_number) !== 1) {
      editingMessages('Por favor ingrese un nro de documento válido');
    } else if (docTypeID === '' && user.document_number.length < 2) {
      editingMessages('Por favor seleccione un tipo de documento');
    } else {
      const res = await dispatch(updateInfoUser(user));
      if (res.success) {
        editingMessages('');
        nextStep();
        return;
      }
      if (!res.success) editingMessages(res.msg);
    }
  };

  const onChangeDocType = ({ target }) => {
    const doc_type_id = target.options[target.selectedIndex].value;
    setDocTypeID(doc_type_id);
  };
  const editingMessages = (message) => {
    setErrorMessage(message);
    setLoading(false);
  };

  return (
    <Fragment>
      <section className="content__shopping__cart">
        <div>
          <h3 className="title text__yellow">DATOS PERSONALES</h3>
          <div className="divider__yellow my__2"></div>
          <div className="form__personal__data">
            <div className="row">
              <div className="col-lg-6">
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_name">Nombres:</label>
                    <input
                      type="text"
                      placeholder="Ingrese su nombre"
                      defaultValue={user?.name}
                      ref={name_ref}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_lastname">Apellidos:</label>
                    <input
                      type="text"
                      placeholder="Ingrese su apellido"
                      defaultValue={user?.last_name}
                      ref={lastName_ref}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_email">Correo electrónico:</label>
                    <input
                      type="email"
                      placeholder="Ingrese su correo"
                      defaultValue={user?.email}
                      ref={email_ref}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_phone">Celular:</label>
                    <div className="input__text__left">
                      <p className="text">+ 51</p>
                      <input
                        type="tel"
                        placeholder="Ingrese su celular"
                        defaultValue={user?.cellphone}
                        ref={cellPhone_ref}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_type_document">
                      Tipo de documento:
                    </label>
                    <select onChange={onChangeDocType} value={docTypeID}>
                      {/* <option value="0" disabled>
                        Seleccione un tipo de documento
                      </option> */}
                      {Children.toArray(
                        documentTypes.map(({ document_type, id }) => (
                          <option value={id}>{document_type}</option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form__control">
                    <label htmlFor="user_document">Documento:</label>
                    <input
                      type="tel"
                      placeholder="Ingrese su nro de documento"
                      defaultValue={user?.document_number}
                      ref={ref_document_number}
                    />
                  </div>
                </div>
              </div>
            </div>
            {errorMessage !== '' && (
              <div className="row">
                <div className="col-lg-6 mx__auto">
                  <MessageError
                    message={errorMessage}
                    bg__dark
                    my__2={false}
                    text__center
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {showBtnsActions && (
        <BuyDetailsActions
          step={step}
          isLoading={isLoading}
          btnGoBack={prevStep}
          btnGoNext={updateUserInformation}
        />
      )}
    </Fragment>
  );
};
