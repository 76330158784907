import { useEffect } from 'react';
import { useRef } from 'react';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BuyDetailsActions } from '../../../../components/buyDetails/BuyDetailsActions';
import { MessageError } from '../../../../components/messages';
import {
  clearCart,
  formattingSale,
  getSales,
  setMethodPayment,
  setTypeDocument,
  updateDireccion,
  updateRazon,
  updateRuc,
  getSessionKey,
  updateIp,
} from '../../../../redux/actions/e-commerce/sale';
import { ProductList } from './methodContent/ProductList';
import { Transferencia } from './methodContent/Transferencia';

const baseUrl = process.env.REACT_APP_API_PRO;

export const PaymentMethod = ({ step, prevStep, nextStep }) => {
  const [method, setMethod] = useState(2);
  const [comprobante, setComprobante] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [nameFile, setNameFile] = useState('');
  const [file, setFile] = useState('');
  const { total, type_document, method_pay } = useSelector(
    (state) => state.sale
  );
  const ruc_ref = useRef('');
  const razon_ref = useRef('');
  const direccion_ref = useRef('');

  const dispatch = useDispatch();
  const methods = [<ProductList />, <Transferencia />];

  useEffect(() => {
    if (type_document !== '') {
      setComprobante(type_document);
    }
    if (method_pay !== '') {
      setMethod(method_pay);
    }
  }, [type_document, method_pay]);

  const makePayment = async () => {
    setErrorMessage('');
    setLoading(true);
    let ruc = '';
    let razon = '';
    let direccion = '';

    if (comprobante === 1 || comprobante === '1') {
      ruc = ruc_ref.current.value ?? '';
      razon = razon_ref.current.value ?? '';
      direccion = direccion_ref.current.value;
    }

    if (
      ruc.length >= 11 &&
      razon.length > 0 &&
      direccion.length > 0 &&
      (comprobante === 1 || comprobante === '1')
    ) {
      dispatch(updateRuc(ruc));
      dispatch(updateRazon(razon));
      dispatch(updateDireccion(direccion));
    }
    if (method_pay === '') {
      setLoading(false);
      setErrorMessage('Por favor seleccione un tipo de método de pago');
      return;
    } else if (type_document === '') {
      setErrorMessage('Por favor seleccione un tipo de documento');
      return;
    } else if ((comprobante === 1 || comprobante === '1') && ruc === '') {
      setLoading(false);
      setErrorMessage('Por favor ingrese ruc');
      return;
    } else if ((comprobante === 1 || comprobante === '1') && razon === '') {
      setLoading(false);
      setErrorMessage('Por favor ingrese una razón social ');
      return;
    } else if ((comprobante === 1 || comprobante === '1') && direccion === '') {
      setLoading(false);
      setErrorMessage('Por favor ingrese una dirección fiscal');
      return;
    } else if ((comprobante === 1 || comprobante === '1') && ruc.length < 11) {
      setLoading(false);
      setErrorMessage('Por favor ingrese un ruc válido');
      return;
    } else if (method_pay === 2 && file === '') {
      const name_doc = type_document === 0 ? 'boleta' : 'factura';
      setLoading(false);
      setErrorMessage('Por favor agregue la foto de su ' + name_doc);
      return;
    }
    if (method === 1) {
      const getSecurityToken = async () => {
        // const data = await fetch('https://api.ipify.org/?format=json');
        // const ip = await data.json();
        // const ID = ip?.ip ?? '1.1.1.1';

        dispatch(updateIp('1.1.1.1'));
        const res = await dispatch(getSessionKey());
        if (res.pago.status === 'error') {
          setErrorMessage('Pago con tarjeta no disponible');
          return;
        }
        const visaWraper = document.getElementById('visaNetWrapper');
        if (res.pago.sessionKey) {
          if (!visaWraper) {
            const form = document.createElement('form');
            form.action = `${baseUrl}api/v1/probar`;
            form.method = 'post';
            form.id = 'niubiz_form';
            form.hidden = true;
            const script = document.createElement('script');
            script.src =
              'https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true';
            script.setAttribute('data-sessiontoken', res.pago.sessionKey);
            script.setAttribute('data-channel', 'web');
            script.setAttribute('data-merchantid', res.pago.merchantid);
            script.setAttribute(
              'data-merchantlogo',
              'http://www.donsanti.pe/images/logos/logo.png'
            );
            script.setAttribute('data-formbuttoncolor', '#f58900');
            script.setAttribute('data-purchasenumber', res.pago.purchasenumber);
            script.setAttribute('data-amount', res.pago.amount);
            script.setAttribute(
              'data-expirationminutes',
              15 || res.pago.expiration_minutes
            );
            script.setAttribute('data-timeouturl', 'http://www.donsanti.pe');
            form.appendChild(script);
            document.body.appendChild(form);
          }
          sessionStorage.setItem('method_pay', 1);
          sessionStorage.setItem('venta', JSON.stringify(res));
          sessionStorage.setItem(
            'type_document',
            JSON.stringify(type_document)
          );
          localStorage.setItem(
            'order-purchasenumber',
            res.pago.purchasenumber
          );
        }
      };
      getSecurityToken();
      let intervalForm = setInterval(() => {
        if (document.querySelector('#niubiz_form')) {
          if (document.querySelector('#niubiz_form button') !== null) {
            document.querySelector('#niubiz_form button').click();
            setLoading(false);
            if(comprobante) sessionStorage.setItem('ruc', ruc_ref.current.value);
            clearInterval(intervalForm);
          }
        }
      }, 500);
    } else {
      const response = await dispatch(formattingSale(file));
      if (response.success) {
        sessionStorage.setItem('method_pay', JSON.stringify(2));
        setLoading(false);
        dispatch(clearCart());
        dispatch(getSales());
        nextStep();
      } else {
        setLoading(false);
        setErrorMessage(response.msg);
      }
    }
  };

  const uploadImmage = (e) => {
    const name_file = e.target.files[0].name;
    setNameFile(name_file);
    setFile(e.target.files[0]);
  };
  const setComprobanteValue = (e) => {
    setErrorMessage('');
    if (e.target.checked) {
      const num = e.target.value;
      setComprobante(num);
      dispatch(setTypeDocument(parseInt(e.target.value)));
    }
  };

  const changeMethod = (id_method) => {
    setErrorMessage('');
    setMethod(id_method + '');
    dispatch(setMethodPayment(parseInt(id_method)));
  };
  return (
    <Fragment>
      <section className="content__shopping__cart">
        <div>
          <h3 className="title text__yellow">MÉTODO DE PAGO</h3>
          <div className="divider__yellow my__2"></div>
          <div className="row" id="methodForm">
            <div className="col-lg-6">
              {methods[method === '2' || method === 2 ? 1 : 0]}
            </div>
            <div className="col-lg-6">
              <div className="form__control">
                <label htmlFor="user_payment__method">
                  Elige tu método de pago:
                </label>
                <select
                  name="user_payment__method"
                  id="user_payment__method"
                  value={method}
                  onChange={(e) => changeMethod(e.target.value)}
                >
                  <option value="">Seleccione un método de pago</option>
                  <option value="1">Pago con tarjeta</option>
                  <option value="2">Transferencia</option>
                </select>
              </div>
              <div className="payment_methods mt__1">
                <div className="form__control flex">
                  <input
                    value="0"
                    name="comprobante"
                    type="radio"
                    id="boleta"
                    onChange={setComprobanteValue}
                    checked={comprobante === 0 || comprobante === '0'}
                  />
                  <label htmlFor="boleta">Boleta</label>
                </div>
                <div className="form__control flex">
                  <input
                    value="1"
                    name="comprobante"
                    type="radio"
                    id="factura"
                    onChange={setComprobanteValue}
                    checked={comprobante === 1 || comprobante === '1'}
                  />
                  <label htmlFor="factura">Factura</label>
                </div>
              </div>
              {(comprobante === 1 || comprobante === '1') && (
                <div className="form__control">
                  <label htmlFor="ruc">RUC:</label>
                  <input
                    id="ruc"
                    name="ruc"
                    type="text"
                    className="mb__2"
                    placeholder="Ingrese su RUC"
                    maxLength={12}
                    ref={ruc_ref}
                  />
                  <label htmlFor="business_name">Razón Social:</label>
                  <input
                    id="business_name"
                    className="mb__2"
                    name="business_name"
                    type="text"
                    placeholder="Ingrese su razón social"
                    ref={razon_ref}
                  />
                  <label htmlFor="fiscal_address">Dirección Fiscal:</label>
                  <input
                    id="fiscal_address"
                    name="fiscal_address"
                    type="text"
                    className="mb__2"
                    placeholder="Ingrese su dirección fiscal"
                    ref={direccion_ref}
                  />
                </div>
              )}
              <div className="total__payable">
                <p>Total a pagar:</p>
                <div className="">
                  <p>S/ {total.toFixed(2)}</p>
                </div>
              </div>
              {(method === '2' || method === 2) && comprobante !== '' && (
                <div className="add__boucher">
                  <input
                    type="file"
                    id="boucher"
                    className="d__none"
                    onChange={uploadImmage}
                  />
                  <label htmlFor="boucher">
                    {nameFile !== ''
                      ? nameFile
                      : `Agregar foto de la ${
                          comprobante === 1 || comprobante === '1'
                            ? 'factura'
                            : 'boleta'
                        } de pago`}
                  </label>
                </div>
              )}
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="row">
              <div className="col-lg-6 mx__auto">
                <MessageError
                  message={errorMessage}
                  bg__dark
                  my__2={false}
                  text__center
                />
              </div>
            </div>
          )}
        </div>
      </section>

      <BuyDetailsActions
        step={step}
        isLoading={isLoading}
        btnGoBack={prevStep}
        textBtnGoNext={method === 0 ? 'Realizar Pago' : 'Realizar Pago'}
        btnGoNext={makePayment}
      />
    </Fragment>
  );
};
