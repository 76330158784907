import { typesInformation } from '../../types/e-commerce';

const initialState = {
  sale: [],
};

export const informationReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesInformation.information:
      return { ...state, ...action.payload };
    case typesInformation.sale:
      return { ...state, sale: action.payload };
    default:
      return state;
  }
};
