export const NoteAdded = () => {
  return (
    <>
      <div className="modal__icon">
        <img src="../../images/decorations/icons/success.svg" alt="" />
      </div>
      <div className="modal__text__success text__center">
        <h1>Su nota se ha agregado éxitosamente</h1>
      </div>
    </>
  );
};
