import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoSelectedProducts } from '../../../components/cart';
import { validateSelectedCategories } from '../../../redux/actions/e-commerce';
import { Products } from './Products';

export const PromotionProducts = () => {
  const dispatch = useDispatch();
  const { categories, price, promotion_products, products } = useSelector(
    (state) => state.promotions.chosen_promotion
  );
  const [listProducts, setListProducts] = useState([]);
  useEffect(() => {
    setListProducts(promotion_products);
  }, [promotion_products]);

  const validatePromotion = () => {
    dispatch(validateSelectedCategories({ is_plans: false }));
  };
  return (
    <>
      {categories.length > 0 &&
        Children.toArray(
          categories.map(
            ({
              productos,
              selectedProduct,
              categoria_nombre,
              categoria_id,
              categoria_nombre_s,
            }) => (
              <Products
                isCategories
                list={productos}
                selected={selectedProduct}
                title={categoria_nombre}
                title_single={categoria_nombre_s}
                id={categoria_id}
              />
            )
          )
        )}

      {products.length > 0 &&
        Children.toArray(
          products.map(
            ({
              group_id,
              products,
              selectedProduct,
              group_title,
              categoria_nombre_s,
              pronoun,
            }) => (
              <Products
                isCategories={false}
                pronoun={pronoun}
                list={products}
                selected={selectedProduct}
                title={group_title}
                title_single={categoria_nombre_s}
                id={group_id}
              />
            )
          )
        )}

      <div className="container">
        <div className="row">
          <InfoSelectedProducts
            text__button="Agregar al carrito"
            showOnlyPriceTotal
            total={price}
            list_products={listProducts}
            action={validatePromotion}
          />
        </div>
      </div>
    </>
  );
};
