import { useHistory } from 'react-router-dom';

export const useGetLocation = () => {
  const history = useHistory();
  const activatedPage = history.location.pathname
    .replace('/', '')
    .replace('-', '')
    .toLowerCase();
  const getLocationPathname = () => activatedPage;
  return {
    getLocationPathname,
  };
};
