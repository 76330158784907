import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateSelectedCategories } from '../../../redux/actions/e-commerce';
import { InfoSelectedProducts } from '../../../components/cart';
import { Products } from './Products';

export const PlanProducts = () => {
  const dispatch = useDispatch();
  const { categories, price, plan_products, products, plan_pan } = useSelector(
    (state) => state.plans.chosen_plan
  );
  const info = useSelector(
    (state) => state.plans.chosen_plan
  );
  const [listProducts, setListProducts] = useState([]);

  useEffect(() => {
    setListProducts(plan_products);
  }, [plan_products]);

  const validatePlan = () => {
    dispatch(validateSelectedCategories({ is_plans: true }));
  };
  return (
    <>
      {categories.length > 0 &&
        Children.toArray(
          categories.map(
            ({
              productos,
              selectedProduct,
              categoria_nombre,
              categoria_id,
              categoria_nombre_s,
            }) => (
              <Products
                isCategories
                list={productos}
                selected={selectedProduct}
                title={categoria_nombre}
                title_single={categoria_nombre_s}
                id={categoria_id}
                isPlan
              />
            )
          )
        )}
      {products.length > 0 &&
        Children.toArray(
          products.map(
            ({
              group_id,
              products,
              selectedProduct,
              group_title,
              categoria_nombre_s,
            }) => (
              <Products
                isCategories={false}
                list={products}
                selected={selectedProduct}
                title={group_title}
                title_single={categoria_nombre_s}
                id={group_id}
                isPlan
              />
            )
          )
        )}

      <div className="container">
        <div className="row">
          <InfoSelectedProducts
            text__button="Agregar al carrito"
            showDays={plan_pan !== 1}
            showTimetables
            total={price}
            list_products={listProducts}
            action={validatePlan}
          />
        </div>
      </div>
    </>
  );
};
