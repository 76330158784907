import { WrapperPage } from '../../../wrappers/WrapperPage';
import { SectionCart } from './sections/SectionCart';
import { SectionDelicacies } from '../../../components/sections/SectionDelicacies';

export const PageCart = () => {
  return (
    <WrapperPage>
      <SectionCart />
      <SectionDelicacies bg__dark />
    </WrapperPage>
  );
};
