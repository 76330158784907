import { v4 as uuidv4 } from 'uuid';
import { productsNames } from '../../../data/ui';
import { typesPersonalisedPlan } from '../../types/e-commerce/personalisedPlan';
import {
  addToCart,
  errorMessageSelectedProducts,
  openModalProduct,
} from '../ui';
import {
  addPersonalisedPlanToShoppingCart,
  calculateProductsTotal,
  updateSales,
  updateSubTotal,
} from './sale';

export const selectedPersonalisedPlanProduct = ({ productOf, product }) => {
  return (dispatch) => {
    if (productOf === productsNames.tabAllProducts) {
      dispatch(addCategoryProduct(product));
    } else if (productOf === productsNames.tabBreads) {
      const product_ = {
        product_name: product.name,
        product_id: product.id,
        category: 'Plan',
      };
      dispatch(setSelectedBreadProduct(product.id));
      dispatch(setBreadProduct(product_));
    }
  };
};

const addCategoryProduct = (product) => {
  return (dispatch, getState) => {
    const {
      personalisedPlanAllProducts: { products },
    } = getState().plans;
    const product_id = product.id;

    let newAllProducts = [];
    const product_ = {
      ...product,
      product_cant: 1,
    };

    if (products.some((product) => product.id === product_id)) {
      newAllProducts = products.filter((product) => product.id !== product_id);
      newAllProducts.push(product_);
    } else {
      newAllProducts = [...products, product_];
    }
    dispatch(setProduct(newAllProducts));
    dispatch(selectedCategoryProduct(product_.id, product_.product_id));
  };
};

const selectedCategoryProduct = (category_id, product_id) => {
  return (dispatch, getState) => {
    const {
      other_plan: { categories },
    } = getState().plans;
    const newArrCategories = categories.map((category) => {
      if (category.categoria_id === category_id) {
        category.selectedProduct = product_id;
      }
      return category;
    });

    dispatch(setSelectedCategoryProduct(newArrCategories));
  };
};

const setProduct = (product) => ({
  type: typesPersonalisedPlan.addProduct,
  payload: product,
});

const setSelectedCategoryProduct = (product_id) => ({
  type: typesPersonalisedPlan.selectedCategoryProduct,
  payload: product_id,
});

export const setTabAllProductsDays = (days) => ({
  type: typesPersonalisedPlan.tabAllProductsDays,
  payload: days,
});

export const setTabAllProductsShift = (shift) => ({
  type: typesPersonalisedPlan.tabAllProductsShift,
  payload: shift,
});

export const clearTabAllProducts = () => {
  return (dispatch, getState) => {
    const {
      other_plan: { categories },
    } = getState().plans;

    let new_categories = [];
    if (categories.length > 0) {
      new_categories = categories.map((category) => {
        category.selectedProduct = '';
        return category;
      });
    }
    const other_plan = {
      categories: new_categories,
    };
    dispatch(setClearAllProducts(other_plan));
  };
};

const setClearAllProducts = (new_other_plan) => ({
  type: typesPersonalisedPlan.clearTabAllProducts,
  payload: new_other_plan,
});
export const clearTabAllProductsID = () => ({
  type: typesPersonalisedPlan.clearTabAllProductsID,
});

const setBreadProduct = (product) => ({
  type: typesPersonalisedPlan.addBreadProduct,
  payload: product,
});

const setSelectedBreadProduct = (product) => ({
  type: typesPersonalisedPlan.selectedBreadProduct,
  payload: product,
});

export const clearTabBreadProduct = () => ({
  type: typesPersonalisedPlan.clearTabBreadProduct,
});

export const clearTabBreadProductID = () => ({
  type: typesPersonalisedPlan.clearTabBreadProductID,
});

export const setTabBreadProductDays = (days) => ({
  type: typesPersonalisedPlan.tabBreadProductDays,
  payload: days,
});

export const setTabBreadProductPrice = (price) => ({
  type: typesPersonalisedPlan.tabBreadProductPrice,
  payload: price,
});

export const setTabBreadProductShift = (shift) => ({
  type: typesPersonalisedPlan.tabBreadProductShift,
  payload: shift,
});

export const validatePersonalisedPlan = (productsOf) => {
  return (dispatch, getState) => {
    let isShowError = false;
    let errorMessage = 'Por favor seleccione ';

    /* ---------------- */
    let days_len;
    let shift_;
    let products_len;
    /* ---------------- */

    if (productsOf === productsNames.tabAllProducts) {
      const {
        personalisedPlanAllProducts: { days, products, shift },
      } = getState().plans;
      days_len = days.length;
      shift_ = shift;
      products_len = products.length;
    } else if (productsOf === productsNames.tabBreads) {
      const {
        personalisedPlanBreadProducts: { days, products, shift },
      } = getState().plans;
      days_len = days.length;
      shift_ = shift;
      products_len = products.length;
    }

    const {
      other_plan: { categories },
    } = getState().plans;

    if (products_len === 0 && productsOf === productsNames.tabBreads) {
      isShowError = true;
      errorMessage += 'un producto';
    } else if (
      products_len !== categories.length &&
      productsOf === productsNames.tabAllProducts
    ) {
      isShowError = true;
      errorMessage += 'un producto de cada categoría';
    } else if (days_len === 0) {
      isShowError = true;
      errorMessage += 'mínimo un día de entrega';
    } else if (shift_ === '') {
      isShowError = true;
      errorMessage += 'un horario';
    }

    const {
      errorMessages: { infoSelectedProducts },
    } = getState().ui;

    if (isShowError) {
      dispatch(
        errorMessageSelectedProducts({
          show: true,
          message: errorMessage,
        })
      );
      return;
    }
    if (infoSelectedProducts.show) {
      dispatch(
        errorMessageSelectedProducts({
          show: false,
          message: '',
        })
      );
    }

    dispatch(formattingPersonalisedPlan(productsOf));
  };
};

export const formattingPersonalisedPlan = (productsOf) => {
  return (dispatch, getState) => {
    let plan_id_;
    let array_dias;
    let hora;
    let productos;
    let precio;
    let total_personalised_plans =
      getState().sale.planes_personalizados.length + 1;

    let plan_name = 'Plan personalizado #' + total_personalised_plans;
    const { plan_id } = getState().plans.other_plan;

    if (productsOf === productsNames.tabAllProducts) {
      const { price } = getState().plans.other_plan;
      const {
        personalisedPlanAllProducts: { days, products, shift },
      } = getState().plans;

      plan_id_ = plan_id;
      array_dias = days;
      productos = products;
      hora = shift;
      precio = price;
    } else if (productsOf === productsNames.tabBreads) {
      const {
        personalisedPlanBreadProducts: { days, products, shift, price },
      } = getState().plans;

      plan_id_ = plan_id;
      array_dias = days;
      productos = products;
      hora = shift;
      precio = price;
    }

    const plan = {
      id: uuidv4(),
      plan_id: plan_id_,
      array_dias,
      preferencia: '',
      meses: 1,
      hora,
      productos,
      imagen: '',
      nombre: plan_name,
      precio,
      subtotal_: 0,
    };

    dispatch(addToCart(true));

    dispatch(addPersonalisedPlanToShoppingCart(plan));
    dispatch(calculateProductsTotal());
    dispatch(updateSubTotal(plan.precio));
    dispatch(updateSales(productsNames.personalisedPlans));

    const product_ = {
      id: plan.id,
      name: plan.nombre,
      list: plan.productos,
      image: plan.imagen,
      note: '',
    };
    dispatch(openModalProduct({ product: product_, productsOf }));

    setTimeout(() => {
      dispatch(addToCart(false));
    }, 3800);
  };
};

export const setPersonalisedPlanID = (id) => ({
  type: typesPersonalisedPlan.personalisedID,
  payload: id,
});
