import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  CartEmpty,
  CartPurchaseSummary,
  CartShopping,
} from '../../../../components/cart';
import { clearCart } from '../../../../redux/actions/e-commerce/sale';

export const SectionCart = () => {
  const dispatch = useDispatch();
  const { products_total } = useSelector((state) => state.sale);
  const history = useHistory();
  const goPagePlans = () => {
    sessionStorage.setItem('cartTab', 'all__menu');
    history.push('/carta');
  };

  const clearShoppingCart = () => {
    dispatch(clearCart());
    window.location.reload();
  };
  return (
    <section className="container-fluid container__shopping__cart">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mx__auto">
            {products_total > 0 ? (
              <div className="grid__shopping__cart">
                <CartPurchaseSummary />
                <CartShopping />
                <div className="actions">
                  <button
                    className="btn btn__outline__white"
                    onClick={goPagePlans}
                  >
                    Ver más productos
                  </button>
                  <button
                    className="btn btn__outline__white"
                    onClick={clearShoppingCart}
                  >
                    Limpiar carrito
                  </button>
                </div>
              </div>
            ) : (
              <CartEmpty />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
