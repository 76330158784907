import { Redirect, Route, Switch } from 'react-router-dom';
import { PageProfile, PageBuyDetails } from '../pages/e-commerce';
import { PageChangePass } from '../pages/auth/PageChangePass';

export const UserRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/usuario/mi-perfil" component={PageProfile} />

        <Route path="/usuario/cambiar-contrasena" component={PageChangePass} />

        <Route path="/usuario/detalles-compra" component={PageBuyDetails} />

        <Redirect to="/inicio" />
      </Switch>
    </>
  );
};
