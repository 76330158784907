import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalTermsNPolicity } from '../redux/actions/ui';
import { customStyles } from './customStyles';

export const ModalTermsNPolicity = () => {
  const {
    modals: { termsConditions },
  } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(toggleModalTermsNPolicity(false));
  };
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={termsConditions.isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 380,
          width: '100%',
        },
      }}
    >
      <div className="modal__body">
        <div className="modal__title">
          {termsConditions.modalName === 'Terms' && (
            <h1>Términos y Condiciones</h1>
          )}
          {termsConditions.modalName === 'Policity' && (
            <h1>Política de Privacidad</h1>
          )}
          <i className="fas fa-times text__orange" onClick={closeModal}></i>
        </div>
        <div className="divider mb__2"></div>
        <div className="modal__content content_terms m__0 scroll">
          <p className="font__klinicSlabmedium paragraph">
            Es requisito necesario para la adquisición de los productos que se
            ofrecen en este sitio, que lea y acepte los siguientes Términos y
            Condiciones que a continuación se redactan. El uso de nuestros
            servicios así como la compra de nuestros productos implicará que
            usted ha leído y aceptado los Términos y Condiciones de Uso en el
            presente documento. Para adquirir un producto, será necesario el
            registro por parte del usuario, con ingreso de datos personales
            fidedignos y definición de una contraseña.
          </p>
          <p className="font__klinicSlabmedium paragraph">
            El usuario puede elegir y cambiar la clave para su acceso de
            administración de la cuenta en cualquier momento, en caso de que se
            haya registrado y que sea necesario para la compra de alguno de
            nuestros productos. https://www.donsanti.pe/ no asume la
            responsabilidad en caso de que entregue dicha clave a terceros.
          </p>
          <p className="font__klinicSlabmedium paragraph">
            Todas las compras y transacciones que se lleven a cabo por medio de
            este sitio web, están sujetas a un proceso de confirmación y
            verificación, el cual podría incluir la verificación del stock y
            disponibilidad de producto, validación de la forma de pago,
            validación de la factura (en caso de existir) y el cumplimiento de
            las condiciones requeridas por el medio de pago seleccionado. En
            algunos casos puede que se requiera una verificación por medio de
            correo electrónico.
          </p>
          <p className="font__klinicSlabmedium paragraph">
            Los precios de los productos ofrecidos en esta Tienda Online es
            válido solamente en las compras realizadas en este sitio web.
          </p>
          <h3 className="font__klinicSlabmedium mt__2 mb__1">
            POLÍTICA DE REEMBOLSO Y GARANTÍA
          </h3>
          <p className="font__klinicSlabmedium paragraph">
            En el caso de productos no realizamos reembolsos después de que sean
            enviados. Por favor, te pedimos que verifiques tu pedido antes de
            realizar la compra.
          </p>
          <p className="font__klinicSlabmedium paragraph">
            Don santi reserva los derechos de cambiar o de modificar estos
            términos sin previo aviso.
          </p>
        </div>
      </div>
    </Modal>
  );
};
