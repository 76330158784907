import { useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPaginationPromotions } from '../../../../../redux/actions/e-commerce';

import { buildPaginationNum } from '../../../../../helpers/e_commerce';
import { SectionPagination } from '../../sections/SectionPagination';

export const Promotions = () => {
  const { pagination } = useSelector((state) => state.promotions);

  const { list, pageActivated, num_pages, loading } = pagination;

  const [arrNumbers, setArrNumbers] = useState([]);
  const [listPromotions, setListPromotions] = useState([]);
  const [page_activated, setPage_activated] = useState(1);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    let list__numbers = buildPaginationNum(num_pages);
    setArrNumbers(list__numbers);
  }, [num_pages]);

  useEffect(() => {
    setPage_activated(pageActivated);
  }, [pageActivated]);

  useEffect(() => {
    setListPromotions([]);
    setListPromotions(list);
  }, [list]);

  const nextPage = () => {
    changePage(page_activated + 1);
    scrollPagination();
  };
  const previousPage = () => {
    changePage(page_activated - 1);
    scrollPagination();
  };

  const changePage = (page) => {
    dispatch(getPaginationPromotions(page));
  };

  const scrollPagination = () => {
    window.scroll(0, 1500);
  };

  return (
    <SectionPagination
      list={listPromotions}
      loading={loading}
      arrNumbers={arrNumbers}
      pageActivated={page_activated}
      changePage={changePage}
      nextPage={nextPage}
      previousPage={previousPage}
      products_of="promotions"
    />
  );
};
