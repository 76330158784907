import { Children } from 'react';

export const ModalDaysList = ({ arrDays, action }) => {
  return (
    <div className="modal__list__days">
      {Children.toArray(
        arrDays.map(({ name, id, selectedOption }) => (
          <div className="form__control">
            <label>
              <input
                type="checkbox"
                className="dayCheck"
                checked={selectedOption}
                onChange={() => action(id)}
              />
              {name}
            </label>
          </div>
        ))
      )}
    </div>
  );
};
