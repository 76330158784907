import { CarouselCustomisable } from '../../../../components/carousels/listCarousels';

export const SectionFeatured = ({
  featured = [],
  showModalProduct = false,
  products_of = '',
  loading,
}) => {
  return (
    <section className="container-fluid bg__secundary section__cards__light">
      <div className="container section__lg__py">
        <div className="row">
          <div className="col-lg-10 mx__auto">
            {products_of === 'promotions' && <TitlePromotions />}
            {products_of === 'all__products' && <TitleProducts />}
          </div>
          <div className="col-lg-10 mx__auto">
            <div className="row">
              <CarouselCustomisable
                showModalProduct={showModalProduct}
                list={featured}
                name="featured"
                loading={loading}
                products_of={products_of}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const TitlePromotions = () => {
  return (
    <div className="title__wt__icons mb__2">
      <img
        src="../../images/decorations/icons/star_left.svg"
        className="icon"
        alt="Star Left"
      />
      <h1 className="font__escafinaHigh title__lg text__yellow text__center">
        Promos especiales
      </h1>
      <img
        src="../../images/decorations/icons/star_right.svg"
        className="icon"
        alt="Star Right"
      />
    </div>
  );
};

export const TitleProducts = () => {
  return (
    <div className="title__wt__icons mb__2">
      <img
        src="../../images/decorations/icons/fork_yellow.svg"
        className="icon"
        alt="Fork"
      />
      <h1 className="font__escafinaHigh title__lg text__yellow text__center">
        Los más destacados
      </h1>
      <img
        src="../../images/decorations/icons/spoon_yellow.svg"
        className="icon"
        alt="Spoon"
      />
    </div>
  );
};
