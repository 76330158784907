import { Provider } from 'react-redux';

import './styles/bootstrap/bootstrap.css';
import './styles/bootstrap/bootstrap.min.css';
import './styles/style.scss';

import { AppRouters } from './routes/AppRoutes';
import { store } from './redux/store';

export const DonSantiApp = () => {
  return (
    <Provider store={store}>
      <AppRouters />
    </Provider>
  );
};
