import { v4 as uuidv4 } from 'uuid';
import { fetchNotToken } from '../../../helpers/api/fetch';
import { methods_api } from '../../../helpers/api/methods_api';

import {
  addPlanToShoppingCart,
  calculateProductsTotal,
  updateSales,
  updateSubTotal,
} from './sale';

import {
  addKeyListCategories,
  formattingPlansOrPromotions,
  productMatchingTitle,
} from '../../../helpers/e_commerce';
import { typesPlans } from '../../types/e-commerce/plans';
import { addToCart, openModalProduct } from '../ui';
import { setPersonalisedPlanID } from './personalisedPlan';
import { productsNames } from '../../../data/ui';

export const getPlans = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchNotToken(methods_api.getPlans);
      const body = await resp.json();
      if (body.plans) {
        const plans = formattingPlansOrPromotions(body.plans);
        dispatch(optionsDaysOtherPlan(plans));
        dispatch(
          setPlans({
            data: plans,
            loading: false,
          })
        );
        dispatch(searchOtherPlan(plans));
      }
    } catch (error) {}
  };
};

export const setPlans = ({ data, loading }) => ({
  type: typesPlans.getPlans,
  payload: { data, loading },
});

export const chosenPlan = (plan) => ({
  type: typesPlans.chosenPlan,
  payload: plan,
});

export const setProductCategoryChosenPlan = (product) => ({
  type: typesPlans.addProductCategoryChosenPlan,
  payload: product,
});

export const setProductGroupChosenPlan = (product) => ({
  type: typesPlans.addProductGroupChosenPlan,
  payload: product,
});

export const setProductChosenPlan = (newPlanProducts) => ({
  type: typesPlans.addProductChosenPlan,
  payload: newPlanProducts,
});

export const addDaysChosenPlan = (days = []) => ({
  type: typesPlans.addDaysChosenPlan,
  payload: days,
});

export const addShiftChosenPlan = (shift) => ({
  type: typesPlans.addShiftChosenPlan,
  payload: shift,
});

export const formattingSelectedPlanData = () => {
  return (dispatch, getState) => {
    const {
      shift,
      days,
      plan_products,
      plan_id,
      plan_name,
      image,
      price,
      max_dias,
    } = getState().plans.chosen_plan;
    const plan = {
      id: uuidv4(),
      plan_id,
      array_dias: [...days],
      preferencia: '',
      meses: 1,
      hora: shift,
      max_dias: max_dias,
      productos: [...plan_products],
      imagen: image,
      nombre: plan_name,
      precio: price,
      subtotal_: parseInt(price),
    };
    dispatch(addToCart(true));
    dispatch(addPlanToShoppingCart(plan));
    dispatch(updateSales(productsNames.plans));

    dispatch(updateSubTotal(plan.precio));
    dispatch(calculateProductsTotal());

    const product_ = {
      id: plan.id,
      name: plan.nombre,
      list: plan.productos,
      image: plan.imagen,
      note: '',
    };
    dispatch(openModalProduct({ product: product_, productsOf: 'plans' }));

    setTimeout(() => {
      dispatch(addToCart(false));
    }, 3800);
  };
};

export const clearChosenPlan = () => ({
  type: typesPlans.clearChosenPlan,
});

const optionsDaysOtherPlan = (plans) => {
  return (dispatch) => {
    const options_days = plans
      .filter((plan) => plan.plan_pan === 1)
      .map(({ precio, dias, array_dias }, index) => {
        const select_options = {
          price: precio,
          days: array_dias,
          text_days: dias,
          index,
        };
        return select_options;
      });

    dispatch(setOptionsDays(options_days));
  };
};

const setOptionsDays = (options) => ({
  type: typesPlans.optionsOtherPlan,
  payload: options,
});

const searchOtherPlan = (plans) => {
  return (dispatch) => {
    const plan_otros_ = plans.filter(
      (plan) => plan.nombre.replace(' ', '').toLowerCase() === 'losotros'
    )[0];

    const { categorias, products_titles, productos, id, precio } = plan_otros_;

    const products = productMatchingTitle(products_titles, productos);
    const categories = addKeyListCategories(categorias);

    const plan_otros = {
      categories,
      products,
      plan_id: id,
      price: precio,
      isLoading: false,
    };

    dispatch(setOtherPlan(plan_otros));
    dispatch(setPersonalisedPlanID(id));
  };
};

const setOtherPlan = (plan) => ({
  type: typesPlans.otherPlan,
  payload: plan,
});
