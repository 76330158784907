export const CardCategory = ({ nextStep, id, i, category_product, image }) => {
  const title =
    category_product.charAt(0).toUpperCase() + category_product.slice(1);
  return (
    <>
      <div
        className={
          'card__category mb__2 font__escafinaHigh bg__category__' + (i + 1)
        }
        onClick={() => nextStep(id, category_product)}
      >
        <h2>
          <div className="card__category__round"></div>
          {title}
        </h2>
      </div>
    </>
  );
};
