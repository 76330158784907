import { useState, useEffect  } from 'react';
import Modal from 'react-modal';

import { useDispatch, useSelector } from 'react-redux';
import { addDaysModalDays, toggleModalDays } from '../redux/actions/ui';
import { addDaysChosenPlan } from '../redux/actions/e-commerce/plans';

import { listDays, productsNames } from '../data/ui';
import { customStyles } from './customStyles';

import {
  ModalDaysTitle,
  CounterSelectedDays,
  MessageMaxDays,
  ModalDaysList,
  ModalDaysBtnConfirm,
} from '../components/modals/Days';

import {
  setTabAllProductsDays,
  setTabBreadProductDays,
} from '../redux/actions/e-commerce/personalisedPlan';

export const ModalListDays = () => {
  const {
    modals: { days },
  } = useSelector((state) => state.ui);
  const { max_dias } = useSelector(
    (state) => state.plans.chosen_plan
  );
  const { arr_days, productsOf } = days;
  const dispatch = useDispatch();
  const [arrDays, setArrDays] = useState([]);
  const [arrSelectedDays, setArrSelectedDays] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (arr_days.length === 0) {
      setArrDays(listDays.map((d) => (d.selectedOption = false)));
      setArrSelectedDays([]);
    }

    const newListDays = listDays.map((DAY) => {
      if (arr_days.some((day) => day.name === DAY.name)) {
        DAY.selectedOption = true;
      }
      return DAY;
    });
    setArrDays(newListDays);
    setShowMessage(false);
  }, [arr_days]);

  const closeModal = () => {
    setArrSelectedDays([]);
    setArrDays(listDays);
    dispatch(toggleModalDays(false));
    dispatch(addDaysModalDays([]));
    if (productsOf === productsNames.plans) {
      dispatch(addDaysChosenPlan([]));
    } else if (productsOf === productsNames.tabAllProducts) {
      dispatch(setTabAllProductsDays([]));
    } else if (productsOf === productsNames.tabBreads) {
      dispatch(setTabBreadProductDays([]));
    }
  };

  const handleInput = (id) => {
    setShowMessage(false);

    if (arrSelectedDays.length === 0) {
      setArrSelectedDays((days) => [...days, id]);
      filterDays(id, true);
    } else if (arrSelectedDays.length > 0) {
      if (arrSelectedDays.includes(id)) {
        filterDays(id, false);
        const newArrSelectedDays = arrSelectedDays.filter((day) => day !== id);
        setArrSelectedDays(newArrSelectedDays);
        return;
      } else {
        filterDays(id, true);
        setArrSelectedDays((days) => [...days, id]);
      }

      if (arrSelectedDays.length >= max_dias) {
        filterDays(id, false);
        const newarrSelectedDays = arrSelectedDays.filter((day) => day !== id);
        setArrSelectedDays(newarrSelectedDays);
        setShowMessage(true);
        return;
      } else {
        setShowMessage(false);
      }
    }
  };

  const filterDays = (day_id, toggle) => {
    const newListDays = arrDays.map((day) => {
      if (day.id === day_id) {
        day.selectedOption = toggle;
      }
      return day;
    });
    setArrDays(newListDays);
  };

  const confirmDays = () => {
    const arrDaysID = arrDays.filter((day) => day.selectedOption);
    const days_ids = arrDaysID.map(({ id }) => id);

    dispatch(addDaysModalDays(arrDaysID.map(({ id, name }) => ({ id, name }))));
    if (productsOf === productsNames.plans) {
      dispatch(addDaysChosenPlan(days_ids));
    } else if (productsOf === productsNames.tabAllProducts) {
      dispatch(setTabAllProductsDays(days_ids));
    } else if (productsOf === productsNames.tabBreads) {
      dispatch(setTabBreadProductDays(days_ids));
    }
    dispatch(toggleModalDays(false));
  };
  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={days.isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 450,
        },
      }}
    >
      <div className="modal__body modal__days mb__0">
        <ModalDaysTitle action={closeModal} />
        <div className="divider"></div>
        <CounterSelectedDays max_dias={max_dias}selectedDays={arrSelectedDays.length} />
        <div className="modal__content mb__0">
          <ModalDaysList arrDays={arrDays} action={handleInput} />

          {showMessage && <MessageMaxDays />}

          <ModalDaysBtnConfirm
            disabled={arrSelectedDays.length !== 0 ? false : true}
            action={confirmDays}
          />
        </div>
      </div>
    </Modal>
  );
};
