import { useEffect, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { useHistory } from 'react-router';
import { WrapperPage } from '../../wrappers/WrapperPage';
import { fetchNotToken } from '../../helpers/api/fetch'; 
export const PageConfirmation = (props) => {
  const token = props.location.search !== ""  ? props.location.search.slice(3) : "";
  const [isLoading, setIsloading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error , setError] = useState({});
  const [venta,setVenta] = useState({});
  const history = useHistory()

  const validatedToken= async () =>{
    debugger
    try{
      let responseValidated = await (await fetchNotToken('validateActualizarPago',{"token":token},'POST')).json();
      if(responseValidated.status === "success"){
        setVenta(responseValidated.venta[0])
        setIsSuccess(true);
        setIsloading(false)
      }else{
        setError(responseValidated);
        setIsSuccess(false);
        setIsloading(false);
      }
    }catch(err){
      setIsSuccess(false);
      setIsloading(false);
    }
  }
  useEffect(() => {
    if(token.length === 0 ){
      debugger
      history.push('/inicio')
    }else{
      validatedToken();
    }
  }, []);

  // const goPageMain = () => {
  //   history.push('/inicio');
  // };

  return (
    <div className="pt__0">
      <WrapperPage bg__dark={true}>
        <section className="container container__steps_buy_products">
          <div className="row">
            <div className="col-lg-11 mx__auto">
              <div className="steps_buy_products">
                <button className={'activated'}>1. Confirmación Pago</button>
              </div>
              <div className="content__successful__shopping">
              {isLoading ? (
                <>
                  <Spinner />
                </> 
              ) : (
                <>
                  <div className="content__texts">
                  <h1 className="text__left font__escafinaHigh title__xxl fs__md__3 text__yellow">
                    {isSuccess 
                      ? 'Confirmación del Pago'
                      : 'Ups ah ocurrido un error ,' + error.message }
                  </h1>
                  <p className="font__klinicSlabbold paragraph my__2">
                    {isSuccess
                      ? 'Se ha aprobado el pedido #' +venta.id  + ' de ' + venta.nombre + ' ' + venta.apellido  + ', por un total de '+ venta.total
                      : ''}
                  </p>
                  <p className="font__klinicSlabbold paragraph my__2">
                    {isSuccess
                      ? '¡Gracias por confiar en nuestros servicios!'
                      : 'Por favor, contacte con su Desarrollador'}
                  </p>
                  </div>
                  <div className="content__img d__md__none">
                    <img src="../../images/sections/forms/register.png" alt="Bread" />
                  </div>
                </>
              )}
              </div>
            </div>
          </div>
        </section>
      </WrapperPage>
    </div>
  );
};
