export const addKeyListCategories = (list_categories = []) => {
  if (list_categories.length === 0) return [];

  const newArray = list_categories.map((category) => {
    if (category.productos.length === 1) {
      category.selectedProduct = category.productos[0].producto_id;
    } else {
      category.selectedProduct = '';
    }

    return category;
  });

  return newArray;
};
