import { typesAuth } from '../types';
import { fetchNotToken, fetchWithToken } from '../../helpers/api/fetch';
import { methods_api } from '../../helpers/api/methods_api';
import { getInformation } from './e-commerce';
import {
  loadingCreatePassword,
  loadingLogin,
  loadingPasswordChange,
  loadingPasswordReset,
  loadingRegister,
} from './ui';
import { setIdUser } from './e-commerce/sale';

export const startLogin = ({ email, password }) => {
  return async (dispatch) => {
    dispatch(loadingLogin(true));
    try {
      const resp = await fetchNotToken(
        methods_api.login,
        {
          email,
          password,
          name: 'desktop',
        },
        'POST'
      );
      const body = await resp.json();
      if (body.status === 'success') {
        const { status, ...data } = body;
        data.logged = true;
        localStorage.setItem('auth', JSON.stringify(data));

        dispatch(loadingLogin(false));
        dispatch(loggedIn(true));
        dispatch(getInformation(data.id));
        dispatch(setIdUser(data.id));

        return { success: true };
      } else {
        dispatch(loadingLogin(false));
        if (body.errors?.email.length > 0) {
          return { success: false, msg: body.errors.email[0] };
        } else if (body.errors?.name.length > 0) {
          return { success: false, msg: body.errors.name[0] };
        } else if (body.message !== '') {
          return { success: false, msg: body.message };
        }
        return { success: false, msg: 'Por favor vuelva a intentarlo' };
      }
    } catch (error) {
      dispatch(loadingLogin(false));
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const registerUser = (user) => {
  return async (dispatch) => {
    dispatch(loadingRegister(true));

    try {
      const resp = await fetchNotToken(
        methods_api.register,
        { ...user },
        'POST'
      );
      const body = await resp.json();
      if (body.status === 'success') {
        const { status, message, ...login } = body;
        const { ...data } = login.login;
        delete data.status;
        data.logged = true;

        localStorage.setItem('auth', JSON.stringify(data));
        dispatch(loadingRegister(false));
        dispatch(loggedIn(true));
        dispatch(getInformation(data.id));
        dispatch(setIdUser(data.id));
        return { success: true };
      } else {
        dispatch(loadingRegister(false));

        if (body.errors.name?.length > 0) {
          return { success: false, msg: body.errors.name[0] };
        } else if (body.errors.last_name?.length > 0) {
          return { success: false, msg: body.errors.last_name[0] };
        } else if (body.errors.cellphone?.length > 0) {
          return { success: false, msg: body.errors.cellphone[0] };
        } else if (body.errors.password?.length > 0) {
          return { success: false, msg: body.errors.password[0] };
        } else if (body.errors.terms_and_conditions?.length > 0) {
          return { success: false, msg: body.errors.terms_and_conditions[0] };
        } else if (body.errors.email?.length > 0) {
          return { success: false, msg: body.errors.email[0] };
        }

        return { success: false, msg: 'Por favor vuelva a intentarlo' };
      }
    } catch (error) {
      dispatch(loadingRegister(false));
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const resetPassword = ({ email }) => {
  return async (dispatch) => {
    dispatch(loadingPasswordReset(true));
    try {
      const resp = await fetchNotToken(
        methods_api.resetPassword,
        {
          email,
        },
        'POST'
      );
      const body = await resp.json();
      if (body.status === 'success') {
        dispatch(loadingPasswordReset(false));
        return { success: true };
      } else {
        dispatch(loadingPasswordReset(false));
        return {
          success: false,
          msg: body.errors.email,
        };
      }
    } catch (error) {
      dispatch(loadingPasswordReset(false));
      console.error(error);
    }
  };
};

export const createPassword = (data) => {
  return async (dispatch) => {
    dispatch(loadingCreatePassword(true));
    try {
      const resp = await fetchWithToken(
        methods_api.changePassword,
        data,
        'POST'
      );
      const body = await resp.json();

      if (body.status === 'success') {
        dispatch(loadingCreatePassword(false));

        return { success: true, msg: body.message };
      } else {
        if (body.errors?.password) {
          return {
            success: false,
            msg: body.errors?.password,
          };
        } else if (body.errors?.password_confirmation) {
          return {
            success: false,
            msg: body.errors?.password_confirmation,
          };
        }
        dispatch(loadingCreatePassword(false));
        return {
          success: false,
          msg: body.message,
        };
      }
    } catch (error) {
      dispatch(loadingCreatePassword(false));
    }
  };
};

export const changePasswordLogin = (data) => {
  return async (dispatch) => {
    dispatch(loadingPasswordChange(true));
    try {
      const resp = await fetchWithToken(
        methods_api.changePasswordLogin,
        data,
        'POST'
      );
      const body = await resp.json();

      if (body.status === 'success') {
        dispatch(loadingPasswordChange(false));

        return { success: true, msg: body.message };
      } else {
        if (body.errors?.password) {
          return {
            success: false,
            msg: body.errors?.password,
          };
        } else if (body.errors?.password_confirmation) {
          return {
            success: false,
            msg: body.errors?.password_confirmation,
          };
        }
        dispatch(loadingPasswordChange(false));
        return {
          success: false,
          msg: body.message,
        };
      }
    } catch (error) {
      dispatch(loadingPasswordChange(false));
    }
  };
};

export const loggedIn = (logged) => ({
  type: typesAuth.loggedIn,
  payload: logged,
});
