export const WrapperFooter = ({ children }) => {
  return (
    <footer className="container-fluid">
      <div className="bg__bread">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx__auto float-unset">
              <div className="logo">
                <img
                  src="../../images/logos/don_santi_footer.svg"
                  alt="Logo de Don Santi"
                />
              </div>
            </div>
            {children}
          </div>
        </div>
        <div className="divider"></div>
        <div className="container container__copyright">
          <div className="row">
            <div className="col-lg-12">
              <p>Copyright © 2021 Don Santi. Todos los derechos reservados</p>
              <p>*Todas las imágenes usadas en este sitio web son referenciales*</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
