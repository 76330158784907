import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { changePasswordLogin } from '../redux/actions/auth';

import { useTooglePass } from '../hooks/useTooglePass';

import { MessageError } from '../components/messages';
import { Spinner } from '../components';

const initialState = {
  old_password: '',
  password: '',
  password_confirmation: '',
};

export const FormChangePass = () => {
  const dispatch = useDispatch();
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');
  const { loading } = useSelector((state) => state.ui);
  const [payload, setPayload] = useState(initialState);
  const {
    togglePass: toggleOldPass,
    showPass: showOldPass,
    inputPassword: oldPass,
  } = useTooglePass();
  const { togglePass, showPass, inputPassword } = useTooglePass();

  const {
    togglePass: togglePass2,
    showPass: showPass2,
    inputPassword: inputPassword2,
  } = useTooglePass();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageError('');
    setMessageSuccess('');

    const password_1 = inputPassword.current.value;
    const password_2 = inputPassword2.current.value;

    if (password_1 !== password_2) {
      setMessageError('Las contraseñas nuevas no coinciden');
      return;
    } else if (password_1.length < 6) {
      setMessageSuccess('La contraseña debe contener más de 6 caracteres');
      return;
    }

    const res = await dispatch(
      changePasswordLogin({
        id: JSON.parse(localStorage.getItem('auth')).id,
        ...payload,
      })
    );

    if (res.success) {
      setMessageSuccess(res.msg);
      setTimeout(() => {
        setMessageSuccess('');
      }, 1500);
    } else {
      setMessageError(res.msg);
    }
    setPayload(initialState);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form__control">
        <label htmlFor="pass">Contraseña Actual</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Ingrese su contraseña actual"
            ref={oldPass}
            value={payload.old_password}
            onChange={({ target }) =>
              setPayload((prev) => {
                return { ...prev, old_password: target.value };
              })
            }
            required
          />
          {!showOldPass ? (
            <i className="fas fa-eye-slash" onClick={toggleOldPass}></i>
          ) : (
            <i className="fas fa-eye" onClick={toggleOldPass}></i>
          )}
        </div>
      </div>
      <div className="form__control">
        <label htmlFor="pass">Contraseña nueva</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Ingrese su contraseña nueva"
            ref={inputPassword}
            value={payload.password}
            onChange={({ target }) =>
              setPayload((prev) => {
                return { ...prev, password: target.value };
              })
            }
            required
          />
          {!showPass ? (
            <i className="fas fa-eye-slash" onClick={togglePass}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass}></i>
          )}
        </div>
      </div>
      <div className="form__control">
        <label htmlFor="pass">Confirmar contraseña nueva</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Ingrese su contraseña nueva"
            ref={inputPassword2}
            value={payload.password_confirmation}
            onChange={({ target }) =>
              setPayload((prev) => {
                return { ...prev, password_confirmation: target.value };
              })
            }
            required
          />
          {!showPass2 ? (
            <i className="fas fa-eye-slash" onClick={togglePass2}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass2}></i>
          )}
        </div>
      </div>

      {messageError !== '' && <MessageError message={messageError} />}
      {messageSuccess !== '' && (
        <div className="my__2">
          <p className="text__error">
            <i className="far fa-thumbs-up"></i> {messageSuccess}
          </p>
        </div>
      )}
      {loading.passChange && <Spinner />}
      <div className="text__center mt__3">
        <button className="btn btn__outline__white">Cambiar contraseña</button>
        <div className="mt__2">
          <Link to="/usuario/mi-perfil" className="link font__klinicSlabbold">
            Cancelar y volver a mi perfil
          </Link>
        </div>
      </div>
    </form>
  );
};
