export const typesSale = {
  addplan: '[SALE] ADD PLAN',
  updatePlans: '[SALE] UPDATE PLANS',
  deletePlan: '[SALE] DELETE PLAN',

  addPromotion: '[SALE] ADD PROMOTION',
  updatePromotions: '[SALE] UPDATE PROMOTIONS',
  deletePromotion: '[SALE] DELETE PROMOTION ',

  addPersonalisedBread: '[SALE] ADD PERSONALISED BREAD',
  updatePersonalisedBreads: '[SALE] UPDATE PERSONALISED BREADS',
  deletePersonalisedBread: '[SALE] DELETE PERSONALISED BREAD ',

  addProduct: '[SALE] ADD PRODUCT',
  updateProducts: '[SALE] UPDATE PRODUCTS',
  deleteProduct: '[SALE] DELETE PRODUCT',

  addPersonalisedPlan: '[SALE] ADD PERSONALISED PLAN',
  updatePersonalisedPlans: '[SALE] UPDATE PERSONALISED PLANS',
  deletePersonalisedPlan: '[SALE] DELETE PERSONALISED PLAN',

  calculateProductsTotal: '[SALE] CALCULATE PRODUCTS TOTAL',
  calculateSubTotal: '[SALE] CALCULATE SUBTOTAL',
  calculateTotal: '[SALE] CALCULATE TOTAL',
  clearCart: '[SALE] CLEAR CART',

  userId: '[SALE] USER ID',
  saleId: '[SALE] ID',
  updateRuc: '[SALE] UPDATE RUC',
  updateRazon: '[SALE] UPDATE RAZON',
  updateDireccion: '[SALE] UPDATE DIRECCION',
  updateMethodPayment: '[SALE] UPDATE METHOD PAYMENT',
  updateTypeDocument: '[SALE] UPDATE TYPE DOCUMENT',
  toggleTermsPolicy: '[SALE] TOGGLE TERMS POLICY',

  ip: '[SALE] SET USER IP',
};
