import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { loggedIn as LoggedIn } from '../redux/actions/auth';

import { AuthRoutes } from './AuthRoutes';
import { UserRoutes } from './UserRoutes';

import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';

import { PageLogin } from '../pages/auth/PageLogin';
import { PageRegister } from '../pages/auth/PageRegister';
import { PageForgetPassword } from '../pages/auth/PageForgetPassword';
import { PageMessageEmailSend } from '../pages/auth/PageMessageEmailSend';
import { PageCreatePassword } from '../pages/auth/PageCreatePassword';
import { PageMessageNewPassword } from '../pages/auth/PageMessageNewPassword';
import { PageConfirmation } from '../pages/e-commerce';
// import { getAllProducts } from '../redux/actions/e-commerce/products';
import {
  // getAllProducts,
  getAllProductsBread,
  getFeaturedProducts,
  getPlans,
  getFeaturedPromotions,
  getPaginationPromotions,
  getAllCategories,
  getInformation,
} from '../redux/actions/e-commerce';
import { getSales, setIdUser } from '../redux/actions/e-commerce/sale';
import { getDistricts, getDocumentTypes } from '../redux/actions/ui';

export const AppRouters = () => {
  const { loggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    dispatch(getPlans());
    // dispatch(getAllProducts());
    
    dispatch(getFeaturedProducts());
    // dispatch(getProductsPaginations());
    dispatch(getFeaturedPromotions());
    dispatch(getPaginationPromotions());
    dispatch(getDistricts());
    dispatch(getDocumentTypes());
    dispatch(getAllProductsBread());
    dispatch(getAllCategories())

  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const id_user = auth?.id ?? false;

    if (auth?.logged && id_user) {
      localStorage.setItem(
        'auth',
        JSON.stringify({ ...auth, logged: loggedIn })
      );

      dispatch(setIdUser(auth.id));
      const getDataUser = async () => {
        await dispatch(getInformation(auth.id));
        await dispatch(getSales());
      };
      getDataUser();
      dispatch(LoggedIn(true));
    } else if (!auth?.logged || !id_user) {
      localStorage.setItem('auth', JSON.stringify({ logged: false }));
      dispatch(LoggedIn(false));
    }
  }, [loggedIn, dispatch]);

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoutes
            path="/inicio-sesion"
            isAuthenticated={loggedIn}
            component={PageLogin}
          />
          <PublicRoutes
            path="/registro"
            isAuthenticated={loggedIn}
            component={PageRegister}
          />
          <PublicRoutes
            path="/olvide-contrasena"
            isAuthenticated={loggedIn}
            component={PageForgetPassword}
          />
          <PublicRoutes
            path="/envio-exitoso"
            isAuthenticated={loggedIn}
            component={PageMessageEmailSend}
          />
          <PublicRoutes
            exact
            path="/crear-contrasena/:token"
            isAuthenticated={loggedIn}
            component={PageCreatePassword}
          />
          <PublicRoutes
            path="/contrasena-creada"
            isAuthenticated={loggedIn}
            component={PageMessageNewPassword}
          />
          <PrivateRoutes
            path="/usuario/"
            isAuthenticated={loggedIn}
            component={UserRoutes}
          />

          <PublicRoutes
            path="/"
            isAuthenticated={false}
            component={AuthRoutes}
          />


        </Switch>
      </div>
    </Router>
  );
};
