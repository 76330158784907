import React from 'react';
import { Banner } from '../../../components';
import { WhatsApp } from '../../../components/WhatsApp';
import { WrapperPage } from '../../../wrappers/WrapperPage';
import { SectionCustomizedPlan } from './SectionCustomizedPlan';

export const PagePlanCustomized = () => {
  return (
    <WrapperPage>
      <SectionCustomizedPlan />
      <Banner
        text="La frescura es la principal fortaleza de nuestros productos."
        name="textFeatured"
      />
      <WhatsApp />
    </WrapperPage>
  );
};
