import { typesSale } from '../../types/e-commerce/sale';

const initialState = {
  user_id: '',
  sale_id: sessionStorage.getItem('sale_id')
    ? parseInt(sessionStorage.getItem('sale_id'))
    : '',
  method_pay: sessionStorage.getItem('method_pay')
    ? parseInt(sessionStorage.getItem('method_pay'))
    : 2,
  type_document: sessionStorage.getItem('type_document')
    ? parseInt(sessionStorage.getItem('type_document'))
    : 0,
  ruc: '',
  fiscal_address: '',
  business_name: '',
  privacy_policy: sessionStorage.getItem('terms')
    ? JSON.parse(sessionStorage.getItem('terms'))
    : 0,
  planes: sessionStorage.getItem('plans')
    ? JSON.parse(sessionStorage.getItem('plans'))
    : [],
  promociones: sessionStorage.getItem('promotions')
    ? JSON.parse(sessionStorage.getItem('promotions'))
    : [],
  planes_personalizados: sessionStorage.getItem('personalisedPlan')
    ? JSON.parse(sessionStorage.getItem('personalisedPlan'))
    : [],
  arma_tu_pan: sessionStorage.getItem('personalisedBread')
    ? JSON.parse(sessionStorage.getItem('personalisedBread'))
    : [],
  productos: sessionStorage.getItem('products')
    ? JSON.parse(sessionStorage.getItem('products'))
    : [],
  products_total: sessionStorage.getItem('products_total')
    ? JSON.parse(sessionStorage.getItem('products_total'))
    : 0,
  subtotal: sessionStorage.getItem('subtotal')
    ? parseFloat(sessionStorage.getItem('subtotal'))
    : 0,
  total: sessionStorage.getItem('total')
    ? parseFloat(sessionStorage.getItem('total'))
    : 0,
  ip: sessionStorage.getItem('user_ip') ?? '',
};

export const saleReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesSale.addplan:
      return { ...state, planes: [action.payload, ...state.planes] };
    case typesSale.updatePlans:
      return { ...state, planes: action.payload };

    case typesSale.addPersonalisedPlan:
      return {
        ...state,
        planes_personalizados: [action.payload, ...state.planes_personalizados],
      };
    case typesSale.updatePersonalisedPlans:
      return { ...state, planes_personalizados: action.payload };

    case typesSale.addPromotion:
      return { ...state, promociones: [action.payload, ...state.promociones] };
    case typesSale.updatePromotions:
      return { ...state, promociones: action.payload };

    case typesSale.addPersonalisedBread:
      return { ...state, arma_tu_pan: [action.payload, ...state.arma_tu_pan] };
    case typesSale.updatePersonalisedBreads:
      return { ...state, arma_tu_pan: action.payload };

    case typesSale.addProduct:
      return { ...state, productos: [action.payload, ...state.productos] };
    case typesSale.updateProducts:
      return { ...state, productos: action.payload };

    case typesSale.calculateProductsTotal:
      return { ...state, products_total: action.payload };
    case typesSale.calculateSubTotal:
      sessionStorage.setItem('subtotal', action.payload);
      return { ...state, subtotal: action.payload };
    case typesSale.clearCart:
      const user_id = JSON.parse(localStorage.getItem('auth')).id;
      return {
        user_id,
        sale_id: '',
        method_pay: 2,
        type_document: 0,
        ruc: '',
        fiscal_address: '',
        business_name: '',
        privacy_policy: 0,
        planes: [],
        promociones: [],
        planes_personalizados: [],
        arma_tu_pan: [],
        productos: [],
        products_total: 0,
        subtotal: 0,
        total: 0,
        ip: '',
      };

    case typesSale.calculateTotal:
      sessionStorage.setItem('total', action.payload);
      return { ...state, total: action.payload };
    case typesSale.userId:
      return { ...state, user_id: action.payload };
    case typesSale.toggleTermsPolicy:
      sessionStorage.setItem('terms', action.payload);
      return { ...state, privacy_policy: action.payload };

    case typesSale.updateRuc:
      return { ...state, ruc: action.payload };
    case typesSale.updateDireccion:
      return { ...state, fiscal_address: action.payload };
    case typesSale.updateRazon:
      return { ...state, business_name: action.payload };
    case typesSale.updateMethodPayment:
      sessionStorage.setItem('method_pay', action.payload);
      return { ...state, method_pay: action.payload };
    case typesSale.updateTypeDocument:
      sessionStorage.setItem('type_document', action.payload);
      return { ...state, type_document: action.payload };
    case typesSale.saleId:
      sessionStorage.setItem('sale_id', action.payload);
      return { ...state, sale_id: action.payload };
    case typesSale.ip:
      sessionStorage.setItem('user_ip', action.payload);
      return { ...state, ip: action.payload };
    default:
      return state;
  }
};
