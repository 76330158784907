import { useDispatch } from 'react-redux';
import { productsNames } from '../../data/ui';
import {
  addProductCategory,
  addProductGroup,
} from '../../redux/actions/e-commerce';
import { addProductChosenTo } from '../../redux/actions/e-commerce/categoriesProducts';
import { selectedPersonalisedPlanProduct } from '../../redux/actions/e-commerce/personalisedPlan';

export const CardCategoryProduct = ({
  producto_nombre,
  producto_descripcion,
  producto_id,
  selected,
  isProducts = false,
  isCategories,
  id,
  isPlan,
  title_category = '',
}) => {
  const dispatch = useDispatch();
  const addProduct = () => {
    if (isProducts && !isCategories && !isPlan) {
      const product = {
        productOf: productsNames.tabAllProducts,
        product: {
          product_name: producto_nombre,
          category: title_category,
          product_id: producto_id,
          id,
        },
      };
      dispatch(selectedPersonalisedPlanProduct(product));
    }

    const product_ = {
      product_id: producto_id,
      isPlans: isPlan,
      category: title_category,
    };
    if (isCategories && !isProducts) {
      dispatch(
        addProductCategory({
          category_id: id,
          ...product_,
        })
      );
    } else if (!isCategories && !isProducts) {
      dispatch(
        addProductGroup({
          group_id: id,
          ...product_,
        })
      );
    }

    const product = {
      product_name: producto_nombre,
      product_id: producto_id,
      category: title_category,
      id,
    };
    if (isPlan && !isProducts) {
      dispatch(
        addProductChosenTo({
          isPlan,
          product,
        })
      );
    } else if (!isPlan && !isProducts) {
      dispatch(
        addProductChosenTo({
          isPlan,
          product,
        })
      );
    }
  };
  return (
    <div className="card__dark">
      <div className="card__body">
        <h1 title={producto_nombre}>{producto_nombre}</h1>
        {isCategories ? '' : <p>{producto_descripcion}</p>}
      </div>
      <div className="card__footer">
        <button
          className={`btn btn__outline__white hover__yellow ${
            selected === producto_id ? 'selected__product' : ''
          }`}
          onClick={addProduct}
          disabled={selected === producto_id}
        >
          {selected === producto_id ? 'Seleccionado' : 'Seleccionar'}
        </button>
      </div>
    </div>
  );
};
