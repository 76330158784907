import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Banner } from '../../../components';
import {
  editingDeliveryInformation,
  editingUserInformation,
} from '../../../redux/actions/ui';
import { WrapperPage } from '../../../wrappers/WrapperPage';
import { SectionInfo } from './sections/SectionInfo';
import { SectionOrders } from './sections/SectionOrders';

export const PageProfile = () => {
  const dispatch = useDispatch();

  const disabledEditProfile = useCallback(() => {
    dispatch(editingUserInformation(false));
    dispatch(editingDeliveryInformation(false));
  }, [dispatch]);

  useEffect(() => {
    disabledEditProfile();
  }, [disabledEditProfile]);
  return (
    <WrapperPage>
      <Banner name="profile" text="Mi Perfil" />
      <SectionInfo />
      <SectionOrders />
    </WrapperPage>
  );
};
