import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { social_media } from '../data/ui';

export const Footer = () => {
  const { loggedIn } = useSelector((state) => state.auth);
  return (
    <div className="col-lg-10 mx__auto float-unset">
      <div className="footer__cols">
        <div className="footer__col footer__links">
          <ul>
            <li>
              <Link to="/inicio">
                <p>Inicio</p>
              </Link>
            </li>

            {/*             <li>
              <Link to="planes">
                <p>Planes</p>
              </Link>
            </li> */}

            <li>
              <Link to="carta">
                <p>Carta</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__col footer__links">
          <ul>
            <li>
              <Link to="nuestra-historia">
                <p>Nuestra historia</p>
              </Link>
            </li>
            <li>
              <Link to="carrito">
                <p>Mi carrito de compras</p>
              </Link>
            </li>
            <li>
              {loggedIn ? (
                <Link to="/usuario/mi-perfil">
                  <p>Mi perfil</p>
                </Link>
              ) : (
                <Link
                  to="registro"
                  onClick={() =>
                    sessionStorage.setItem('page-logged', 'profile')
                  }
                >
                  <p>Mi perfil</p>
                </Link>
              )}
            </li>
            {!loggedIn && (
              <>
                <li>
                  <Link
                    to="inicio-sesion"
                    onClick={() => sessionStorage.setItem('page-logged', '')}
                  >
                    <p>Inicia sesión</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="registro"
                    onClick={() =>
                      sessionStorage.setItem('page-logged', 'profile')
                    }
                  >
                    <p>Únete a nosotros</p>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="footer__col">
          <div className="footer__networks">
            {social_media.whatsApp !== '' && (
              <a href={social_media.whatsApp} rel="noreferrer" target="_blank">
                <i className="fab fa-whatsapp"></i>
              </a>
            )}
            {social_media.instagram !== '' && (
              <a href={social_media.instagram} rel="noreferrer" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            )}
            {social_media.twitter !== '' && (
              <a href={social_media.twitter} rel="noreferrer" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>
            )}
            {social_media.facebook !== '' && (
              <a href={social_media.facebook} rel="noreferrer" target="_blank">
                <i className="fab fa-facebook-square"></i>
              </a>
            )}
            {social_media.tiktok !== '' && (
              <a href={social_media.tiktok} rel="noreferrer" target="_blank">
                <i className="fab fa-tiktok"></i>
              </a>
            )}
            {social_media.email !== '' && (
              <a
                href={`mailto:${social_media.email}`}
                rel="noreferrer"
                target="_blank"
              >
                <i className="far fa-envelope"></i>
              </a>
            )}
          </div>
          <div className="map">
            <iframe
              title="Mapa de Lima"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31211.62097668704!2d-77.02877127903848!3d-12.081135552433695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9d7e1c028f7%3A0x8b7cabf84cc7197b!2sDon%20Santi!5e0!3m2!1ses!2spe!4v1634226978553!5m2!1ses!2spe"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
