import { WrapperPage } from '../../../wrappers/WrapperPage';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Banner } from '../../../components';
import { Gallery, Item } from 'react-photoswipe-gallery';
import img1 from '../../../images/sections/history/img-1.webp';
import img2 from '../../../images/sections/history/img-2.webp';
import img3 from '../../../images/sections/history/img-3.webp';
import img4 from '../../../images/sections/history/img-4.webp';
import img5 from '../../../images/sections/history/img-5.webp';
import img6 from '../../../images/sections/history/img-6.webp';
import img7 from '../../../images/sections/history/img-7.webp';
import img8 from '../../../images/sections/history/img-8.webp';
export const PageHistory = () => {
  const images = [
    { url: img4, class: '' },
    { url: img5, class: '' },
    { url: img6, class: '' },
    { url: img7, class: 'col__2' },
    { url: img2, class: '' },
    { url: img1, class: '' },
    { url: img8, class: '' },
    { url: img3, class: '' },
  ];
  return (
    <div className="page-history">
      <WrapperPage>
        <Banner text="Nuestra Historia" name="history" />
        <section className="container container__history__don__santi">
          <div className="col-lg-10 mx__auto">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="font__escafinaHigh title__lg text__yellow">
                  La Historia de <br /> Don Santi
                </h2>
              </div>
              <div className="col-lg-6">
                <p className="font__klinicSlabbold paragraph my__1">
                  Soy español con corazón peruano. Soy abuelo, padre y amigo. Me
                  describo a mí mismo como alguien amiguero y familiar. Soy
                  defensor de los animales y del ambiente, también me preocupan
                  los temas sociales. “Dador” por naturaleza, me gusta que el
                  otro esté cómodo, feliz y satisfecho. Me gustan las fiestas,
                  los bailes, el amor, en todos sus sentidos y formas. Entre mis
                  pasatiempos favoritos se encuentran la buena comida, el pan,
                  el rico vino y la buena comida peruana y española.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container section__history">
          <div className="row">
            <div className="col-lg-10 mx__auto">
              <div className="banner__history banner__history__2">
                <div className="banner__history__content">
                  <h1>
                    “¡Lo mejor de Perú y España, se unen para recibirlos con
                    alegría y gran sabor!”
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container section__history">
          <div className="row">
            <div className="col-lg-10 mx__auto">
              <div className="row">
                <div className="grid__images">
                  <Gallery>
                    {images.map((image, i) => {
                      return (
                        <Item
                          key={i}
                          original={image.url}
                          thumbnail={image.url}
                          width="500"
                          height="500"
                          title="Fundadores de Don Santi"
                        >
                          {({ ref, open }) => (
                            <img
                              className={`pointer ${image.class}`}
                              alt="Fundadores de Don Santi"
                              ref={ref}
                              onClick={open}
                              src={image.url}
                            />
                          )}
                        </Item>
                      );
                    })}
                  </Gallery>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mb__3">
          <div className="row">
            <div className="col-lg-7 mx__auto">
              <div className="title__wt__icons">
                <img
                  src="../../images/decorations/icons/fork.svg"
                  className="icon"
                  alt="Fork"
                />
                <h1 className="font__escafinaHigh title__lg fs__md__1_8 text__center">
                  ¡Vas a Flipar con nuestro sabor!
                </h1>
                <img
                  src="../../images/decorations/icons/spoon.svg"
                  className="icon"
                  alt="Spoon"
                />
              </div>
            </div>
          </div>
        </section>
      </WrapperPage>
    </div>
  );
};
