import { useSelector } from 'react-redux';
import { SectionFeatured } from '../../sections/SectionFeatured';
import { Promotions } from './Promotions';

export const TabPromotions = () => {
  const {
    featured: { list, loading },
  } = useSelector((state) => state.promotions);
  return (
    <>
      <SectionFeatured
        featured={list}
        loading={loading}
        products_of="promotions"
      />
      <Promotions />
    </>
  );
};
