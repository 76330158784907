import { useSelector } from 'react-redux';
import { CartPersonalisedBread } from '../ListProducts/CartPersonalisedBread';
import { CartPersonalisedPlans } from '../ListProducts/CartPersonalisedPlans';
import { CartPlans } from '../ListProducts/CartPlans';
import { CartProducts } from '../ListProducts/CartProducts';
import { CartPromotions } from '../ListProducts/CartPromotions';

export const CartShopping = () => {
  const { planes_personalizados, planes } = useSelector((state) => state.sale);
  const { promociones, arma_tu_pan, productos } = useSelector(
    (state) => state.sale
  );

  const hasProducts = productos.length > 0;
  const hasPlans = planes.length > 0;
  const hasPersonalisedPlans = planes_personalizados.length > 0;
  const hasPromotions = promociones.length > 0;
  const hasPersonalisedBread = arma_tu_pan.length > 0;

  return (
    <div className="content__shopping__cart">
      <h3 className="title text__yellow">MI CARRITO DE COMPRAS</h3>
      <div className="divider__yellow my__2"></div>
      {hasProducts && <CartProducts />}
      {hasPlans && <CartPlans divider={hasProducts} />}
      {hasPersonalisedPlans && (
        <CartPersonalisedPlans divider={hasPlans || hasProducts} />
      )}
      {hasPromotions && (
        <CartPromotions
          divider={hasPersonalisedPlans || hasPlans || hasProducts}
        />
      )}
      {hasPersonalisedBread && (
        <CartPersonalisedBread
          divider={
            hasPromotions || hasPersonalisedPlans || hasPlans || hasProducts
          }
        />
      )}
    </div>
  );
};
