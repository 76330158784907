export const CounterSelectedDays = ({ selectedDays , max_dias }) => {
  return (
    <div className="mt__2 text__center">
      <p className="mb__1 paragraph font__klinicSlabbold">
        Seleccione máx. {max_dias} días de la semana:
      </p>
      <p className="paragraph font__klinicSlabbook">
        Días seleccionados ( {selectedDays} )
      </p>
    </div>
  );
};
