import { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrderEmpty } from './orders/OrderEmpty';
import { ProductAccordeon } from './orders/ProductAccordeon';

export const SectionOrders = () => {
  const [showContent, setShowContent] = useState(false);
  const [accordionActivated, setAccordionActivated] = useState('');
  const { sale } = useSelector((state) => state.information);
  const toggleAccordion = (i) => {
    if (showContent) setAccordionActivated('');
    else if (!showContent) setAccordionActivated(i);
    setShowContent(!showContent);
  };

  return (
    <section className="container section__lg__py">
      <div className="row">
        <div className="col-lg-10 mx__auto">
          <h1 className="font__escafinaHigh title__xxl text__center">
            Mis pedidos
          </h1>
          <div className="divider my__2"></div>

          {sale.length > 0 ? (
            <div className="list-acordeons scroll">
              {sale.map((sale_, index) => (
                <ProductAccordeon
                  accordionActivated={index === accordionActivated}
                  showContent={showContent}
                  // index={index + 1}
                  key={index}
                  {...sale_}
                  toggle={() => toggleAccordion(index)}
                />
              ))}
            </div>
          ) : (
            <OrderEmpty />
          )}
        </div>
      </div>
    </section>
  );
};
