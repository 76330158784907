import { Children } from 'react';
import { CardCustomisable } from '../../../../components/cards';
import { SkeletonCardCustomisable } from '../../../../components/skeleton/cards/SkeletonCardCustomisable';

export const SectionPagination = ({
  list = [],
  loading,
  arrNumbers = [],
  pageActivated = 1,
  changePage,
  nextPage,
  previousPage,
  categoryName,
  products_of,
}) => {
  const skeletons = [1, 2, 3, 4, 5, 6];
  return (
    <>
      <section className="container section_pagination">
        {products_of !== '' && (
          <div className="row">
            <div className="col-lg-8 mx__auto">
              <h1 className="font__escafinaHigh title__xxl title__m__xxl text__yellow text__center mb__2 mt__4">
                {products_of === 'promotions' && 'Promociones'}
                {products_of === 'all__products' && categoryName}
              </h1>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-10 mx__auto">
            <div className="row">
              {loading
                ? Children.toArray(
                    skeletons.map(() => (
                      <div className="col-6 col-sm-6 col-lg-4 card__grid">
                        <SkeletonCardCustomisable />
                      </div>
                    ))
                  )
                : list.length > 0 &&
                  Children.toArray(
                    list.map((product) => (
                      <div className="col-6 col-sm-6 col-lg-4 card__grid">
                        <CardCustomisable
                          {...product}
                          products_of={products_of}
                        />
                      </div>
                    ))
                  )}
              {}
            </div>
          </div>
        </div>
      </section>

      {arrNumbers.length <= 1 ? (
        ''
      ) : (
        <div className="container pb__6">
          <div className="row">
            <div className="col-lg-10 mx__auto">
              <div className="pagination">
                <div className="actions__pagination">
                  {pageActivated !== 1 && (
                    <button className="btn btn__primary" onClick={previousPage}>
                      Anterior
                    </button>
                  )}
                  {arrNumbers.length !== pageActivated && (
                    <button className="btn btn__primary" onClick={nextPage}>
                      Siguiente
                    </button>
                  )}
                </div>

                <div className="list__pagination__numbers">
                  {Children.toArray(
                    arrNumbers.map((number) => (
                      <button
                        className={`btn btn__number ${
                          pageActivated === number ? 'activated' : ''
                        }`}
                        onClick={() => changePage(number)}
                      >
                        {number}
                      </button>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
