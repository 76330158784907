export const Title = ({ page, titles_categories , title }) => {
  return (
    <div className="container section__customized__plan">
      <div className="row text__center">
        <div className="col-lg-8 mx__auto">
          <div className="title__wt__icons">
            <img
              src="../../images/decorations/icons/fork.svg"
              className="icon"
              alt="Fork"
            />
            <h1 className="title">
              {page === 'plans' ? 'Plan' : 'Promoción'}: <br/> {title}
            </h1>
            <img
              src="../../images/decorations/icons/spoon.svg"
              className="icon"
              alt="Spoon"
            />
          </div>
          <p className="font__klinicSlabbold paragraph my__1 description text__yellow">
            {titles_categories.join(' + ')}
          </p>
        </div>
      </div>
    </div>
  );
};
