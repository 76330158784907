import { v4 as uuidv4 } from 'uuid';
import { productsNames } from '../../../data/ui';
import { fetchNotToken } from '../../../helpers/api/fetch';
import { methods_api } from '../../../helpers/api/methods_api';
import { formattingPlansOrPromotions } from '../../../helpers/e_commerce';
import { typesPromotions } from '../../types/e-commerce/promotions';
import { addToCart, openModalProduct } from '../ui';
import {
  addPromotionToShoppingCart,
  calculateProductsTotal,
  updateSales,
  updateSubTotal,
} from './sale';

export const getFeaturedPromotions = () => {
  return async (dispatch) => {
    const resp = await fetchNotToken(methods_api.getFeaturedPromotions);
    const body = await resp.json();

    if (body.promociones.length > 0) {
      const { promociones } = body;
      dispatch(
        setFeaturedPromotions(
          formattingPlansOrPromotions(
            promociones.filter((promo) => promo.id !== 12 && promo.id !== 11)
          )
        )
      );
      dispatch(getNumberPages());
    }
  };
};

const setFeaturedPromotions = (featured) => ({
  type: typesPromotions.featuredPromotions,
  payload: { list: featured, loading: false },
});

const getNumberPages = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchNotToken(
        methods_api.getPromotions + `?page=${1}`
      );
      const body = await resp.json();
      dispatch(setNumberPages(body.paginas));
    } catch (error) {}
  };
};

const setNumberPages = (num_pages) => ({
  type: typesPromotions.num_pages,
  payload: num_pages,
});

export const getPaginationPromotions = (page_num = 1) => {
  return async (dispatch) => {
    try {
      const resp = await fetchNotToken(
        methods_api.getPromotions + `?page=${page_num}`
      );
      const body = await resp.json();

      if (body.promociones.data.length > 0) {
        const { data, current_page } = body.promociones;

        const pagination = {
          list: formattingPlansOrPromotions(data),
          pageActivated: current_page,
          loading: false,
        };
        dispatch(setPaginationPromotions(pagination));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

const setPaginationPromotions = (pagination) => ({
  type: typesPromotions.paginationPromotions,
  payload: pagination,
});

export const chosenPromotion = (promotion) => ({
  type: typesPromotions.chosenPromotion,
  payload: promotion,
});

export const setProductCategoryChosenPromotion = (product) => ({
  type: typesPromotions.addProductCategoryChosenPromotion,
  payload: product,
});

export const setProductGroupChosenPromotion = (product) => ({
  type: typesPromotions.addProductGroupChosenPromotion,
  payload: product,
});

export const setProductChosenPromotion = (newPromotionalProducts) => ({
  type: typesPromotions.addProductChosenPromotion,
  payload: newPromotionalProducts,
});

export const formattingChosenPromotion = () => {
  return (dispatch, getState) => {
    const { promotion_products, promotion_id, promotion_name, image, price } =
      getState().promotions.chosen_promotion;

    const promotion = {
      id: uuidv4(),
      promocion_id: promotion_id,
      preferencia: '',
      cantidad: 1,
      productos: [...promotion_products],
      imagen: image,
      nombre: promotion_name,
      precio: price,
      subtotal_: 0,
    };

    dispatch(addToCart(true));
    dispatch(addPromotionToShoppingCart(promotion));
    dispatch(updateSubTotal(promotion.precio));
    dispatch(calculateProductsTotal());
    dispatch(updateSales(productsNames.promotions));

    const product_ = {
      id: promotion.id,
      name: promotion.nombre,
      list: promotion.productos,
      image: promotion.imagen,
      note: '',
    };
    dispatch(openModalProduct({ product: product_, productsOf: 'promotions' }));

    setTimeout(() => {
      dispatch(addToCart(false));
    }, 3800);
  };
};
export const clearChosenPromotion = () => ({
  type: typesPromotions.chosenPromotion,
});
