export const typesPlans = {
  getPlans: '[PLAN] GET PLANS',
  otherPlan: '[PLAN] OTHER PLAN',
  optionsOtherPlan: '[PLAN] OPTIONS OTHER PLAN',

  chosenPlan: '[PLAN] CHOSEN PLAN',
  addProductCategoryChosenPlan: '[PLAN] ADD PRODUCT IN CATEGORY OF CHOSEN PLAN',
  addProductGroupChosenPlan: '[PLAN] ADD PRODUCT IN GROUP OF CHOSEN PLAN',

  addDaysChosenPlan: '[PLAN] ADD DAYS OF CHOSEN PLAN',
  addShiftChosenPlan: '[PLAN] ADD SHIFT OF CHOSEN PLAN',
  clearChosenPlan: '[PLAN] CLEAR CHOSEN PLAN',

  addProductChosenPlan: '[PLAN] ADD PRODUCT CHOSEN PLAN',
};
