import { typesPersonalisedPlan } from '../../types/e-commerce/personalisedPlan';
import { typesPlans } from '../../types/e-commerce/plans';

const initialState = {
  list: {
    data: [],
    loading: true,
  },
  chosen_plan: {
    categories: [],
    products: [],
    plan_id: 0,
    plan_products: [],
    days: [],
    price: '0',
    shift: '',
    max_dias : 0,
  },
  personalisedPlanAllProducts: {
    plan_id: '',
    days: [],
    products: [],
    shift: '',
  },
  personalisedPlanBreadProducts: {
    plan_id: '',
    days: [],
    products: [],
    shift: '',
    price: '',
  },
  other_plan: {
    isLoading: true,
  },
};

const initialPersonalised = {
  days: [],
  products: [],
  shift: '',
};

export const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesPlans.getPlans:
      return {
        ...state,
        list: {
          ...action.payload,
        },
      };

    case typesPlans.chosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...initialState.chosen_plan,
          ...action.payload,
        },
      };

    case typesPlans.addProductCategoryChosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...state.chosen_plan,
          categories: action.payload,
        },
      };
    case typesPlans.addProductGroupChosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...state.chosen_plan,
          products: action.payload,
        },
      };
    case typesPlans.addProductChosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...state.chosen_plan,
          plan_products: action.payload,
        },
      };
    case typesPlans.addDaysChosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...state.chosen_plan,
          days: action.payload,
        },
      };
    case typesPlans.addShiftChosenPlan:
      return {
        ...state,
        chosen_plan: {
          ...state.chosen_plan,
          shift: action.payload,
        },
      };
    case typesPlans.clearChosenPlan:
      return {
        ...state,
        chosen_plan: initialState.chosen_plan,
      };

    /* ------------------------------------ */
    case typesPersonalisedPlan.personalisedID:
      return {
        ...state,
        personalisedPlanAllProducts: {
          ...state.personalisedPlanAllProducts,
          plan_id: action.payload,
        },
        personalisedPlanBreadProducts: {
          ...state.personalisedPlanBreadProducts,
          plan_id: action.payload,
        },
      };
    case typesPersonalisedPlan.addProduct:
      return {
        ...state,
        personalisedPlanAllProducts: {
          ...state.personalisedPlanAllProducts,
          products: action.payload,
        },
      };

    case typesPersonalisedPlan.selectedCategoryProduct:
      return {
        ...state,
        other_plan: {
          ...state.other_plan,
          categories: action.payload,
        },
      };
    case typesPersonalisedPlan.tabAllProductsDays:
      return {
        ...state,
        personalisedPlanAllProducts: {
          ...state.personalisedPlanAllProducts,
          days: action.payload,
        },
      };
    case typesPersonalisedPlan.tabAllProductsShift:
      return {
        ...state,
        personalisedPlanAllProducts: {
          ...state.personalisedPlanAllProducts,
          shift: action.payload,
        },
      };
    case typesPersonalisedPlan.clearTabAllProducts:
      return {
        ...state,
        personalisedPlanAllProducts: {
          ...initialPersonalised,
        },
        other_plan: {
          ...state.other_plan,
          ...action.payload,
        },
      };
    case typesPersonalisedPlan.addBreadProduct:
      return {
        ...state,
        personalisedPlanBreadProducts: {
          ...state.personalisedPlanBreadProducts,
          products: [action.payload],
        },
      };
    case typesPersonalisedPlan.tabBreadProductDays:
      return {
        ...state,
        personalisedPlanBreadProducts: {
          ...state.personalisedPlanBreadProducts,
          days: action.payload,
        },
      };
    case typesPersonalisedPlan.tabBreadProductPrice:
      return {
        ...state,
        personalisedPlanBreadProducts: {
          ...state.personalisedPlanBreadProducts,
          price: action.payload,
        },
      };

    case typesPersonalisedPlan.tabBreadProductShift:
      return {
        ...state,
        personalisedPlanBreadProducts: {
          ...state.personalisedPlanBreadProducts,
          shift: action.payload,
        },
      };
    case typesPersonalisedPlan.clearTabBreadProduct:
      return {
        ...state,
        personalisedPlanBreadProducts: {
          ...initialPersonalised,
        },
      };

    /* ---------------------- */
    case typesPlans.otherPlan:
      return {
        ...state,
        other_plan: {
          ...state.other_plan,
          ...action.payload,
        },
      };
    case typesPlans.optionsOtherPlan:
      return {
        ...state,
        other_plan: {
          ...state.other_plan,
          options: action.payload,
        },
      };
    default:
      return state;
  }
};
