export const typesPersonalisedPlan = {
  personalisedID: '[PERSONALISED PLAN] ID',
  addProduct: '[PERSONALISED PLAN] ADD  PRODUCT',
  selectedCategoryProduct: '[PERSONALISED PLAN] SELECTED CATEGORY PRODUCT',
  tabAllProductsDays: '[PERSONALISED PLAN] TAB ALL PRODUCTS DAYS',
  tabAllProductsShift: '[PERSONALISED PLAN] TAB ALL PRODUCTS SHIFT',
  clearTabAllProducts: '[PERSONALISED PLAN] CLEAR PRODUCT',
  clearTabAllProductsID: '[PERSONALISED PLAN] CLEAR PRODUCT ID',

  addBreadProduct: '[PERSONALISED PLAN] ADD BREAD PRODUCT',
  selectedBreadProduct: '[PERSONALISED PLAN] SELECTED BREAD PRODUCT',
  tabBreadProductDays: '[PERSONALISED PLAN] TAB BREAD PRODUCTS DAYS',
  tabBreadProductPrice: '[PERSONALISED PLAN] TAB BREAD PRODUCTS PRICE',
  tabBreadProductShift: '[PERSONALISED PLAN] TAB BREAD PRODUCTS SHIFT',
  clearTabBreadProduct: '[PERSONALISED PLAN] CLEAR BREAD PRODUCT',
  clearTabBreadProductID: '[PERSONALISED PLAN] CLEAR BREAD PRODUCT ID',
};
