export const formattingPlansOrPromotions = (plans_or_promotions) => {
  const newArr = [];
  plans_or_promotions.forEach((plan_or_promotion) => {
    const { titulos, lista, max_dias, ...data  } = plan_or_promotion;
    let products_titles = [];
    if (titulos.length > 1) {
      products_titles = removeDuplicateTitles(titulos);
    } else if (titulos.length === 1) {
      products_titles.push(...titulos[0]);
    }

    let categories_titles = [];
    if (lista.length > 1) {
      categories_titles = removeDuplicateTitles(lista);
    } else if (lista.length === 1) {
      categories_titles.push(lista[0]);
    }

    const newPromotion = {
      ...data,
      products_titles,
      categories_titles,
      max_dias
    };

    newArr.push(newPromotion);
  });

  return newArr;
};

export const removeDuplicateTitles = (titles) => {
  const listTitles = titles.map((title) => title[0]);
  return [...new Set(listTitles)];
};
