import { WrapperForm } from '../../wrappers/WrapperForm';
import { FormLogin } from '../../forms';
import { NavbarMain } from '../../components';

export const PageLogin = () => {
  return (
    <>
      <NavbarMain />
      <WrapperForm image="login" title="¡Nos alegra mucho verte de nuevo!">
        <FormLogin />
      </WrapperForm>
    </>
  );
};
