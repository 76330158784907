import { useHistory } from 'react-router-dom';

export const OrderEmpty = () => {
  const history = useHistory();

  const goPageCart = () => {
    sessionStorage.setItem('cartTab', 'all__menu');
    history.push('/carta');
  };
  return (
    <div className="container__emptyOrder">
      <div className="content__empty__order">
        <div className="emptyOrder__icons">
          <img
            src="../../images/decorations/icons/cart/frying_pan_icon.svg"
            className="icon"
            alt="Frying Pan Icon"
          />
          <img
            src="../../images/decorations/icons/cart/salt_icon.svg"
            className="icon"
            alt="Salt Icon"
          />
        </div>
        <div className="emptyOrder__texts">
          <h2 className="title__xxl fs__md__3 text__yellow font__escafinaLow mb__2">
            ¡Oh, no! Aún no tienes pedidos
          </h2>
          <p className="font__klinicSlabmedium paragraph mb__1">
            Elige entre nuestra deliciosa variedad de productos y promociones
          </p>

          <button
            className="btn btn__outline__white mt__2"
            onClick={goPageCart}
          >
            Explorar en el menú
          </button>
        </div>
      </div>
    </div>
  );
};
