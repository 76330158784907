import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePersonalisedBread } from '../../../../../redux/actions/e-commerce';
import { TypesBreads } from './TypesBreads';
import { TypesFillers } from './TypesFillers';

import { TitleCustomizedPlan } from './TitleCustomizedPlan';
import { Divider } from './Divider';
import { InfoSelectedProducts } from '../../../../../components/cart';

export const TabCustomizedPlan = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const { personalisedBread } = useSelector((state) => state.products);

  useEffect(() => {
    const productsNames = [];
    if (personalisedBread.pan_name !== '') {
      productsNames.push({
        product_name: personalisedBread.pan_name,
        category: 'Pan',
      });
    }
    if (personalisedBread.relleno_name !== '') {
      productsNames.push({
        product_name: personalisedBread.relleno_name,
        category: 'Relleno',
      });
    }
    setProducts(productsNames);
  }, [personalisedBread]);

  const addToCartCustomizedPlan = () => {
    dispatch(validatePersonalisedBread());
  };
  return (
    <section className="container-fluid section__customized__plan bg__secundary section__lg__py">
      <div className="container">
        <TitleCustomizedPlan />
        <TypesBreads />
        <Divider />
        <TypesFillers />

        <div className="mt__3">
          <InfoSelectedProducts
            text__button="Agregar al carrito"
            total="15.00"
            list_products={products}
            showOnlyPriceTotal
            action={addToCartCustomizedPlan}
          />
        </div>
      </div>
    </section>
  );
};
