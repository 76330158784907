export const productsNames = {
  plans: 'plans',
  promotions: 'promotions',
  fillers: 'fillers',
  breads: 'breads',
  products: 'products',
  tabBreads: 'bread__products',
  tabAllProducts: 'all__products',
  personalisedPlans: 'personalised__plans',
  personalisedBread: 'personalised_bread',
};
