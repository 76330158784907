import { errorMessageSelectedProducts } from '../ui';
import {
  formattingSelectedPlanData,
  setProductCategoryChosenPlan,
  setProductChosenPlan,
  setProductGroupChosenPlan,
} from './plans';

import {
  formattingChosenPromotion,
  setProductCategoryChosenPromotion,
  setProductChosenPromotion,
  setProductGroupChosenPromotion,
} from './promotions';

export const addProductChosenTo = ({ isPlan, product }) => {
  return (dispatch, getState) => {
    let list_products = [];
    if (isPlan) {
      const { plan_products } = getState().plans.chosen_plan;
      list_products = plan_products;
    } else {
      const { promotion_products } = getState().promotions.chosen_promotion;
      list_products = promotion_products;
    }

    let product_ = {
      ...product,
      product_cant: 1,
    };

    if (list_products.length === 0) {
      if (isPlan) {
        dispatch(setProductChosenPlan([{ ...product_ }]));
      } else {
        dispatch(setProductChosenPromotion([{ ...product_ }]));
      }
      return;
    }

    let newPromotionProducts = [];
    if (list_products.some((product) => product.id === product_.id)) {
      newPromotionProducts = list_products.filter(
        (product) => product.id !== product_.id
      );
      newPromotionProducts.push(product_);
    } else {
      newPromotionProducts = [...list_products, product_];
    }

    if (isPlan) {
      dispatch(setProductChosenPlan(newPromotionProducts));
    } else {
      dispatch(setProductChosenPromotion(newPromotionProducts));
    }
  };
};
export const addProductCategory = ({
  category_id,
  product_id,
  isPlans = true,
}) => {
  return (dispatch, getState) => {
    let arr_categories = [];
    if (isPlans) {
      const { categories } = getState().plans.chosen_plan;
      arr_categories = categories;
    } else {
      const { categories } = getState().promotions.chosen_promotion;
      arr_categories = categories;
    }

    if (arr_categories.length !== 0) {
      const newArrCategories = arr_categories.map((category) => {
        if (category.categoria_id === category_id) {
          category.selectedProduct = product_id;
        }
        return category;
      });

      if (isPlans) {
        dispatch(setProductCategoryChosenPlan(newArrCategories));
      } else {
        dispatch(setProductCategoryChosenPromotion(newArrCategories));
      }
    }
  };
};

export const addProductGroup = ({ group_id, product_id, isPlans = true }) => {
  return (dispatch, getState) => {
    let arr_products = [];
    if (isPlans) {
      const { products } = getState().plans.chosen_plan;
      arr_products = products;
    } else {
      const { products } = getState().promotions.chosen_promotion;
      arr_products = products;
    }

    if (arr_products.length !== 0) {
      const newArrProducts = arr_products.map((product) => {
        if (product.group_id === group_id) {
          product.selectedProduct = product_id;
        }
        return product;
      });

      if (isPlans) {
        dispatch(setProductGroupChosenPlan(newArrProducts));
      } else {
        dispatch(setProductGroupChosenPromotion(newArrProducts));
      }
    }
  };
};

export const validateLengthProductsCategories = ({
  categories = [],
  isPlans = true,
  isCategory = true,
}) => {
  return (dispatch) => {
    if (categories.length !== 0) {
      categories.forEach((categoria) => {
        let id = '';
        let list_products = [];
        if (isCategory) {
          const { categoria_id, productos } = categoria;
          id = categoria_id;
          list_products = productos;
        } else {
          const { group_id, products } = categoria;
          id = group_id;
          list_products = products;
        }

        if (list_products.length === 1) {
          const { producto_id, producto_nombre, producto_categoria_s, titulo } =
            list_products[0];
          const category = titulo.charAt(0).toUpperCase() + titulo.slice(1);
          const product = {
            id,
            product_id: producto_id,
            product_name: producto_nombre,
            category,
          };

          dispatch(addProductChosenTo({ isPlan: isPlans, product }));
        }
      });
    }
  };
};

export const validateSelectedCategories = ({ is_plans }) => {
  return (dispatch, getState) => {
    const { shift, days } = getState().plans.chosen_plan;

    let isShowError = false;
    let errorMessage = 'Por favor seleccione ';

    let products_length;
    let totalProducts;
    if (is_plans) {
      const { categories, products, plan_products } =
        getState().plans.chosen_plan;

      products_length = plan_products.length;
      totalProducts = categories.length + products.length;
    } else {
      const { categories, products, promotion_products } =
        getState().promotions.chosen_promotion;

      products_length = promotion_products.length;
      totalProducts = categories.length + products.length;
    }

    const {
      errorMessages: { infoSelectedProducts },
    } = getState().ui;

    if (totalProducts !== products_length) {
      const isMax1Category =
        totalProducts === 1 ? 'un producto' : 'un producto de cada categoría';
      isShowError = true;
      errorMessage += isMax1Category;
    } else if (days.length === 0 && is_plans) {
      isShowError = true;
      errorMessage += 'mínimo un día de entrega';
    } else if (shift === '' && is_plans) {
      isShowError = true;
      errorMessage += 'un horario';
    }

    if (isShowError) {
      dispatch(
        errorMessageSelectedProducts({
          show: true,
          message: errorMessage,
        })
      );
      return;
    }

    if (infoSelectedProducts.show) {
      dispatch(
        errorMessageSelectedProducts({
          show: false,
          message: '',
        })
      );
    }
    if (is_plans) {
      dispatch(formattingSelectedPlanData());
    } else {
      dispatch(formattingChosenPromotion());
    }
  };
};
