import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../redux/actions/auth';
import { toggleModalEmailError } from '../redux/actions/ui';

import { Spinner } from '../components';

export const FormForgetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.ui);
  const email = useRef('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(resetPassword({ email: email.current.value }));
    if (res.success) {
      history.push('/envio-exitoso');
    } else {
      dispatch(toggleModalEmailError(true, email.current.value));
    }
  };

  return (
    <>
      <p className="paragraph font__klinicSlabbold my__3">
        Ingresa tu correo electrónico y te enviaremos los pasos necesarios para
        crear una nueva contraseña.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form__control">
          <label htmlFor="email">Correo electrónico</label>
          <input
            ref={email}
            type="email"
            placeholder="Ingrese su correo"
            required
          />
        </div>
        {loading.passReset && <Spinner />}
        <div className="text__center mt__3">
          <button className="btn btn__outline__white">Enviar</button>
        </div>
        <div className="mt__3 links__flex">
          <p className="paragraph font__klinicSlabbold">
            ¿Recordaste tu contraseña?
            <Link to="/inicio-sesion" className="line__line__yellow">
              <span className="paragraph font__klinicSlabbold">
                Inicia sesión
              </span>
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};
