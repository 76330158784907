import { WrapperForm } from '../../wrappers/WrapperForm';
import { NavbarMain } from '../../components/NavbarMain';
import { FormForgetPassword } from '../../forms';
import { ModalEmailError } from '../../modals';

export const PageForgetPassword = () => {
  return (
    <>
      <ModalEmailError />
      <NavbarMain />
      <WrapperForm image="change_pass" title="¿Olvidaste tu contraseña?">
        <FormForgetPassword />
      </WrapperForm>
    </>
  );
};
