import pan_lg from '../../../../images/sections/pan_lg.webp';
export const SectionAboutUs = () => {
  return (
    <section className="container pb__10" id="section__main">
      <div className="row">
        <div className="col-lg-10 mx__auto">
          <div className="row pt__2 pb__xl__3">
            <div className="col-lg-6">
              <div className="logo__don__santi__2">
                <img
                  src="../../images/logos/Logo_Don-Santi2.png"
                  alt="Logo Don Santi"
                  title="Logo Don Santi"
                />
              </div>
              <p className="font__klinicSlabbold fs__2 my__1">
                Estamos comprometidos con lo orgánico, lo natural, la
                preservación del ambiente, el reciclaje, la responsabilidad
                socio-ambiental, utilizando insumos locales y haciendo un uso
                sostenible de los recursos.
              </p>
            </div>
            <div className="col-lg-6 d__lg__none">
              <img className="bread" src={pan_lg} alt="Bread" title="Bread" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
