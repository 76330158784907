import { useEffect, Fragment, useState, Children } from 'react';
import { useSelector } from 'react-redux';
import { productsNames } from '../../../data/ui';
import { CartProduct } from '../ProductsParts/CartProduct';
import { CartProductsHeader } from '../ProductsParts/CartProductsHeader';
import { removeKeysPlans } from '../removeKeysPlans';

export const CartPersonalisedPlans = ({ divider }) => {
  const { planes_personalizados } = useSelector((state) => state.sale);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const arr_plans = removeKeysPlans(planes_personalizados);
    setPlans(arr_plans);
  }, [planes_personalizados]);

  return (
    <Fragment>
      {divider && <div className="divider my__2"></div>}

      <div className="info__products mt__2">
        <CartProductsHeader
          isPlans
          productsOf={productsNames.personalisedPlans}
        />

        <div className="list__grid__card__products">
          {plans.length > 0 &&
            Children.toArray(
              plans.map((plan) => <CartProduct {...plan} isPlans />)
            )}
        </div>
      </div>
    </Fragment>
  );
};
