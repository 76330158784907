import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from '../reducers/auth';
import {
  plansReducer,
  informationReducer,
  productsReducer,
  promotionsReducer,
  saleReducer,
} from '../reducers/e-commerce';
import { uiReducer } from '../reducers/ui';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  information: informationReducer,
  plans: plansReducer,
  products: productsReducer,
  promotions: promotionsReducer,
  sale: saleReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
