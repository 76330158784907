import { typesPersonalisedBread } from '../../types/e-commerce/personalisedBread';
import { typesPersonalisedPlan } from '../../types/e-commerce/personalisedPlan';
import { typesProducts } from '../../types/e-commerce/products';

const initialState = {
  breads: {
    list: [],
    loading: true,
    selectedProductID: '',
    selectedProductID_: '',
  },
  fillers: {
    list: [],
    loading: true,
    selectedProductID: '',
  },
  featured: {
    list: [],
    loading: true,
  },
  pagination: {
    list: [],
    loading: true,
  },
  categories:{
    list:[],
    loading:true
  },
  num_pages: 1,
  personalisedBread: {
    pan_producto_id: '',
    pan_name: '',
    relleno_producto_id: '',
    relleno_name: '',
    image: '',
  },
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ---------------------------------------- */
    case typesProducts.categories:
      return{
        ...state,
        categories:{
          ...state.categories,
          ...action.payload
        }
      }
    case typesProducts.breads:
      return {
        ...state,
        breads: {
          ...state.breads,
          ...action.payload,
        },
      };
    case typesProducts.fillers:
      return {
        ...state,
        fillers: {
          ...state.fillers,
          ...action.payload,
        },
      };
    /* --------------------------------------------- */
    case typesPersonalisedBread.addTypeBreadBreads:
      const { image = '' } = action.payload;
      return {
        ...state,
        breads: {
          ...state.breads,
          selectedProductID: action.payload.id,
        },
        personalisedBread: {
          ...state.personalisedBread,
          pan_producto_id: action.payload.id,
          pan_name: action.payload.name,
          image,
        },
      };

    case typesPersonalisedBread.addTypeFillerBreads:
      return {
        ...state,
        fillers: {
          ...state.fillers,
          selectedProductID: action.payload.id,
        },
        personalisedBread: {
          ...state.personalisedBread,
          relleno_producto_id: action.payload.id,
          relleno_name: action.payload.name,
        },
      };
    case typesPersonalisedBread.clearPersonalisedBread:
      return {
        ...state,
        breads: {
          ...state.breads,
          selectedProductID: '',
        },
        fillers: {
          ...state.fillers,
          selectedProductID: '',
        },
        personalisedBread: {
          ...initialState.personalisedBread,
        },
      };
    /* -------------------------------------------- */

    case typesPersonalisedPlan.selectedBreadProduct:
      return {
        ...state,
        breads: {
          ...state.breads,
          selectedProductID_: action.payload,
        },
      };
    case typesPersonalisedPlan.clearTabBreadProductID:
      return {
        ...state,
        breads: {
          ...state.breads,
          selectedProductID_: '',
        },
      };
    case typesPersonalisedPlan.clearTabAllProductsID:
      return {
        ...state,
        featured: {
          ...state.featured,
          selectedProductID: '',
        },
      };
    /* -------------------------------------------- */
    case typesProducts.featured:
      return { ...state, featured: action.payload };
    case typesProducts.pagination:
      return { ...state, pagination: action.payload };
    case typesProducts.num_pages:
      return { ...state, num_pages: action.payload };
    default:
      return state;
  }
};
