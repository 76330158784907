import { typesAuth } from '../../types';

const initialState = {
  loggedIn: JSON.parse(localStorage.getItem('auth'))?.logged ?? false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesAuth.loggedIn:
      return { ...state, loggedIn: action.payload };

    default:
      return state;
  }
};
