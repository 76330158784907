import { Children, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';

import { breakPointsDelicacies } from '../../../helpers/breakPoints';

import { ArrowLeft, ArrowRight, Dot } from '../parts';
import { CardCustomisable } from '../../cards';
import { SkeletonCardCustomisable } from '../../skeleton/cards/SkeletonCardCustomisable';

export const CarouselCustomisable = ({
  name = '',
  list,
  products_of,
  loading,
}) => {
  const skeletons = [1, 2, 3, 4, 5];
  const [page, setPage] = useState(0);
  let history = useHistory();
  const list__carousel = {
    delicies: 'delicies',
    featured: 'featured',
  };
  let carousel;

  const goPageDelicacies = () => {
    sessionStorage.setItem('cartTab', 'promotions');
    history.push('/carta#previous-page-home');
  };

  return (
    <Carousel
      // enableAutoPlay
      // autoPlaySpeed={3000}
      outerSpacing={20}
      itemPadding={[0, 10]}
      breakPoints={breakPointsDelicacies}
      ref={(ref) => (carousel = ref)}
      showArrows={false}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <div
            className={`flex__dots__custom dots__col__2 mt__3 ${
              name === list__carousel.featured ? 'flex__between' : ''
            }`}
          >
            {pages.length > 1 && (
              <div className="dots__block">
                <ArrowLeft
                  action={() => {
                    setPage(activePage);
                    carousel.slidePrev();
                  }}
                />
                <ArrowRight action={() => carousel.slideNext()} />
              </div>
            )}

            {name === list__carousel.delicies && (
              <button className="btn btn__custom" onClick={goPageDelicacies}>
                <p>Ver todo</p>
              </button>
            )}

            {(name === list__carousel.featured && pages.length) > 1 && (
              <div className="list__dots">
                {pages.map((page) => {
                  const isActivePage = activePage === page;
                  return (
                    <Dot
                      action={() => onClick(page)}
                      activated={isActivePage}
                      key={page}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    >
      {loading
        ? Children.toArray(skeletons.map(() => <SkeletonCardCustomisable />))
        : Children.toArray(
            list.map((card) => (
              <CardCustomisable {...card} products_of={products_of} />
            ))
          )}
    </Carousel>
  );
};
