import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearCart,
  setClearCart,
} from '../../../../redux/actions/e-commerce/sale';
import Niubiz from '../../../../helpers/niubiz/apiNiubiz';
// setClearCart
import { Spinner } from '../../../../components/Spinner';
import { makePurchaseFinal } from '../../../../redux/actions/e-commerce/sale';
import {
  fetchWithTokenWithoutBody,
  createDocument,
} from '../../../../helpers/api/fetch';
import { getId, getFileName } from '../../../../utils/getFileName';
import { useLayoutEffect } from 'react';
export const Success = ({ token, nextStep }) => {
  const [isLoading, setIsloading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [methodPay, setMethodPay] = useState(
    JSON.parse(sessionStorage.getItem('method_pay'))
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scroll({
      top: 20,
      left: 100,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    // dispatch(clearCart());
    if (isSuccess) {
      dispatch(clearCart());
      nextStep(3);
      if (methodPay === 1) {
        let intervalId = setInterval(() => {
          // window.location.href = '../../inicio';
          // clearInterval(intervalId);
        }, 10000);
      }
    }
  }, [dispatch, nextStep, isSuccess]);
  useEffect(() => {
    /* const sendApiSunat = async () => {
      if (documentId === '0') {
        let cantidad_boletas = await fetchWithTokenWithoutBody('cont_b');
        let fileName = getFileName(cantidad_boletas.message, 'B001', '03');
        let id = getId(cantidad_boletas.message, 'B001');
        let response = await createDocument(
          '03',
          documentNumber,
          typeDocument,
          fileName,
          id,
          info
        );
        if (response.status !== 'ERROR') {
          setIsSuccess(true);
          dispatch(clearCart());
          // await dispatch(makePurchaseFinal())
        } else {
          setIsSuccess(false);
        }
        setIsloading(false);
      } else {
        let cantidad_facturas = await fetchWithTokenWithoutBody('cont_f');
        let fileName = getFileName(cantidad_facturas.message, 'F001', '01');
        let id = getId(cantidad_facturas.message, 'F001');
        let response = await createDocument('01', ruc, '6', fileName, id, info);
        if (response.status !== 'ERROR') {
          setIsSuccess(true);
          dispatch(clearCart());
          //await dispatch(makePurchaseFinal())
        } else {
          setIsSuccess(false);
        }
        setIsloading(false);
      }
    }; */
    const sendTransaction = async () => {
      setIsloading(true);
      if (token) {
        const niubiz = new Niubiz();
        const order = localStorage.getItem('order-purchasenumber');
        const resp = await niubiz.authorization(token, order);

        if (resp.status === 'error' || resp.errorCode) {
          setIsSuccess(false);
          setIsloading(false);
          return;
        }
        if (resp.message) {
          sessionStorage.clear();
          setIsloading(false);
          setIsSuccess(true);
          sessionStorage.clear();
          dispatch(setClearCart());
        }
        return;
      }
    };
    if (methodPay === 1) {
      sendTransaction();
    } else {
      setIsloading(false);
      setIsSuccess(true);
    }

    return () => {
      localStorage.removeItem('order-purchasenumber');
    }
  }, [token]);

  // const goPageMain = () => {
  //   history.push('/inicio');
  // };

  return (
    <div className="pt__0">
      {isLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className="content__successful__shopping">
          <div className="content__texts">
            <h1 className="text__left font__escafinaHigh title__xxl fs__md__3 text__yellow">
              {isSuccess && methodPay === 1
                ? 'Tu compra se ha realizado con éxito'
                : methodPay === 1
                ? '¡Ups! Hubo un error al procesar el pago'
                : ''}
              {isSuccess && methodPay !== 1 ? 'Estamos validando tu pago' : ''}
            </h1>
            <p className="font__klinicSlabbold paragraph my__2">
              {isSuccess && methodPay === 1
                ? 'Estarás recibiendo tu pedido dentro de 30 a 45 minutos aproximadamente'
                : ''}
              {isSuccess && methodPay !== 1
                ? 'Una vez confirmado, estarás recibiendo tu pedido dentro de 30 a 45 minutos aproximadamente'
                : ''}
            </p>
            <p className="font__klinicSlabbold paragraph my__2">
              {isSuccess
                ? '¡Gracias por confiar en nuestros servicios!'
                : 'Por favor, inténtalo de nuevo'}
            </p>
            {!isSuccess && (
              <button
                className="btn btn__outline__white mt__4"
                onClick={() => {
                  // sessionStorage.clear();
                  history.push('/usuario/detalles-compra');
                }}
              >
                Realizar pago
              </button>
            )}
          </div>
          <div className="content__img d__md__none">
            <img src="../../images/sections/forms/register.png" alt="Bread" />
          </div>
        </div>
      )}
    </div>
  );
};
