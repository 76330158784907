export const OrderInformation = ({
  type_document,
  address,
  reference,
  link,
  pago
}) => {
  return (
    <div className="sale__info">
      <div className="">
        <h2>Tipo de documento:</h2>
        <p>{type_document === 0 ? 'Boleta' : 'Factura'}</p>
      </div>
      <div className="">
        <h2>Dirección: </h2>
        <p>{address}</p>
      </div>
      <div className="">
        <h2>Estado: </h2>
        <p>{pago === 0 ? "Pendiente" : "Pagado"}</p>
      </div>
      {reference && (
        <div className="">
          <h2>Referencia: </h2>
          <p>{reference}</p>
        </div>
      )}
      {link !== '' && (
        <div className="button__link">
          <a href={link} target="_blank" rel="noreferrer">
            <button className="btn btn__white">Ver documento</button>
          </a>
        </div>
      )}
    </div>
  );
};
