import Interbank from '../../../../../images/pago_logos/interbank.png';
import plin from '../../../../../images/pago_logos/plin.png';
import Scotiabank from '../../../../../images/pago_logos/scotiabank.png';
import Yape from '../../../../../images/pago_logos/yape.png';
import Bcp from '../../../../../images/pago_logos/bcp.png';
import Bbva from '../../../../../images/pago_logos/bbva.png';

export const Transferencia = () => {
  return (
    <div className="card__content__transferencia bg__white text__black">
      {/* <div className="row mb__2">
        <div className="col-sm-12 col-lg-6">
          <img src={Interbank} alt="banco interbank" className="w__80 mb__1" />
          <img src={plin} alt="banco interbank plin" className="w__25" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <ul>
            <li>999 999 999</li>
            <li>#############</li>
            <li>CCI: ##########</li>
            <li>A nombre de #######</li>
          </ul>
        </div>
      </div> */}
      {/* <div className="row mb__2">
        <div className="col-sm-12 col-lg-6">
          <img src={Scotiabank} alt="banco interbank" className="w__100" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <ul>
            <li>#############</li>
            <li>CCI: ##########</li>
            <li>A nombre de #######</li>
          </ul>
        </div>
      </div> */}
      <div className="row mb__3">
        <div className="col-sm-12 col-lg-6">
          <img src={Yape} alt="banco interbank" className="w__75 w_md_50 mb__1" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <ul>
            <li className="item_trans">+51 997 518 833</li>
          </ul>
        </div>
      </div>
      <div className="row mb__3">
        <div className="col-sm-12 col-lg-6">
          <img src={Bcp} alt="banco bcp" className="w__75 w_md_50 mb__1" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <ul>
            <li  className="item_trans">Nro. de cuenta 193-2596379-0-48</li>
            <li  className="item_trans">CCI: 00219300259637904817</li>
            <li  className="item_trans">A nombre de Gabriel Gil ENTERTAINMENT SAC</li>
          </ul>
        </div>
      </div>
      <div className="row mb__3">
        <div className="col-sm-12 col-lg-6">
          <img src={Bbva} alt="banco bbva" className="w__75 w_md_50 mb__1" />
        </div>
        <div className="col-sm-12 col-lg-6">
          <ul>
            <li  className="item_trans" >Nro. de cuenta 0011-0615-0100020606-28</li>
            <li  className="item_trans">CCI: 011-615-000100020606-28</li>
            <li  className="item_trans">A nombre de Gabriel Gil ENTERTAINMENT SAC</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
