import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { toggleModalProductInCart } from '../redux/actions/ui';
import { customStyles } from './customStyles';

const ModalProductInCart = () => {
  const { isOpen } = useSelector((state) => state.ui.modals.productInCart);
  const history = useHistory();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleModalProductInCart(false));
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 350,
        },
      }}
    >
      <div className="modal__body modal__days mb__0">
        <div className="modal__title">
          <h1>Producto existente</h1>
          <i className="fas fa-times text__orange" onClick={closeModal}></i>
        </div>
        <div className="divider mb__2"></div>
        <p className="klinic_slabmedium">
          El producto ya se encuentra en tu carrito de compras, por favor
          dirígete al carrito y cambia la cantidad.
        </p>
        <div className="modal__buttons">
          <button
            className="btn btn__outline__primary w__100 mt__2"
            onClick={closeModal}
          >
            No, gracias
          </button>
          <button
            className="btn btn__primary w__100 mt__1"
            onClick={() => {
              history.push('/carrito');
              closeModal();
            }}
          >
            Ir al carrito
          </button>
        </div>
        <div className="modal__content mx__0 mb__0"></div>
      </div>
    </Modal>
  );
};

export default ModalProductInCart;
