import { v4 as uuidv4 } from 'uuid';
import { productsNames } from '../../../data/ui';
import { fetchNotToken } from '../../../helpers/api/fetch';
import { methods_api } from '../../../helpers/api/methods_api';
import { typesProducts } from '../../types/e-commerce/products';
import { addToCart, openModalProduct, toggleModalProductInCart } from '../ui';
import {
  addProductToShoppingCart,
  calculateProductsTotal,
  updateSales,
  updateSubTotal,
} from './sale';

export const getAllCategories = () => {
  return async (dispatch) => {
    const resp = await fetchNotToken(methods_api.getCategories);
    const body = await resp.json();

    if (body.categorias.length > 0) {
      const { categorias } = body;
      dispatch(setCategories(categorias));
    }
  };
};

export const getAllProducts = () => {
  return async (dispatch) => {
    const resp = await fetchNotToken(methods_api.getProducts);
    const body = await resp.json();

    if (body.productos.data.length > 0) {
      const { data } = body.productos;

      const breads = data.filter((bread) => bread.categoria_producto_id === 1);
      const fillers = data.filter((fill) => fill.categoria_producto_id === 2);

      dispatch(setBreads(breads));
      dispatch(setFillers(fillers));

      dispatch(setNumberPages(body.productos.last_page));
    }
  };
};
export const getAllProductsBread = () => {
  return async (dispatch) => {
    const resp = await fetchNotToken(methods_api.getProductsBuildBread);
    const body = await resp.json();
    if (body.productos.data.length > 0) {
      const { data } = body.productos;

      const breadsonly = data.filter(
        (bread) => bread.categoria_producto_id === 1
      );

      const fillers = data.filter((fill) => fill.categoria_producto_id === 2);
      dispatch(setBreads(breadsonly));
      dispatch(setFillers(fillers));
    }
  };
};

export const setBreads = (breads) => ({
  type: typesProducts.breads,
  payload: { list: breads, loading: false },
});

export const setFillers = (fillers) => ({
  type: typesProducts.fillers,
  payload: { list: fillers, loading: false },
});

export const setCategories = (categories) => ({
  type: typesProducts.categories,
  payload: { list: categories, loading: false },
});

export const setNumberPages = (num_pages) => ({
  type: typesProducts.num_pages,
  payload: num_pages,
});
export const getFeaturedProducts = () => {
  return async (dispatch) => {
    const resp = await fetchNotToken(methods_api.getFeaturedProducts);
    const body = await resp.json();

    if (body.productos.length > 0) {
      const products = body.productos.filter((product) => product.ver === 1);
      dispatch(setFeatured(products));
    }
  };
};
export const setFeatured = (featured) => ({
  type: typesProducts.featured,
  payload: { list: featured, loading: false },
});
export const getProductsPaginations = (id_category, page_num = 1) => {
  return async (dispatch) => {
    const pagination = {
      list: [],
      pageActivated: page_num,
      loading: true,
    };
    dispatch(setProductsPagination(pagination));

    const resp = await fetchNotToken(
      methods_api.getProducts + `?page=${page_num}&category_id=${id_category}`
    );
    const body = await resp.json();

    if (body.productos.data.length > 0) {
      const { data, links, total } = body.productos;

      const number_pages = Math.ceil(total / 12);
      dispatch(setNumberPages(number_pages));
      const products = data;

      let page_activated_num;
      if (links) {
        const page_activated = links.filter((link) => link.active);
        page_activated_num = parseInt(page_activated[0].label);
      } else if (!links) {
        page_activated_num = 1;
      }
      pagination.list = [...products];
      pagination.pageActivated = page_activated_num;
      pagination.loading = false;
      dispatch(setProductsPagination(pagination));
    } else {
      pagination.loading = false;
      dispatch(setProductsPagination(pagination));
    }
  };
};

export const setProductsPagination = ({ list, pageActivated, loading }) => ({
  type: typesProducts.pagination,
  payload: { list, pageActivated, loading: loading },
});

export const addProductToCart = (product_) => {
  return (dispatch, getState) => {
    const id = uuidv4();
    const { promociones, productos } = getState().sale;
    const products_names = [...promociones, ...productos].map(
      (product) => product.nombre
    );

    if (products_names.includes(product_.name)) {
      dispatch(toggleModalProductInCart(true));
      return;
    }

    const { name, image, list, ...product } = product_;

    dispatch(
      addProductToShoppingCart({
        ...product,
        nombre: product_.name,
        imagen: product_.image,
        productos: [],
        id,
        preferencia: '',
        subtotal_: 0,
      })
    );
    dispatch(addToCart(true));
    dispatch(calculateProductsTotal());
    dispatch(updateSales(productsNames.products));
    dispatch(updateSubTotal(product_.precio));
    dispatch(
      openModalProduct({
        product: { ...product_, id, note: '' },
        productsOf: 'products',
      })
    );

    setTimeout(() => {
      dispatch(addToCart(false));
    }, 3800);
  };
};
