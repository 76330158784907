import { useState, useEffect } from 'react';

import { Tabs } from './parts/Tabs';
import { CustomizedPlanTitle } from './parts/CustomizedPlanTitle';
import { TabAllProducts } from './parts/TabAllProducts';
import { TabBreadProducts } from './parts/TabBreadProducts';
import { useDispatch } from 'react-redux';
import {
  clearTabAllProducts,
  clearTabAllProductsID,
  clearTabBreadProduct,
  clearTabBreadProductID,
  setTabAllProductsDays,
  setTabBreadProductDays,
} from '../../../redux/actions/e-commerce/personalisedPlan';
import {
  addDaysModalDays,
  addScreenModalDays,
  showSelectDays,
} from '../../../redux/actions/ui';
import { productsNames } from '../../../data/ui';

export const SectionCustomizedPlan = () => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(productsNames.tabAllProducts);

  useEffect(() => {
    if (selectedTab === productsNames.tabAllProducts) {
      dispatch(clearTabBreadProduct());
      dispatch(clearTabBreadProductID());
      dispatch(setTabBreadProductDays([]));
      dispatch(addScreenModalDays(productsNames.tabAllProducts));
      dispatch(addDaysModalDays([]));
      dispatch(showSelectDays(false));
    } else {
      dispatch(clearTabAllProducts());
      dispatch(clearTabAllProductsID());
      dispatch(setTabAllProductsDays([]));
      dispatch(addScreenModalDays(productsNames.tabBreads));
      dispatch(addDaysModalDays([]));
      dispatch(showSelectDays(true));
    }
  }, [selectedTab, dispatch]);

  return (
    <section className="container section__customized__plan section__lg__py">
      <CustomizedPlanTitle tab__activated={selectedTab} />
      <Tabs
        tab1Activated={selectedTab === productsNames.tabAllProducts}
        action1Activated={() => setSelectedTab(productsNames.tabAllProducts)}
        tab2Activated={selectedTab === productsNames.tabBreads}
        action2Activated={() => setSelectedTab(productsNames.tabBreads)}
      />
      {selectedTab !== productsNames.tabAllProducts && (
        <div className="row mt__3 mb__2">
          <div className="col-lg-10 mx__auto">
            <p className="font__klinicSlabmedium paragraph">
              Seleccione el tipo de pan:
            </p>
          </div>
        </div>
      )}

      <div className="row">
        {selectedTab === productsNames.tabAllProducts ? (
          <TabAllProducts />
        ) : (
          <TabBreadProducts />
        )}
      </div>
    </section>
  );
};
