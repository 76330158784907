import { Spinner } from '../../../../../components/Spinner';

export const LoadingProfile = ({ tab }) => {
  return (
    <div className="loading__profile">
      <Spinner />
      <p>Actualizando Datos {tab}</p>
    </div>
  );
};
