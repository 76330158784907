import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { productsNames } from '../../../data/ui';

export const ModalProductHeader = () => {
  const {
    modals: { product_ },
    cart: { addToCart },
  } = useSelector((state) => state.ui);
  const { productsOf } = product_;
  const { name, image } = product_.product;

  const productName = (product) => {
    switch (product) {
      case productsNames.plans:
        return 'Plan';
      case productsNames.promotions:
        return 'Promoción';
      case productsNames.tabAllProducts:
        return 'Plan Personalizado';
      case productsNames.tabBreads:
        return 'Plan Personalizado';
      case productsNames.personalisedPlans:
        return 'Plan Personalizado';
      case productsNames.personalisedBread:
        return 'Pan';
      default:
        return 'Producto';
    }
  };

  const prod_name = productName(productsOf);

  return (
    <Fragment>
      <div className="modal__title__feaured text__center">
        {addToCart && (
          <h1 className="text__yellow">
            ¡Se ha agregado un nuevo <br /> producto al carrito!
          </h1>
        )}
        <h2 className={`${!addToCart ? 'text__yellow' : ''}`}>
          ¿Desea agregar una nota adicional?
        </h2>
      </div>
      <div className="modal__header__title">
        <div className="title">
          <span className="subtitle">{prod_name}:</span>
          <h1>{name}</h1>
        </div>
        {image !== '' && (
          <div className="image">
            <img src={image} alt={name} title={name} />
          </div>
        )}
      </div>
    </Fragment>
  );
};
