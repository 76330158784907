import { useDispatch } from 'react-redux';
import { selectedBreadProduct } from '../../redux/actions/e-commerce/personalisedBread';

import { validateValueEmpty } from '../../helpers/validations';
import { default_imgs } from '../../data/ui/default_images';
import { productsNames } from '../../data/ui';
import { selectedPersonalisedPlanProduct } from '../../redux/actions/e-commerce/personalisedPlan';

export const CardCustomizedPlan = ({
  id,
  nombre,
  descripcion = '',
  imagen = '',
  precio,
  typesProduct = '',
  selectedProductID = '',
}) => {
  const dispatch = useDispatch();
  const description = descripcion ?? '';

  const selectedProduct = () => {
    if (
      productsNames.fillers === typesProduct ||
      productsNames.breads === typesProduct
    ) {
      const product = {
        category: typesProduct,
        bread: {
          name: nombre,
          image: imagen ?? '',
          id: id,
        },
      };
      dispatch(selectedBreadProduct(product));
    } else if (productsNames.tabBreads === typesProduct) {
      const product = {
        productOf: typesProduct,
        product: {
          name: nombre,
          image: imagen ?? '',
          id: id,
        },
      };
      dispatch(selectedPersonalisedPlanProduct(product));
    }
  };
  return (
    <div className="card__dark">
      {(productsNames.fillers === typesProduct ||
        productsNames.breads === typesProduct) && (
        <div className="card__img">
          <img
            src={`${
              validateValueEmpty(imagen) ? default_imgs.product1 : imagen
            } `}
            alt={nombre}
            title={nombre}
          />
        </div>
      )}
      <div
        className={`card__body ${
          description?.length === 0 || description === ''
            ? 'card__body__title_2'
            : ''
        }`}
      >
        <h1 title={nombre}>{nombre}</h1>
        {(description?.length !== 0 || description !== '') && (
          <p className="paragraph font__klinicSlabmedium">
            {description !== ''
              ? description.length > 70
                ? description /* description.substr(0, 55) + '...' */
                : description
              : ''}
          </p>
        )}

        {productsNames.tabAllProducts || productsNames.tabBreads ? (
          ''
        ) : (
          <p className="price font__klinicSlabbold">S/. {precio}</p>
        )}
      </div>
      <div className="card__footer">
        <button
          className={`btn btn__outline__white hover__yellow ${
            selectedProductID === id ? 'selected__product' : ''
          }`}
          onClick={selectedProduct}
          disabled={selectedProductID === id}
        >
          {selectedProductID === id ? 'Seleccionado' : 'Seleccionar'}
        </button>
      </div>
    </div>
  );
};
