import { fetchWithToken } from '../../../helpers/api/fetch';
import { methods_api } from '../../../helpers/api/methods_api';
import { typesInformation } from '../../types/e-commerce';

export const updateInfoUser = (data) => {
  return async (dispatch) => {
    const { id } = JSON.parse(localStorage.getItem('auth'));
    try {
      const resp = await fetchWithToken(
        methods_api.updateInfoUser + `/${id}`,
        data,
        'PUT'
      );
      const body = await resp.json();
      if (body.status === 'success') {
        dispatch(getInformation());
        return { success: true };
      } else {
        if (body.errors.email?.length > 0) {
          return { success: false, msg: body.errors.email[0] };
        } else if (body.errors.last_name?.length > 0) {
          return { success: false, msg: body.errors.last_name[0] };
        } else if (body.errors?.cellphone?.length > 0) {
          return { success: false, msg: body.errors.cellphone[0] };
        } else if (body.errors?.document_number?.length > 0) {
          return { success: false, msg: body.errors.document_number[0] };
        }

        return { success: false, msg: 'Por favor vuelva a intentarlo' };
      }
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const updateInfoDelivery = (data) => {
  return async (dispatch) => {
    const { id } = JSON.parse(localStorage.getItem('auth'));
    try {
      const resp = await fetchWithToken(
        methods_api.updateInfoDelivery + `/${id}`,
        data,
        'PUT'
      );

      const body = await resp.json();

      if (body.status === 'success') {
        dispatch(getInformation());
        return { success: true };
      } else {
        if (body.errors?.cellphone.length > 0) {
          return { success: false, msg: body.errors.cellphone[0] };
        } else if (body.errors?.lote.length > 0) {
          return { success: false, msg: body.errors.lote[0] };
        } else if (body.errors?.address.length > 0) {
          return { success: false, msg: body.errors.address[0] };
        } else if (body.errors?.reference.length > 0) {
          return { success: false, msg: body.errors.reference[0] };
        } else if (body.errors?.district.length > 0) {
          return { success: false, msg: body.errors.district[0] };
        }
        return { success: false, msg: 'Por favor vuelva a intentarlo' };
      }
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Por favor vuelva a intentarlo' };
    }
  };
};

export const getInformation = (id_user) => {
  return async (dispatch) => {
    try {
      let id__user;
      if (id_user) {
        id__user = id_user;
      } else {
        const { id } = JSON.parse(localStorage.getItem('auth'));
        id__user = id;
      }

      const resp = await fetchWithToken(
        methods_api.getInfoUser + `/${id__user}`
      );
      const body = await resp.json();

      if (body.status === 'success') {
        const data = {
          user: { ...body.usuario },
          delivery: { ...body.delivery },
        };
        dispatch(setInformation(data));
      } else {
        localStorage.setItem('auth', JSON.stringify({ logged: false }));
      }
    } catch (error) {
      console.error(error);
      localStorage.setItem('auth', JSON.stringify({ logged: false }));
    }
  };
};

export const setInformation = (user) => ({
  type: typesInformation.information,
  payload: user,
});
