export const TitleCustomizedPlan = () => {
  return (
    <div className="row text__center">
      <div className="col-lg-6 mx__auto px__0">
        <div className="title__wt__icons">
          <img
            src="../../images/decorations/icons/fork.svg"
            className="icon"
            alt="Fork"
          />
          <h1 className="title">Arma tu pan</h1>
          <img
            src="../../images/decorations/icons/spoon.svg"
            className="icon"
            alt="Spoon"
          />
        </div>
        <p className="font__klinicSlabbold paragraph mt__3 description">
          Elige uno de nuestros panes Don Santi y añade tu relleno favorito
        </p>
      </div>
    </div>
  );
};
