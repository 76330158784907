import { Link } from 'react-router-dom';
import { WrapperForm } from '../../wrappers/WrapperForm';
import { NavbarMain } from '../../components/NavbarMain';

export const PageMessageEmailSend = () => {
  return (
    <>
      <NavbarMain />
      <WrapperForm
        image="change_pass"
        title="Llegó el momento de revisar tus correos recibidos"
      >
        <p className="paragraph font__klinicSlabbold my__3">
          Revisa el correo que te hemos enviado para cambiar tu contraseña.
          <br />
          Este correo es válido por 15min.
          <br />
          Si no encuentra el correo en su bandeja de entrada, por favor revise
          su spam.
        </p>

        <div className="my__3 text__center">
          <Link to="/inicio-sesion">
            <button className="btn btn__outline__white">Iniciar Sesión</button>
          </Link>
        </div>
        <div className="mt__3">
          <p className="paragraph font__klinicSlabbold text__center">
            ¿Aún no recibiste nuestro correo? Revisa tu carpeta de Spam o
            <Link to="/olvide-contrasena" className="line__line__yellow">
              <span className="paragraph font__klinicSlabbold">
                intenta con otro correo electrónico
              </span>
            </Link>
          </p>
        </div>
      </WrapperForm>
    </>
  );
};
