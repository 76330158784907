import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WrapperPage } from '../../../wrappers/WrapperPage';
import { PersonalData } from './sections/PersonalData';
import { DeliveryData } from './sections/DeliveryData';
import { PaymentMethod } from './sections/PaymentMethod';
import { Success } from './sections/Success';
import { fetchWithToken } from '../../../helpers/api/fetch';

export const PageBuyDetails = () => {
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');
  const [numPayAnswer, setNumPayAnswer] = useState(0);
  const { products_total } = useSelector((state) => state.sale);
  const { goBack } = useHistory();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const t = url.search.slice(3, url.search.length);
  useEffect(() => {
    if (t) {
      setStep(3);
      setToken(t);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (!t) {
        window.location.reload();
      }
    };
  }, []);

  const payAnswer = async (transactionToken, purchaseNumber) => {
    /*     const resp = await fetchWithToken(
      'payAnswer',
      {
        transactionToken,
        purchaseNumber,
      },
      'POST'
    );
    const response = await resp.json();
    console.log(response);
    if (response.status == 'success') {
      // setStep(3);
    } */
  };

  useEffect(() => {
    const purchaseNumber = sessionStorage.getItem('order-purchasenumber');
    if (token && purchaseNumber && numPayAnswer === 0) {
      setNumPayAnswer(numPayAnswer + 1);
      payAnswer(token, purchaseNumber);
    }
  }, [token]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step === 0) {
      goBack();
      return;
    }
    setStep(step - 1);
  };
  const selectStep = [
    <PersonalData step={step} prevStep={prevStep} nextStep={nextStep} />,
    <DeliveryData step={step} prevStep={prevStep} nextStep={nextStep} />,
    <PaymentMethod prevStep={prevStep} nextStep={nextStep} />,
    <Success token={token} nextStep={setStep} />,
  ];

  if (products_total === 0 && step === 0) {
    return <Redirect to="/inicio" />;
  }
  return (
    <WrapperPage>
      <section className="container container__steps_buy_products">
        <div className="row">
          <div className="col-lg-11 mx__auto">
            <div className="steps_buy_products">
              <button className={'activated'}>1. Datos personales</button>
              <button className={step > 0 ? 'activated' : ''}>
                2. Delivery
              </button>
              <button className={step > 1 ? 'activated' : ''}>
                3. Método de pago
              </button>
            </div>
            {selectStep[step]}
          </div>
        </div>
      </section>
    </WrapperPage>
  );
};
