import { Link } from 'react-router-dom';
import { social_media } from '../../../data/ui';

export const Banner = () => {
  return (
    <section className="container-fluid banner__main">
      <div className="banner__main__container">
        <div className="banner__networks">
          {social_media.whatsApp !== '' && (
            <a href={social_media.whatsApp} rel="noreferrer" target="_blank">
              <i className="fab fa-whatsapp"></i>
            </a>
          )}
          {social_media.instagram !== '' && (
            <a href={social_media.instagram} rel="noreferrer" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          )}
          {social_media.twitter !== '' && (
            <a href={social_media.twitter} rel="noreferrer" target="_blank">
              <i className="fab fa-twitter"></i>
            </a>
          )}
          {social_media.facebook !== '' && (
            <a href={social_media.facebook} rel="noreferrer" target="_blank">
              <i className="fab fa-facebook-square"></i>
            </a>
          )}
          {social_media.tiktok !== '' && (
            <a href={social_media.tiktok} rel="noreferrer" target="_blank">
              <i className="fab fa-tiktok"></i>
            </a>
          )}
        </div>
        <div className="banner__content">
          <div className="logo__don__santi">
            <img
              src="../../images/logos/Logo_Don-Santi2.png"
              alt="Logo Don Santi"
              title="Logo Don Santi"
            />
          </div>
          <p>
            Te brindamos un viaje emocional a través de deliciosa comida
            hispano-peruana que hará flipar tu cerebro, derretir tu paladar y
            acelerar tus latidos.
          </p>
          {/* <Link to="planes">
            <button className="btn btn__primary">Explorar planes</button>
          </Link> */}
        </div>
      </div>
    </section>
  );
};
