import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearTabAllProducts,
  clearTabAllProductsID,
  clearTabBreadProduct,
  clearTabBreadProductID,
  setTabAllProductsDays,
  setTabBreadProductDays,
} from '../redux/actions/e-commerce/personalisedPlan';
import { clearChosenPlan } from '../redux/actions/e-commerce/plans';
import { updateNote } from '../redux/actions/e-commerce/sale';
import { closedModalProduct } from '../redux/actions/ui';
import { clearChosenPromotion } from '../redux/actions/e-commerce/promotions';
import { clearPersonalisedBread } from '../redux/actions/e-commerce/personalisedBread';

import { useGetLocation } from '../hooks/useGetLocation';
import { customStyles } from './customStyles';

import { ModalProductHeader } from '../components/modals/Product/ModalProductHeader';
import { ModalProductList } from '../components/modals/Product/ModalProductList';
import { ModalProductQuestion } from '../components/modals/Product/ModalProductQuestion';
import { ModalProductActions } from '../components/modals/Product/ModalProductActions';
import { NoteAdded } from '../components/modals/Product/NoteAdded';
import { NoteEmpty } from '../components/modals/Product/NoteEmpty';
import { NoteField } from '../components/modals/Product/NoteField';
import { productsNames } from '../data/ui';

export const ModalProduct = () => {
  const {
    modals: { product_ },
  } = useSelector((state) => state.ui);
  const { id } = product_.product;
  const { productsOf } = product_;
  const [noteWasAdded, setNoteWasAdded] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const note = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getLocationPathname } = useGetLocation();
  const page_cart = 'carrito';
  const page = getLocationPathname();

  const closeModal = () => {
    if (productsOf === productsNames.plans) {
      dispatch(clearChosenPlan());
    } else if (productsOf === productsNames.promotions) {
      dispatch(clearChosenPromotion());
    } else if (productsOf === 'personalised_bread') {
      dispatch(clearPersonalisedBread());
    } else if (productsOf === productsNames.tabBreads) {
      dispatch(clearTabBreadProduct());
      dispatch(clearTabBreadProductID());
      dispatch(setTabBreadProductDays([]));
    } else if (productsOf === productsNames.tabAllProducts) {
      dispatch(clearTabAllProducts());
      dispatch(clearTabAllProductsID());
      dispatch(setTabAllProductsDays([]));
    }

    dispatch(closedModalProduct());
    if (
      (productsOf === productsNames.plans ||
        productsOf === productsNames.promotions) &&
      page !== page_cart
    ) {
      history.goBack();
    }
  };
  const addNote = () => {
    if (note.current.value === '') {
      setShowMessage(true);
      return;
    }
    if (showMessage) setShowMessage(false);

    dispatch(updateNote({ id, note: note.current.value, productsOf }));
    setNoteWasAdded(true);

    setTimeout(() => {
      let productsOf_ = '';
      if (
        (productsOf === productsNames.plans ||
          productsOf === productsNames.promotions) &&
        page !== page_cart
      ) {
        history.goBack();
      } else if (
        productsOf === productsNames.tabAllProducts ||
        productsOf === productsNames.tabBreads
      ) {
        productsOf_ = productsOf;
      }

      dispatch(closedModalProduct(productsOf_));

      if (productsOf === productsNames.plans) {
        dispatch(clearChosenPlan());
      } else if (productsOf === productsNames.promotions) {
        dispatch(clearChosenPromotion());
      } else if (productsOf === 'personalised_bread') {
        dispatch(clearPersonalisedBread());
      } else if (productsOf === productsNames.tabBreads) {
        dispatch(clearTabBreadProduct());
        dispatch(clearTabBreadProductID());
        dispatch(setTabBreadProductDays([]));
      } else if (productsOf === productsNames.tabAllProducts) {
        dispatch(clearTabAllProducts());
        dispatch(clearTabAllProductsID());
        dispatch(setTabAllProductsDays([]));
      }

      setNoteWasAdded(false);
    }, 1400);
  };
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={product_.isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 450,
        },
      }}
    >
      <div className="mx__auto modal__body modal__product">
        <div className={`modal__content ${noteWasAdded ? 'success' : ''}`}>
          {noteWasAdded ? (
            <NoteAdded />
          ) : (
            <>
              <ModalProductHeader />
              <ModalProductList />
              <div className="divider"></div>
              <div className="modal__content">
                <ModalProductQuestion />
                <NoteField ref_note={note} />
                {showMessage && <NoteEmpty />}

                <ModalProductActions
                  closeModal={closeModal}
                  addNote={addNote}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
