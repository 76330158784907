import React, { useState } from 'react';
import { social_media } from '../../data/ui';

export const WhatsApp = () => {
  const [showMessage, setShowMessage] = useState(true);
  return (
    <div className="icon__whatsApp">
      <div className={`message ${showMessage ? '' : 'did-fade-in'}`}>
        <i className="fas fa-times" onClick={() => setShowMessage(false)}></i>
        <p>¿Tienes otros requerimientos para tu plan? </p>
        <p>
          <b>¡Escríbenos!</b>
        </p>
      </div>
      <a href={social_media.whatsApp_plan} rel="noreferrer" target="_blank">
        <img
          src="../../images/decorations/icons/whatsApp.svg"
          alt="Icono de WhatsApp"
          title="Icono de WhatsApp"
        />
      </a>
    </div>
  );
};
