import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from '../redux/actions/auth';

import { validateEmail } from '../helpers/validations';
import { useTooglePass } from '../hooks/useTooglePass';

import { MessageError } from '../components/messages';
import { Spinner } from '../components';
import { fieldErrorMessages } from '../data/ui';

const initialState = {
  email: '',
  password: '',
};
export const FormLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [messageError, setMessageError] = useState('');
  const { loading } = useSelector((state) => state.ui);
  const [payload, setPayload] = useState(initialState);
  const { togglePass, showPass, inputPassword } = useTooglePass();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageError('');
    if (!validateEmail(payload.email)) {
      setMessageError(fieldErrorMessages.invalidEmail);
      return;
    }

    const resp = await dispatch(startLogin(payload));
    const loggedPage = sessionStorage.getItem('page-logged') ?? 'profile';
    if (resp.success) {
      setPayload(initialState);
      if (loggedPage === 'cart') {
        history.push('/carrito');
      } else {
        history.push('/inicio');
      }
    } else {
      setMessageError(resp.msg);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form__control">
        <label htmlFor="email">Correo electrónico</label>
        <input
          type="email"
          placeholder="Ingrese su correo"
          required
          value={payload.email}
          onChange={({ target }) =>
            setPayload((prev) => {
              return { ...prev, email: target.value };
            })
          }
        />
      </div>
      <div className="form__control">
        <label htmlFor="pass">Contraseña</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Ingrese su contraseña"
            ref={inputPassword}
            value={payload.password}
            onChange={({ target }) =>
              setPayload((prev) => {
                return { ...prev, password: target.value };
              })
            }
            required
          />
          {!showPass ? (
            <i className="fas fa-eye-slash" onClick={togglePass}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass}></i>
          )}
        </div>
      </div>

      {messageError !== '' && <MessageError message={messageError} />}
      {loading.login && <Spinner />}

      <div className="text__center mt__3">
        <button className="btn btn__outline__white">Iniciar sesión</button>
        <div className="mt__2">
          <Link to="/registro" className="link font__klinicSlabbold">
            ¿Nuevo(a) por aquí? Regístrate
          </Link>
        </div>
        <div className="mt__2">
          <Link to="/olvide-contrasena" className="link font__klinicSlabbold">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </div>
    </form>
  );
};
