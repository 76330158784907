import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const WrapperForm = ({ children, image, title }) => {
  useEffect(() => {
    window.scrollTo(0, 70);
  }, []);

  return (
    <div className="container-fluid form__container">
      <div className="container">
        <div className="row">
          <div className="col-lg-11 mx__auto">
            <div className="row">
              <div className="col-lg-6 px__0 d__lg__none">
                <div className="form__image">
                  <img
                    src={`../images/sections/forms/${image}.png`}
                    alt="Bread"
                  />
                </div>
              </div>
              <div className="col-lg-6 px__0">
                <div className="form__content">
                  <div className="form__body">
                    <h1 className="font__escafinaHigh title__lg">{title}</h1>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WrapperForm.protoType = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
