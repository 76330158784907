import { Children } from 'react';
import { CardCategoryProduct } from '../../../components/cards/CardCategoryProduct';

export const Products = ({
  isCategories = false,
  isProducts = false,
  list = [],
  title = '',
  selected,
  id,
  isPlan = false,
  title_single = '',
  pronoun = '',
}) => {
  const category = title.charAt(0).toUpperCase() + title.slice(1);
  const category_single =
    title_single.charAt(0).toUpperCase() + title_single.slice(1);
  return (
    <section className="container-fluid">
      <div className="row">
        <div className="container">
          <div className="col-lg-10 mx__auto pb__xl__0">
            <h3 className="subtitle__products">
              <div className="dot__custom"></div>
              Seleccione {pronoun !== '' ? pronoun + ' ' : 'uno de nuestros '}
              <span className="text__yellow">{category}</span>
            </h3>
          </div>
          <div className="col-lg-10 mx__auto">
            <div className="grid__category__product">
              {Children.toArray(
                list.map((item) => (
                  <CardCategoryProduct
                    {...item}
                    selected={selected}
                    isCategories={isCategories}
                    isProducts={isProducts}
                    title_category={category}
                    id={id}
                    isPlan={isPlan}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
