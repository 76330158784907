import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../images/logos/logo.png';
export const NavbarMain = () => {
  const navRef = useRef(null);
  const history = useHistory();
  const { loggedIn } = useSelector((state) => state.auth);
  const {
    cart: { addToCart },
  } = useSelector((state) => state.ui);
  const [openMenu, setOpenMenu] = useState(false);
  const { products_total } = useSelector((state) => state.sale);
  const [currentSection, setCurrentSection] = useState('section__main');

  const handleMenu = () => {
    const isOpen = navRef.current.classList.contains('show__items');
    if (isOpen) {
      navRef.current.classList.remove('show__items');
      setOpenMenu(false);
    } else {
      navRef.current.classList.add('show__items');
      setOpenMenu(true);
    }
  };

  const changePass = (page) => {
    history.push(`/${page}`);
  };

  const scrollCCurrentSection = (id) => {
    const container = document.querySelector(`#${id}`);
    container?.scrollIntoView({ behavior: 'smooth' });
    setCurrentSection(id);
  };
  useEffect(() => {
    const hasPromotions = history.location.hash === '#promociones';
    if (hasPromotions) scrollCCurrentSection('section__promotions');
  }, [currentSection]);

  const scroll = (id) => {
    if (history.location.pathname === '/inicio') {
      scrollCCurrentSection(id);
    } else {
      if (id === 'section__main') history.push(`/inicio`);
      else history.push(`/inicio/#promociones`);
      setTimeout(() => {
        scrollCCurrentSection(id);
      }, 800);
    }
  };

  const ItemsNavbar = ({ mobile = false }) => {
    const itemActivated = history.location.pathname.replace('/', '');

    return (
      <ul>
        <li
          className={`item-section ${mobile ? '' : 'd__md__none'} ${
            currentSection === 'section__main' && itemActivated === 'inicio'
              ? 'activated'
              : ''
          }`}
          onClick={() => {
            scroll('section__main');
          }}
        >
          <p>SOBRE NOSOTROS </p>
        </li>
        <li
          className={`item-section ${mobile ? '' : 'd__md__none'} ${
            (currentSection === 'section__promotions' &&
              itemActivated === 'inicio') ||
            itemActivated === 'inicio/'
              ? 'activated'
              : ''
          }`}
          onClick={() => {
            scroll('section__promotions');
          }}
        >
          <p>PROMOCIONES </p>
        </li>
        {!mobile && (
          <li className={`${openMenu ? 'd__md__none' : ''}`}>
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Logo de Don Santi" />
              </Link>
            </div>
          </li>
        )}
        <li
          className={`${mobile ? '' : 'd__md__none'} ${
            itemActivated === 'carta' ? 'activated' : ''
          }`}
        >
          <Link
            className="item__navbar"
            to="carta"
            onClick={() => changePass('carta')}
          >
            <p>CARTA</p>
          </Link>
        </li>
        <li
          className={`${mobile ? '' : 'd__md__none'} ${
            itemActivated === 'nuestra-historia' ? 'activated' : ''
          }`}
        >
          <Link
            className="item__navbar"
            to="nuestra-historia"
            onClick={() => changePass('nuestra-historia')}
          >
            <p>NUESTRA HISTORIA</p>
          </Link>
        </li>
      </ul>
    );
  };
  return (
    <div className="container-fluid container__navbar">
      <div className="row">
        <div className="col-md-12">
          <div className="navbar__don__santi">
            {loggedIn ? (
              <div className="item__login">
                <Link
                  to="/usuario/mi-perfil"
                  className="d-flex align-items-center gap-2"
                >
                  <img
                    src="../../images/decorations/icons/smile.svg"
                    alt="Smile Face"
                    className="icon__smile"
                  />
                  <p className="d__md__none text__yellow">Mi Perfil</p>
                </Link>
              </div>
            ) : (
              <div className="item__login">
                <Link
                  to="/inicio-sesion"
                  onClick={() => changePass('inicio-sesion')}
                  className="d-flex align-items-center gap-2"
                >
                  <i className="fas fa-user-circle text__yellow"></i>
                  <p className="d__md__none text__yellow">Iniciar Sesión</p>
                </Link>
              </div>
            )}

            <nav>
              <ItemsNavbar />
            </nav>
            <div className={`icon__cart  ${addToCart ? 'fill__cart' : ''}`}>
              <Link to="carrito" onClick={() => changePass('carrito')}>
                <i
                  className={`fas fa-shopping-cart ${
                    products_total > 0 ? 'text__yellow' : ''
                  }`}
                ></i>
                {/* fill-cart */}
                <p className={`${products_total > 0 ? 'text__yellow' : ''}`}>
                  {products_total}
                </p>
              </Link>
            </div>

            <button className="icon__burger d__xlg__none">
              {openMenu ? (
                <i className="fas fa-times" onClick={handleMenu}></i>
              ) : (
                <svg
                  width="31"
                  height="24"
                  viewBox="0 0 31 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleMenu}
                >
                  <path
                    d="M17 22H28.6667M2 2H28.6667H2ZM2 12H28.6667H2Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>

            <div className="items__mobile d__xlg__none" ref={navRef}>
              <ItemsNavbar mobile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
