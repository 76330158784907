import { v4 as uuidv4 } from 'uuid';
import {
  addToCart,
  errorMessageSelectedProducts,
  openModalProduct,
} from '../ui';
import {
  addPersonalisedBreadToShoppingCart,
  calculateProductsTotal,
  updateSubTotal,
} from './sale';
import { typesPersonalisedBread } from '../../types/e-commerce/personalisedBread';

const types__categories = {
  fillers: 'fillers',
  breads: 'breads',
};
export const selectedBreadProduct = ({ category, bread }) => {
  return (dispatch) => {
    if (category === types__categories.fillers) {
      dispatch(setTypeFillerBreads(bread));
    } else if (category === types__categories.breads) {
      dispatch(setTypeBreadBreads(bread));
    }
  };
};

const setTypeFillerBreads = (bread) => ({
  type: typesPersonalisedBread.addTypeFillerBreads,
  payload: bread,
});

const setTypeBreadBreads = (bread) => ({
  type: typesPersonalisedBread.addTypeBreadBreads,
  payload: bread,
});

export const validatePersonalisedBread = () => {
  return (dispatch, getState) => {
    const { personalisedBread } = getState().products;

    let isShowError = false;
    let errorMessage = 'Seleccione un tipo de ';

    const {
      errorMessages: { infoSelectedProducts },
    } = getState().ui;

    if (personalisedBread.pan_producto_id === '') {
      const typeBread = 'pan';
      isShowError = true;
      errorMessage += typeBread;
    } else if (personalisedBread.relleno_producto_id === '') {
      const typeBread = 'relleno';
      isShowError = true;
      errorMessage += typeBread;
    }
    if (isShowError) {
      dispatch(
        errorMessageSelectedProducts({
          show: true,
          message: errorMessage,
        })
      );
      return;
    }

    if (infoSelectedProducts.show) {
      dispatch(
        errorMessageSelectedProducts({
          show: false,
          message: '',
        })
      );
    }

    dispatch(formattingPersonalisedBread());
  };
};

const formattingPersonalisedBread = () => {
  return (dispatch, getState) => {
    const { arma_tu_pan } = getState().sale;
    const { personalisedBread } = getState().products;
    const { pan_name, relleno_name, image, ...product } = personalisedBread;
    const personalisedBread_ = {
      id: uuidv4(),
      nombre: `Pan personalizado #${arma_tu_pan.length + 1}`,
      imagen: '',
      preferencia: '',
      cantidad: 1,
      ...product,
      precio: '15.00',
      productos: [{ product_name: pan_name }, { product_name: relleno_name }],
      subtotal_: 0,
    };

    dispatch(addToCart(true));
    dispatch(addPersonalisedBreadToShoppingCart(personalisedBread_));
    dispatch(updateSubTotal(personalisedBread_.precio));
    dispatch(calculateProductsTotal());

    const product_ = {
      id: personalisedBread_.id,
      name: personalisedBread_.nombre,
      list: personalisedBread_.productos,
      image: personalisedBread_.imagen,
      note: '',
    };
    dispatch(
      openModalProduct({
        product: product_,
        productsOf: 'personalised_bread',
      })
    );

    setTimeout(() => {
      dispatch(addToCart(false));
    }, 3800);
  };
};

export const clearPersonalisedBread = () => ({
  type: typesPersonalisedBread.clearPersonalisedBread,
});
