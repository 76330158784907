export const Tabs = ({
  tab1Activated,
  action1Activated,
  tab2Activated,
  action2Activated,
}) => {
  return (
    <div className="tabs my__2">
      <div
        className={`tab ${tab1Activated ? 'tab__activated' : ''}`}
        onClick={action1Activated}
      >
        <p>Mis datos personales</p>
      </div>
      <div
        className={`tab ${tab2Activated ? 'tab__activated' : ''}`}
        onClick={action2Activated}
      >
        <p>Datos de delivery</p>
      </div>
    </div>
  );
};
