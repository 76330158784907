import { Fragment, useRef, useState, useLayoutEffect, Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loggedIn } from '../../../../../redux/actions/auth';
import { updateInfoDelivery } from '../../../../../redux/actions/e-commerce';
import { editingDeliveryInformation } from '../../../../../redux/actions/ui';

import {
  validateNumber,
  validateValueEmpty,
} from '../../../../../helpers/validations';

import {
  MessageError,
  MessageSuccess,
  MessageWarning,
} from '../../../../../components/messages';
import { fieldErrorMessages } from '../../../../../data/ui';

import { LoadingProfile } from './LoadingProfile';

export const EditDeliveryInformation = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const inputEmpty = '';
  const { delivery } = useSelector((state) => state.information);
  const { editing } = useSelector((state) => state.ui);
  const { districts } = useSelector((state) => state.ui);
  const district = delivery?.district_id ?? '0';
  const [districtID, setDistrictID] = useState(district);
  const ref__celphone = useRef('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const canWrite = editing.delivery;

  useLayoutEffect(() => {
    setPayload(delivery);
    if (district === '') {
      setDistrictID('0');
      return;
    }
    setDistrictID(parseInt(district));
  }, [delivery, district]);

  const logout = () => {
    sessionStorage.clear();
    dispatch(loggedIn(false));
    history.push('/inicio');
  };
  const updateInfo = async () => {
    setErrorMessage('');
    if (canWrite) {
      setLoading(true);

      const cellphone = ref__celphone.current.value;

      if (
        (cellphone.length < 1 ||
          !validateNumber(cellphone) ||
          Math.sign(cellphone) !== 1) &&
        cellphone !== ''
      ) {
        editingMessages(fieldErrorMessages.invalidCel);
      } else if (Math.sign(payload.lote) !== 1 && payload.lote !== '') {
        editingMessages(fieldErrorMessages.invalidNroLte);
      } else if (cellphone.length < 9 && cellphone !== '') {
        editingMessages(fieldErrorMessages.invalidCelNumbers);
      } else {
        const { status, district_name, district_id, district_price, ...data } =
          payload;

        const delivery = {
          ...data,
          district: districtID ?? 0,
        };

        if (delivery?.cellphone?.length === 0) {
          delete delivery.cellphone;
        }

        if (
          delivery.district === 0 ||
          !delivery.district ||
          delivery.district === '0'
        )
          delete delivery.district;
        const res = await dispatch(updateInfoDelivery(delivery));

        if (res.success) {
          dispatch(editingDeliveryInformation(false));
          setSuccessMessage(
            'Los datos personales de han actualizado correctamente 😁'
          );
          setTimeout(() => {
            window.scrollTo(0, 240);
            setSuccessMessage('');
          }, 1500);
        }

        if (!res.success) editingMessages(res.msg);
        window.scrollTo(0, 280);
        setLoading(false);
      }
    } else {
      dispatch(editingDeliveryInformation(true));
    }
  };

  const editingMessages = (message) => {
    dispatch(editingDeliveryInformation(true));
    setErrorMessage(message);
    setLoading(false);
  };

  const handlerDistrict = ({ target }) => {
    const num_district = parseInt(target.options[target.selectedIndex].value);
    setDistrictID(num_district);
  };

  const cancelUpdate = () => {
    window.location.reload();
  };

  return (
    <div className="tab__content">
      {!loading ? (
        <Fragment>
          <div className="info__user">
            <p>Otro teléfono:</p>
            <input
              name="user_tel"
              type="tel"
              value={`${
                validateValueEmpty(payload?.cellphone)
                  ? inputEmpty
                  : payload?.cellphone
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, cellphone: target.value };
                })
              }
              ref={ref__celphone}
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Distrito:</p>

            <select
              onChange={handlerDistrict}
              value={districtID}
              className={`${canWrite ? '' : 'text'}`}
              disabled={!canWrite}
            >
              <option value="0" disabled>
                Seleccione un distrito
              </option>
              {Children.toArray(
                districts.map(({ id, name, price }) => (
                  <option data-price={price} value={id}>
                    {name}
                  </option>
                ))
              )}
            </select>
          </div>
          <div className="info__user">
            <p>Nro / Lte:</p>
            <input
              name="user_nro__lte"
              type="tel"
              value={`${
                validateValueEmpty(payload?.lote) ? inputEmpty : payload?.lote
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, lote: target.value };
                })
              }
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Dirección:</p>
            <textarea
              name="user_address"
              cols="30"
              rows="1"
              value={`${
                validateValueEmpty(payload?.address)
                  ? inputEmpty
                  : payload?.address
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, address: target.value };
                })
              }
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>
          <div className="info__user">
            <p>Referencia:</p>
            <textarea
              name="user_ref"
              cols="30"
              rows="2"
              value={`${
                validateValueEmpty(payload?.reference)
                  ? inputEmpty
                  : payload?.reference
              }`}
              onChange={({ target }) =>
                setPayload((prev) => {
                  return { ...prev, reference: target.value };
                })
              }
              className={`${canWrite ? '' : 'text'}`}
              readOnly={!canWrite}
            />
          </div>

          {errorMessage !== '' && (
            <MessageError bg__dark message={errorMessage} my__2={false} />
          )}
          {successMessage !== '' && <MessageSuccess />}

          {editing.delivery && (
            <MessageWarning message="Recuerda guardar tus cambios antes de salir" />
          )}
          <div className="actions">
            <button
              className="btn btn__white"
              type="button"
              onClick={updateInfo}
            >
              {editing.delivery ? 'Guardar' : 'Editar'}
            </button>

            {!editing.delivery ? (
              <button
                className="btn btn__outline__white"
                type="button"
                onClick={logout}
              >
                Cerrar Sesión
              </button>
            ) : (
              <button
                className="btn btn__outline__white"
                type="button"
                onClick={cancelUpdate}
              >
                Cancelar
              </button>
            )}
          </div>
        </Fragment>
      ) : (
        <LoadingProfile tab="de Delivery" />
      )}
    </div>
  );
};
