import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default_imgs, productsNames } from '../../../../../data/ui';
import { shoppingCartProducts } from '../../../../../redux/actions/e-commerce/sale';

export const ProductList = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const { subtotal, total } = useSelector((state) => state.sale);
  const {
    delivery: { district_price },
  } = useSelector((state) => state.information);

  useEffect(() => {
    const products = dispatch(shoppingCartProducts());
    setProducts(products);
  }, [dispatch]);

  return (
    <div className="card__content__products">
      <div className="list__products">
        {Children.toArray(
          products.map(
            ({
              nombre,
              precio,
              productOf,
              cantidad = '',
              meses = '',
              imagen,
            }) => (
              <div className="grid__product">
                <div className="product__image">
                  <img
                    src={imagen === '' ? default_imgs.product2 : imagen}
                    alt={nombre}
                    title={nombre}
                  />
                </div>
                <div className="product__info card__product__info">
                  <p>{nombre}</p>

                  <p>
                    {productOf !== productsNames.plans &&
                    productOf !== productsNames.personalisedPlans
                      ? 'Cant.'
                      : 'Meses'}
                    &nbsp;
                    {productOf !== productsNames.plans &&
                    productOf !== productsNames.personalisedPlans
                      ? cantidad
                      : meses}
                  </p>
                </div>
                <div className="product__price card__product__info">
                  <p className="text__yellow">S/ {precio}</p>
                </div>
              </div>
            )
          )
        )}
      </div>

      <div className="divider my__3"></div>

      <div className="content__prices">
        <div className="flex">
          <p>Subtotal</p>
          <p>S/ {subtotal.toFixed(2)}</p>
        </div>
        <div className="flex">
          <p>Delivery</p>
          <p>S/ {district_price}</p>
        </div>
        <div className="divider my__2"></div>
        <div className="flex text__yellow">
          <p>Total</p>
          <p>S/ {total.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};
