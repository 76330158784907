import { SkeletonElement } from '../parts/SkeletonElement';
import { WrapperSkeleton } from '../parts/WrapperSkeleton';

export const SkeletonCardCustomisable = () => {
  return (
    <WrapperSkeleton>
      <div>
        <SkeletonElement type="image" />
        <div className="skeleton__body">
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
        <div className="skeleton__footer">
          <SkeletonElement type="button" />
        </div>
      </div>
    </WrapperSkeleton>
  );
};
