import { Children, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartProductsHeader } from '../ProductsParts/CartProductsHeader';
import { CartProduct } from '../ProductsParts/CartProduct';
import { productsNames } from '../../../data/ui';
import { Fragment } from 'react';

export const CartPromotions = ({ divider }) => {
  const { promociones } = useSelector((state) => state.sale);
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const arr_promotions = promociones.map((promocion) => {
      const { promotion_id, ...promotion } = promocion;
      return {
        ...promotion,
        productsOf: productsNames.promotions,
      };
    });
    setPromotions(arr_promotions);
  }, [promociones]);

  return (
    <Fragment>
      {divider && <div className="divider my__2"></div>}
      <div className="info__products">
        <CartProductsHeader productsOf={productsNames.promotions} />

        <div className="list__grid__card__products">
          {promotions.length > 0 &&
            Children.toArray(
              promotions.map((product) => <CartProduct {...product} />)
            )}
        </div>
      </div>
    </Fragment>
  );
};
