export const Tabs = ({
  tab1Activated,
  action1Activated,
  tab2Activated,
  action2Activated,
}) => {
  return (
    <div className="row my__3">
      <div className="col-lg-10 mx__auto">
        <div className="tabs">
          <div
            className={`tab ${tab1Activated ? 'tab__activated' : ''}`}
            onClick={action1Activated}
          >
            <p>Todos los productos</p>
          </div>
          <div
            className={`tab ${tab2Activated ? 'tab__activated' : ''}`}
            onClick={action2Activated}
          >
            <p>Solo panes Don Santi</p>
          </div>
        </div>
      </div>
    </div>
  );
};
