import { typesPromotions } from '../../types/e-commerce/promotions';

const initialState = {
  featured: {
    list: [],
    loading: true,
  },
  pagination: {
    list: [],
    loading: true,
    pageActivated: 1,
    num_pages: 1,
  },
  chosen_promotion: {
    categories: [],
    products: [],
    promotion_id: 0,
    promotion_products: [],
    price: '0',
  },
};

export const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesPromotions.featuredPromotions:
      return {
        ...state,
        featured: {
          ...action.payload,
        },
      };
    case typesPromotions.paginationPromotions:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case typesPromotions.num_pages:
      return {
        ...state,
        pagination: { ...state.pagination, num_pages: action.payload },
      };
    case typesPromotions.chosenPromotion:
      return {
        ...state,
        chosen_promotion: {
          ...initialState.chosen_promotion,
          ...action.payload,
        },
      };
    case typesPromotions.addProductChosenPromotion:
      return {
        ...state,
        chosen_promotion: {
          ...state.chosen_promotion,
          promotion_products: action.payload,
        },
      };
    case typesPromotions.addProductCategoryChosenPromotion:
      return {
        ...state,
        chosen_promotion: {
          ...state.chosen_promotion,
          categories: action.payload,
        },
      };
    case typesPromotions.addProductGroupChosenPromotion:
      return {
        ...state,
        chosen_promotion: {
          ...state.chosen_promotion,
          products: action.payload,
        },
      };
    default:
      return state;
  }
};
