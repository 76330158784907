import { fetchNotToken } from '../../helpers/api/fetch';
import { methods_api } from '../../helpers/api/methods_api';
import { typesUi } from '../types';

/* LIST DISTRICTS */
export const getDistricts = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchNotToken(methods_api.getDistricts);
      const body = await resp.json();

      if (body.length > 0) dispatch(setDistricts(body));
    } catch (error) {
      console.error(error);
    }
  };
};

const setDistricts = (districts) => ({
  type: typesUi.districts,
  payload: districts,
});

/* LIST DOCUMENT TYPES  */
export const getDocumentTypes = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchNotToken(methods_api.getDocumentTypes);
      const body = await resp.json();
      if (body.length > 0) dispatch(setDocumentTypes(body));
    } catch (error) {
      console.error(error);
    }
  };
};

const setDocumentTypes = (documentsTypes) => ({
  type: typesUi.documentTypes,
  payload: documentsTypes,
});

/* MODAL PRODUCT IN CART */
export const toggleModalProductInCart = (isOpen) => ({
  type: typesUi.toggleModalProductInCart,
  payload: isOpen,
});

/*  MODAL DAYS  */
export const toggleModalDays = (isOpen) => ({
  type: typesUi.toggleModalDays,
  payload: isOpen,
});

export const addScreenModalDays = (productsOf) => ({
  type: typesUi.addScreenModalDays,
  payload: productsOf,
});

export const addDaysModalDays = (days = []) => ({
  type: typesUi.addDaysModalDays,
  payload: days,
});

export const showSelectDays = (isShow) => ({
  type: typesUi.showSelectDays,
  payload: isShow,
});
/* -------------- */

/* MODAL PRODUCT  */
export const openModalProduct = (product) => ({
  type: typesUi.openModalProduct,
  payload: product,
});

export const closedModalProduct = (productsOf) => ({
  type: typesUi.closedModalProduct,
  payload: productsOf,
});
/*  ------------- */

export const toggleModalTerms = (isOpen) => ({
  type: typesUi.toggleModalTermsConditions,
  payload: isOpen,
});

export const toggleModalTermsNPolicity = (isOpen, modalName) => ({
  type: typesUi.toggleModalTermsNPolicity,
  payload: { isOpen, modalName },
});
export const toggleModalMakePurchase = (isOpen) => ({
  type: typesUi.toggleModalMakePurchase,
  payload: isOpen,
});

export const toggleModalEmailError = (isOpen, email) => ({
  type: typesUi.toggleModalEmailError,
  payload: { isOpen, email },
});

/* LOADINGS --------------------------------------------  */
export const loadingLogin = (isLoading) => ({
  type: typesUi.loadingLogin,
  payload: isLoading,
});

export const loadingRegister = (isLoading) => ({
  type: typesUi.loadingRegister,
  payload: isLoading,
});

export const loadingPasswordReset = (isLoading) => ({
  type: typesUi.loadingPasswordReset,
  payload: isLoading,
});
export const loadingPasswordChange = (isLoading) => ({
  type: typesUi.loadingPasswordChange,
  payload: isLoading,
});
export const loadingCreatePassword = (isLoading) => ({
  type: typesUi.loadingCreatePassword,
  payload: isLoading,
});

/* EDITING INFORMATION ----------------------------------- */

export const editingUserInformation = (isEditing) => ({
  type: typesUi.editingUserInformation,
  payload: isEditing,
});
export const editingDeliveryInformation = (isEditing) => ({
  type: typesUi.editingDeliveryInformation,
  payload: isEditing,
});

/* TABS ACTIVATED ------------------------------------------ */
export const changeProfileTab = (tab) => ({
  type: typesUi.activatedProfileTab,
  payload: tab,
});

export const changeCartTab = (tab) => ({
  type: typesUi.activatedCartTab,
  payload: tab,
});

export const addToCart = (isNewAdd) => ({
  type: typesUi.addProductToCart,
  payload: isNewAdd,
});

/* ERROR MESSAGES */
export const errorMessageSelectedProducts = ({ show, message }) => ({
  type: typesUi.errorMessageSelectedProducts,
  payload: {
    show,
    message,
  },
});
