import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default_imgs } from '../../../data/ui';
import {
  updateSubTotal,
  setSubTotal,
  updateSubTotalCant,
  deleteProductCart,
} from '../../../redux/actions/e-commerce/sale';
import {
  addScreenModalDays,
  openModalProduct,
} from '../../../redux/actions/ui';

export const CartProduct = ({
  precio,
  nombre,
  imagen,
  isPlans = false,
  cantidad,
  meses,
  id,
  productsOf,
  subtotal_,
  preferencia,
  productos,
}) => {
  const cant = isPlans ? meses : cantidad;

  const [cant_, setCant_] = useState(cant);
  const [subTotal, setSubTotal_] = useState(subtotal_);
  const { subtotal } = useSelector((state) => state.sale);
  const dispatch = useDispatch();

  const validateQuantity = (num) => {
    const text_num = num + '';
    if (text_num.startsWith('0') && text_num.length > 1) return num;
    if (Math.sign(num) !== 1) return 0;
    if (Math.sign(num) === 0) return 1;
    return num;
  };
  const total = validateQuantity(precio);

  useEffect(() => {
    let cant_n = validateQuantity(cant);
    let subTotal_ = validateQuantity(precio);

    if (cant_n !== 0) {
      subTotal_ *= cant_;
      setSubTotal_(subTotal_.toFixed(2));
    }
  }, [cant_, precio, cant]);
  useEffect(() => {
    setCant_(cant);
  }, [cant]);

  useEffect(() => {
    const num_subtotal = parseFloat(precio) * cant_;

    dispatch(updateSubTotalCant(productsOf, cant_, num_subtotal, id));
  }, [subTotal]);

  const handleAdd = () => {
    let cant_n = validateQuantity(cant_);

    if (cant_n !== 0 || cant_n === 1) dispatch(updateSubTotal(total));

    setCant_((cant) => {
      let cant_n = validateQuantity(cant);
      if (cant_n === 0) return 1;
      if (cant_n !== 0) return validateQuantity(cant) + 1;
    });
  };

  const handleSubtract = () => {
    let cant_n = validateQuantity(cant_);

    if (cant_n !== 0 && cant_n !== 1) dispatch(setSubTotal(subtotal - total));

    setCant_((cant) => {
      let cant_n = validateQuantity(cant);
      if (cant_n === 0 || cant_n === 1) return 1;
      if (cant_n !== 0) return validateQuantity(cant) - 1;
    });
  };

  const deleteProduct = () => {
    dispatch(deleteProductCart(productsOf, id));
  };

  const updateNote = () => {
    dispatch(addScreenModalDays(productsOf));

    const product_ = {
      id,
      name: nombre,
      list: productos,
      image: imagen,
      note: preferencia,
    };
    dispatch(
      openModalProduct({
        product: product_,
        productsOf,
      })
    );
  };

  return (
    <div className="grid__card__products">
      <div className="card__product__image">
        <img
          src={`${imagen === '' ? default_imgs.product2 : imagen}`}
          alt={nombre}
          title={nombre}
        />
      </div>
      <div className="card__product__info price__total">
        <span className="text__total">Total:</span>
        <span>{precio}</span>
      </div>
      <div className="card__product__cant">
        <button onClick={handleSubtract}>-</button>
        <p>{cant_}</p>
        <button onClick={handleAdd}>+</button>
      </div>
      <div className="card__product__info price__subtotal">
        <span className="text__subtotal">Subtotal:</span>
        <span>{subTotal}</span>
      </div>
      <div className="card__product__info icon__delete" onClick={deleteProduct}>
        <i className="fas fa-times"></i>
      </div>
      <div className="card__product__info product__name">
        <span>{nombre}</span>
        <button className="btn btn__custom" onClick={updateNote}>
          <p>Más Información</p>
        </button>
      </div>
    </div>
  );
};
