import { ProductInformation } from './ProductInformation';
import { OrderNumber } from './OrderNumber';
import { OrderInformation } from './OrderInformation';
import { OrderPrices } from './OrderPrices';

export const ProductAccordeon = ({
  accordionActivated,
  showContent,
  toggle,

  pago,
  productos,
  tipo_documento,
  delivery_direccion,
  delivery_referencia,
  boleta_factura_link,
  delivery_precio,
  subtotal,
  total,
  creado,
  contador,
}) => {
  const link = boleta_factura_link ?? '';
  return (
    <div className="content__accordion">
      <OrderNumber
        action={toggle}
        open={accordionActivated}
        order_num={contador}
        date={creado}
      />
      {accordionActivated && (
        <div className="body">
          <OrderInformation
            type_document={tipo_documento}
            pago={pago}
            address={delivery_direccion}
            reference={delivery_referencia}
            link={link}
          />
          {productos.map((product, index) => (
            <ProductInformation {...product} key={index} />
          ))}
          <OrderPrices
            total={total}
            subtotal={subtotal}
            delivery={delivery_precio}
          />
        </div>
      )}
    </div>
  );
};
