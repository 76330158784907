import { Children } from 'react';
import { SkeletonCardProduct } from '../../../../components/skeleton/cards/SkeletonCardProduct';

export const TabAllProductsLoading = () => {
  const arr = [1, 2, 3];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container">
          <div className="col-lg-10 mx__auto">
            <div className="grid__category__product">
              {Children.toArray(arr.map(() => <SkeletonCardProduct />))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
