import { WrapperForm } from '../../wrappers/WrapperForm';
import { FormChangePass } from '../../forms';
import { NavbarMain } from '../../components/NavbarMain';

export const PageChangePass = () => {
  return (
    <>
      <NavbarMain />
      <WrapperForm image="change_pass" title="Cambiar contraseña">
        <FormChangePass />
      </WrapperForm>
    </>
  );
};
