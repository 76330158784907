import { Fragment } from 'react';

export const BuyDetailsActions = ({
  btnGoBack,
  btnGoNext,
  isLoading = false,
  step,
  textBtnGoNext = '',
}) => {
  return (
    <Fragment>
      <div className="actions">
        <button className="btn btn__outline__white" onClick={btnGoBack}>
          <i className="fas fa-arrow-left"></i>
          Volver
        </button>
        <button
          className={step === 2 ? 'btn btn__white' : 'btn btn__outline__white'}
          onClick={btnGoNext}
          disabled={isLoading}
        >
          {isLoading
            ? 'Cargando ..'
            : textBtnGoNext === ''
            ? 'Continuar'
            : textBtnGoNext}
          {textBtnGoNext !== '' ||
            (!isLoading && <i className="fas fa-arrow-right"></i>)}
        </button>
      </div>
    </Fragment>
  );
};
