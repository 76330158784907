import { Shimmer } from './Shimmer';

export const WrapperSkeleton = ({ theme, children }) => {
  const themeClass = theme ?? 'light';
  return (
    <div className={`skeleton__wrapper ${themeClass}`}>
      {children}
      <Shimmer />
    </div>
  );
};
