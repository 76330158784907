export const MessageError = ({
  message,
  my__2 = true,
  bg__dark = false,
  text__center = false,
}) => {
  return (
    <div
      className={`${my__2 ? 'my__2' : 'mt__2'} ${
        bg__dark ? 'content__msg__error__yellow' : ''
      }  ${text__center ? 'text__center' : ''}`}
    >
      <p className="text__error">
        <i className="fas fa-exclamation-triangle"></i>{' '}
        <span className="text__uppercase_letter">{message}</span>
      </p>
    </div>
  );
};
