import { useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../redux/actions/auth';

import { validateEmail } from '../helpers/validations';
import { useTooglePass } from '../hooks/useTooglePass';

import { MessageError } from '../components/messages';
import { Spinner } from '../components';
import { fieldErrorMessages } from '../data/ui';
import { toggleModalTermsNPolicity } from '../redux/actions/ui';

const initialState = {
  name: '',
  last_name: '',
  email: '',
  password: '',
  cellphone: '',
};

export const FormRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [messageError, setMessageError] = useState('');
  const [payload, setPayload] = useState(initialState);
  const { togglePass, showPass, inputPassword } = useTooglePass();
  const { loading } = useSelector((state) => state.ui);
  const check__terms__register = useRef('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageError('');

    const terms = check__terms__register.current.checked;

    if (!validateEmail(payload.email)) {
      setMessageError(fieldErrorMessages.invalidEmail);
      return;
    } else if (payload.password.length < 7) {
      setMessageError(fieldErrorMessages.invalidPasswordCharacters);
      return;
    } else if (Math.sign(payload.cellphone) !== 1) {
      setMessageError(fieldErrorMessages.invalidCel);
      return;
    } else if (terms === false) {
      setMessageError(fieldErrorMessages.enterTerms);
      return;
    } else {
      const payload_ = {
        ...payload,
        terms_and_conditions: terms && 1,
      };

      const resp = await dispatch(registerUser(payload_));

      if (resp.success) {
        setPayload(initialState);

        const loggedPage = sessionStorage.getItem('page-logged') ?? 'inicio';
        if (loggedPage === 'profile') {
          history.push('/usuario/mi-perfil');
        } else if (loggedPage === 'cart') {
          history.push('/carrito');
        }
      } else {
        setMessageError(resp.msg);
      }
    }
  };

  const openModalTerms = () => {
    dispatch(toggleModalTermsNPolicity(true, 'Terms'));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form__control">
        <label htmlFor="name">Nombres</label>
        <input
          type="text"
          placeholder="Ingrese sus nombres"
          value={payload.name}
          onChange={({ target }) =>
            setPayload((prev) => {
              return { ...prev, name: target.value };
            })
          }
          required
        />
      </div>
      <div className="form__control">
        <label htmlFor="name">Apellidos</label>
        <input
          type="text"
          placeholder="Ingrese sus apellidos"
          value={payload.last_name}
          onChange={({ target }) =>
            setPayload((prev) => {
              return { ...prev, last_name: target.value };
            })
          }
          required
        />
      </div>
      <div className="form__control">
        <label htmlFor="email">Correo electrónico</label>
        <input
          type="email"
          placeholder="Ingrese su correo"
          value={payload.email}
          onChange={({ target }) =>
            setPayload((prev) => {
              return { ...prev, email: target.value };
            })
          }
          required
        />
      </div>
      <div className="form__control">
        <label htmlFor="email">Celular</label>
        <input
          type="tel"
          placeholder="Ingrese su celular"
          value={payload.cellphone}
          onChange={({ target }) =>
            setPayload((prev) => {
              return { ...prev, cellphone: target.value };
            })
          }
          required
        />
      </div>

      <div className="form__control">
        <label htmlFor="pass">Contraseña</label>
        <div className="input__w__icon">
          <input
            type="password"
            placeholder="Ingrese su contraseña"
            ref={inputPassword}
            value={payload.password}
            onChange={({ target }) =>
              setPayload((prev) => {
                return { ...prev, password: target.value };
              })
            }
            required
          />
          {!showPass ? (
            <i className="fas fa-eye-slash" onClick={togglePass}></i>
          ) : (
            <i className="fas fa-eye" onClick={togglePass}></i>
          )}
        </div>
      </div>
      <div className="terms terms__register">
        <input
          ref={check__terms__register}
          type="checkbox"
          id="cbox1"
          value="first_checkbox"
        />
        <p>
          Acepto los &nbsp;
          <span className="link" onClick={openModalTerms}>
            Términos y Condiciones
          </span>
        </p>
      </div>
      {messageError !== '' && <MessageError message={messageError} />}
      {loading.register && <Spinner />}

      <div className="text__center mt__3">
        <button className="btn btn__outline__white">Crear cuenta nueva</button>
        <div className="mt__2">
          <Link to="/inicio-sesion" className="link font__klinicSlabbold">
            ¿Ya tienes una cuenta? Inicia sesión
          </Link>
        </div>
      </div>
    </form>
  );
};
