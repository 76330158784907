import { WrapperForm } from '../../wrappers/WrapperForm';
import { NavbarMain } from '../../components/NavbarMain';
import { FormCreatePassword } from '../../forms';
export const PageCreatePassword = () => {
  return (
    <>
      <NavbarMain />
      <WrapperForm image="register" title="Crea una nueva contraseña">
        <FormCreatePassword />
      </WrapperForm>
    </>
  );
};
