export const fieldErrorMessages = {
  enterName: 'Por favor ingrese su nombre',
  enterLastName: 'Por favor ingrese su apellido',
  enterDistrict: 'Por favor seleccione su distrito',
  enterTerms: 'Por favor acepte los términos y condiciones para registrarse',
  enterNroLte: 'Por favor ingrese su nro / lte',
  invalidAddress: 'Por favor ingrese una dirección válida',
  invalidAddressCharacters: 'Por favor ingrese una dirección',
  invalidNroLte: 'Por favor ingrese una nro / lte válido',
  invalidReference: 'Por favor ingrese una referencia válida',
  invalidEmail: 'Por favor ingrese un correo válido',
  invalidCel: 'Por favor ingrese un celular válido',
  enterCel: 'Por favor ingrese su nro de celular',
  invalidCelNumbers: 'Por favor ingrese un celular válido de 9 números',
  invalidDni: 'Por favor ingrese un dni válido',
  invalidDniNumbers: 'Por favor ingrese un dni válido de 8 números',
  invalidPasswordCharacters: 'La contraseña debe contener más de 6 caracteres',
};
