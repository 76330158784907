import { useSelector } from 'react-redux';
import { SectionFeatured } from '../../sections/SectionFeatured';
import { Categories } from './Categories';

export const TabAllProducts = () => {
  const {
    featured: { list, loading },
  } = useSelector((state) => state.products);
  return (
    <>
      <Categories />
      {/* <Products /> */}
      <SectionFeatured
        featured={list}
        loading={loading}
        products_of="all__products"
      />
    </>
  );
};
