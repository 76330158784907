import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalEmailError } from '../redux/actions/ui';
import { customStyles } from './customStyles';
export const ModalEmailError = () => {
  const {
    modals: { emailError },
  } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(toggleModalEmailError(false));
  };
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={emailError.isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 400,
        },
      }}
    >
      <div className="modal__body">
        <div className="modal__title center">
          <h1 className="title__big">Ooops!</h1>
        </div>
        <div className="divider__yellow mb__3"></div>
        <div className="modal__content m__0 text__center">
          <p className="font__klinicSlabmedium paragraph">
            No hay ninguna cuenta{' '}
            <span className="text__yellow">{emailError.email}</span> asociada a
            Don Santi
          </p>
        </div>
        <div className="modal__buttons text__center mt__3">
          <button className="btn btn__primary" onClick={closeModal}>
            Volver a Intentar
          </button>
        </div>
      </div>
    </Modal>
  );
};
