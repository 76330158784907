import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePersonalisedPlan } from '../../../../redux/actions/e-commerce/personalisedPlan';

import { CarouselTwoRows } from '../../../../components/carousels/listCarousels';
import { InfoSelectedProducts } from '../../../../components/cart/InfoSelectedProducts';
import { productsNames } from '../../../../data/ui';
import { formatteArrayElem } from '../../../../helpers/e_commerce';

export const TabBreadProducts = () => {
  const dispatch = useDispatch();
  const { breads } = useSelector((state) => state.products);
  const copy__breads = breads.list.slice();
  const list__breads = formatteArrayElem(copy__breads);
  const { selectedProductID_ } = breads;

  const {
    personalisedPlanBreadProducts: { products },
  } = useSelector((state) => state.plans);

  const validate = () => {
    dispatch(validatePersonalisedPlan(productsNames.tabBreads));
  };

  return (
    <Fragment>
      <div className="col-lg-11 mx__auto">
        <CarouselTwoRows
          arrayProducts={list__breads}
          loading={breads.loading}
          typesProduct={productsNames.tabBreads}
          selectedProductID={selectedProductID_}
        />
      </div>

      <InfoSelectedProducts
        text__button="Agregar nuevo plan al carrito"
        showDays
        showTimetables
        productsOf={productsNames.tabBreads}
        total={products.length > 0 ? '5.00' : '0'}
        list_products={products}
        action={validate}
      />
    </Fragment>
  );
};
