import { Children } from 'react';
import Carousel from 'react-elastic-carousel';

import { breakPointsCustomPlan } from '../../../helpers/breakPoints/';

import { ArrowLeft, ArrowRight, Dot } from '../parts';
import { CardCustomizedPlan } from '../../cards/CardCustomizedPlan';
import { SkeletonCardCustomizedPlan } from '../../skeleton/cards/SkeletonCardCustomizedPlan';

export const CarouselTwoRows = ({
  loading,
  typesProduct,
  arrayProducts,
  selectedProductID,
}) => {
  const skeletons = [1, 2, 3];
  let carousel;

  return (
    <Carousel
      outerSpacing={18}
      autoPlaySpeed={3000}
      itemPadding={[2, 4]}
      breakPoints={breakPointsCustomPlan}
      ref={(ref) => (carousel = ref)}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <div className="flex__dots__custom">
            {pages.length > 1 && (
              <ArrowLeft action={() => carousel.slidePrev()} />
            )}

            <div className="list__dots">
              {pages.length > 1
                ? pages.map((page) => {
                    const isActivePage = activePage === page;
                    return (
                      <Dot
                        action={() => onClick(page)}
                        activated={isActivePage}
                        key={page}
                      />
                    );
                  })
                : ''}
            </div>
            {pages.length > 1 && (
              <ArrowRight action={() => carousel.slideNext()} />
            )}
          </div>
        );
      }}
    >
      {loading ? (
        <div className="grid__products__customized">
          {Children.toArray(
            skeletons.map(() => <SkeletonCardCustomizedPlan />)
          )}
        </div>
      ) : (
        Children.toArray(
          arrayProducts.map((products) => (
            <div className="grid__products__customized">
              {products.map((product, index) => (
                <div className="product__customized" key={index}>
                  <CardCustomizedPlan
                    {...product}
                    selectedProductID={selectedProductID}
                    typesProduct={typesProduct}
                  />
                </div>
              ))}
            </div>
          ))
        )
      )}
    </Carousel>
  );
};
