import { Children, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartProductsHeader } from '../ProductsParts/CartProductsHeader';
import { CartProduct } from '../ProductsParts/CartProduct';
import { productsNames } from '../../../data/ui';
import { Fragment } from 'react';

export const CartPersonalisedBread = ({ divider }) => {
  const { arma_tu_pan } = useSelector((state) => state.sale);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const arr_personalised_bread = arma_tu_pan.map((bread) => {
      const { pan_producto_id, relleno_producto_id, ...bread_ } = bread;
      return {
        ...bread_,
        productsOf: productsNames.personalisedBread,
      };
    });

    setProducts(arr_personalised_bread);
  }, [arma_tu_pan]);
  return (
    <Fragment>
      {divider && <div className="divider my__2"></div>}
      <div className="info__products">
        <CartProductsHeader productsOf={productsNames.personalisedBread} />

        <div className="list__grid__card__products">
          {products.length > 0 &&
            Children.toArray(
              products.map((product) => <CartProduct {...product} />)
            )}
        </div>
      </div>
    </Fragment>
  );
};
