import { useSelector } from 'react-redux';
import { CarouselTwoRows } from '../../../../../components/carousels/listCarousels';
import { formatteArrayElem } from '../../../../../helpers/e_commerce';

export const TypesFillers = () => {
  const { fillers } = useSelector((state) => state.products);
  const copy__fillers = fillers.list.slice();

  const list__fillers = formatteArrayElem(copy__fillers);
  return (
    <div className="row mt__2">
      <div className="col-lg-9 mx__auto pl__0">
        <p className="font__klinicSlabbold paragraph mt__3 description subtitle__products">
          <span className="dot__custom"></span>
          Selecciona un tipo de relleno
        </p>
      </div>
      <div className="col-lg-11 mx__auto">
        <CarouselTwoRows
          loading={fillers.loading}
          typesProduct="fillers"
          arrayProducts={list__fillers}
          selectedProductID={fillers.selectedProductID}
        />
      </div>
    </div>
  );
};
