import { WrapperPage } from '../../../wrappers/WrapperPage';

import { Banner as TextFeatured } from '../../../components';

import { SectionDelicacies } from '../../../components/sections/SectionDelicacies';
import { SectionAboutUs } from './sections/SectionAboutUs';
import { SectionPlans } from './sections/SectionPlans';
import { SectionHistory } from './sections/SectionHistory';
import { Banner } from './Banner';

export const PageMain = () => {
  return (
    <WrapperPage bg__dark={false}>
      <Banner />
      <SectionAboutUs />
      {/* <SectionPlans /> */}
      <TextFeatured
        text="Experiencia única a través de todos tus sentidos, con un espacio que te hará sentir en familia."
        name="textFeatured"
      />
      <SectionDelicacies />
      <SectionHistory />
    </WrapperPage>
  );
};
