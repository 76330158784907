import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleTermsPolicy } from '../../../redux/actions/e-commerce/sale';
import {
  toggleModalMakePurchase,
  toggleModalTermsNPolicity,
} from '../../../redux/actions/ui';

export const CartPurchaseSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedIn } = useSelector((state) => state.auth);
  const { subtotal, privacy_policy } = useSelector((state) => state.sale);
  const [msgAcceptTerms, setMsgAcceptTerms] = useState(false);
  const checkbox = useRef('');
  const openModalTerms = () => {
    dispatch(toggleModalTermsNPolicity(true, 'Terms'));
  };

  const makepPurchase = () => {
    if (!privacy_policy) {
      setMsgAcceptTerms(true);
      return;
    }
    if (msgAcceptTerms) {
      setMsgAcceptTerms(false);
    }
    if (!loggedIn) {
      dispatch(toggleModalMakePurchase(true));
      return;
    }
    history.push('/usuario/detalles-compra');
  };

  const toggleTerms = () => {
    dispatch(toggleTermsPolicy(checkbox.current.checked));
  };
  return (
    <div>
      <div className="content__purchase__summary">
        <h3 className="title text__yellow">RESUMEN DE COMPRAS</h3>
        <div className="divider__yellow my__2"></div>
        <div className="info__purchase__summary">
          <div className="sub__total">
            <p>Subtotal</p>
            <p>S/ {subtotal.toFixed(2)}</p>
          </div>
          <div className="divider my__2"></div>
          <div className="total">
            <p className="price">S/ {subtotal.toFixed(2)}</p>
          </div>
          <div className="cart__message">
            <p>
              Antes de finalizar tu compra, podrás obtener el costo del delivery
              (según tu distrito) incluído en el total a pagar{' '}
            </p>
          </div>

          <div className="terms">
            <input
              type="checkbox"
              id="cbox1"
              ref={checkbox}
              value="first_checkbox"
              checked={privacy_policy}
              onChange={toggleTerms}
            />
            <p>
              Acepto los &nbsp;
              <span className="link" onClick={openModalTerms}>
                Términos y Condiciones
              </span>
            </p>
          </div>
          {msgAcceptTerms && (
            <div className="mb__2 text__center font__klinicSlabmedium text__yellow">
              <p>Por favor acepte los términos y condiciones</p>
            </div>
          )}
          <button className="btn btn__primary w__100" onClick={makepPurchase}>
            Realizar compra
          </button>
        </div>
      </div>
    </div>
  );
};
