import { Children, useEffect } from 'react';
import { Fragment, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyDetailsActions } from '../../../../components/buyDetails/BuyDetailsActions';
import { MessageError } from '../../../../components/messages';
import { fieldErrorMessages } from '../../../../data/ui';
// import { validateNumber } from '../../../../helpers/validations';
import { updateInfoDelivery } from '../../../../redux/actions/e-commerce';
import {
  calculateTotal,
  setTotal,
} from '../../../../redux/actions/e-commerce/sale';

export const DeliveryData = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const { delivery } = useSelector((state) => state.information);
  const { subtotal, total } = useSelector((state) => state.sale);
  const { districts } = useSelector((state) => state.ui);
  // const info = useSelector((state) => state.sale);
  const [errorMessage, setErrorMessage] = useState('');
  const [total_, setTotal_] = useState(total);
  const [isLoading, setLoading] = useState(false);
  const district = delivery.district_id ?? '';
  const [districtID, setDistrictID] = useState(district);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const ref__cellphone = useRef('');
  const ref__address = useRef('');
  const ref__nro__lte = useRef('');
  const ref__reference = useRef('');

  let changeDistrict = useRef('false');
  useEffect(() => {
    if (changeDistrict.current === 'false') {
      dispatch(calculateTotal());
      setTotal_(total);
    }
  }, [dispatch, total, changeDistrict]);

  useEffect(() => {
    if (district !== '') {
      const num_district = parseInt(district);
      setDistrictID(num_district);
      const price = districts.filter(
        (district) => district.id === num_district
      )[0].price;

      setDeliveryPrice(price);
    }
  }, [district, districts]);

  const handlerDistrict = ({ target }) => {
    changeDistrict.current = 'true';

    const num_district = parseInt(target.options[target.selectedIndex].value);
    const num_price = target.options[target.selectedIndex].dataset.price;
    setDistrictID(num_district);
    setDeliveryPrice(num_price);

    const total = parseFloat(num_price) + parseFloat(subtotal.toFixed(2));
    dispatch(setTotal(total));
    setTotal_(total);
  };

  const updateDeliveryInformation = async () => {
    setLoading(true);

    const cellphone = ref__cellphone.current.value;
    const address = ref__address.current.value;
    const nro__lte = ref__nro__lte.current.value;
    const reference = ref__reference.current.value;
    const delivery = {
      cellphone,
      district: districtID,
      lote: nro__lte,
      address,
      reference,
    };

    if (delivery.cellphone.length === 0) {
      delete delivery.cellphone;
    }
    if (delivery.reference.length === 0) {
      delete delivery.reference;
    }

    if (districtID === 0) {
      editingMessages(fieldErrorMessages.enterDistrict);
      return;
    } else if (address.length === 0) {
      editingMessages(fieldErrorMessages.invalidAddressCharacters);
      return;
    } else if (address.length < 10) {
      editingMessages(fieldErrorMessages.invalidAddress);
      return;
    } else if (nro__lte.length === 0) {
      editingMessages(fieldErrorMessages.enterNroLte);
      return;
    // } else if (nro__lte.length < 3 || Math.sign(nro__lte) !== 1) {
    //   editingMessages(fieldErrorMessages.invalidNroLte);
    //   return;
    // } else if (
    //   cellphone !== '' &&
    //   (cellphone.length < 1 ||
    //     !validateNumber(cellphone) ||
    //     Math.sign(cellphone) !== 1)
    // ) {
    //   editingMessages(fieldErrorMessages.invalidCel);
    //   return;
    // } else if (cellphone.length < 9 && cellphone !== '') {
    //   editingMessages(fieldErrorMessages.invalidCelNumbers);
    //   return;
    } else if (reference !== '') {
      if (reference.length < 10) {
        editingMessages(fieldErrorMessages.invalidReference);
        return;
      }
    }

    const res = await dispatch(updateInfoDelivery(delivery));
    if (res.success) {
      editingMessages('');
      nextStep();
    } else {
      editingMessages(res.msg);
    }
  };

  const editingMessages = (message) => {
    setErrorMessage(message);
    setLoading(false);
  };
  return (
    <Fragment>
      <section className="content__shopping__cart">
        <div>
          <h3 className="title text__yellow">DELIVERY</h3>
          <div className="divider__yellow my__2"></div>
          <div className="form__personal__data">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="form__control">
                    <label htmlFor="user_district">Distrito:</label>
                    <select onChange={handlerDistrict} value={districtID}>
                      <option value="" disabled>
                        Seleccione un distrito
                      </option>
                      {Children.toArray(
                        districts.map(({ id, name, price }) => (
                          <option data-price={price} value={id}>
                            {name}
                          </option>
                        ))
                      )}
                    </select>
                    <p className="text__yellow font__klinicSlabbold mt__1">
                      El precio del delivery dependerá del distrito de destino
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form__control">
                    <label htmlFor="user_nro_lte">Nro / Lte:</label>
                    <input
                      defaultValue={delivery?.lote}
                      type="number"
                      placeholder="Nro / Lte:"
                      ref={ref__nro__lte}
                    />
                  </div>
                </div>
                
                <div className="row">
                  <div className="form__control">
                    <label htmlFor="user_phone2">Otro teléfono (opcional):</label>
                    <input
                      defaultValue={delivery?.cellphone}
                      type="number"
                      ref={ref__cellphone}
                      placeholder="Ingrese su teléfono"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="form__control">
                    <label htmlFor="user_address">Dirección</label>
                    <textarea
                      rows="4"
                      placeholder="Detalles sobre su domicilio"
                      defaultValue={delivery?.address}
                      ref={ref__address}
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="form__control">
                    <label htmlFor="user_address_reference">
                      Referencia (opcional)
                    </label>
                    <textarea
                      ref={ref__reference}
                      defaultValue={delivery?.reference}
                      rows="4"
                      placeholder="Ayúdanos a encontrarte más fácilmente"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="content__prices">
                <div className="flex">
                  <p>Subtotal</p>
                  <p>S/ {subtotal.toFixed(2)}</p>
                </div>
                <div className="flex">
                  <p>Delivery</p>
                  <p>S/ {deliveryPrice}</p>
                </div>
                <div className="divider my__2"></div>
                <div className="flex text__yellow">
                  <p>Total</p>
                  <p>S/ {total_.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="row">
              <div className="col-lg-6 mx__auto">
                <MessageError
                  message={errorMessage}
                  bg__dark
                  my__2={false}
                  text__center
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <BuyDetailsActions
        step={step}
        isLoading={isLoading}
        btnGoBack={prevStep}
        btnGoNext={updateDeliveryInformation}
      />
    </Fragment>
  );
};
