import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModalMakePurchase } from '../redux/actions/ui';

import { customStyles } from './customStyles';
export const ModalMakePurchase = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    modals: { makePurchase },
  } = useSelector((state) => state.ui);
  const closeModal = () => {
    dispatch(toggleModalMakePurchase(false));
  };

  const goPageSingIn = () => {
    closeModal();
    sessionStorage.setItem('page-logged', 'cart');
    history.push('/inicio-sesion');
  };
  const goPageRegister = () => {
    closeModal();
    sessionStorage.setItem('page-logged', 'cart');
    history.push('/registro');
  };
  return (
    <Modal
      isOpen={makePurchase.isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          ...customStyles.content,
          maxWidth: 450,
        },
      }}
    >
      <div className="modal__body">
        <div className="modal__title">
          <h1>REALIZA TU COMPRA CON ÉXITO</h1>
          <i className="fas fa-times text__orange" onClick={closeModal}></i>
        </div>
        <div className="divider"></div>
        <div className="modal__content m__0">
          <div className="mt__2 text__center">
            <p className="font__klinicSlabbold paragraph">
              <span className="text__yellow">Regístrate</span> o &nbsp;
              <span className="text__yellow">Inicia Sesión</span> para una mejor
              experiencia de compra, múltiples beneficios y la mayor comodidad
              al adquirir tus productos.
            </p>
            <div className="modal__icons">
              <img
                src="../../images/decorations/icons/cart/frying_pan_icon.svg"
                className="icon"
                alt="Frying Pan Icon"
              />
              <img
                src="../../images/decorations/icons/cart/salt_icon.svg"
                className="icon"
                alt="Salt Icon"
              />
            </div>
            <div className="modal__buttons flex">
              <button className="btn btn__primary" onClick={goPageRegister}>
                Registrarse
              </button>
              <button className="btn btn__primary" onClick={goPageSingIn}>
                Iniciar Sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
