import { typesUi } from '../../types';

const initialState = {
  modals: {
    product_: {
      isOpen: false,
      product: {
        id: '',
        name: '',
        list: [],
        image: '',
        note: '',
      },
      productsOf: '',
    },
    days: {
      isOpen: false,
      productsOf: '',
      selectedDays: false,
      arr_days: [],
    },
    termsConditions: {
      isOpen: false,
      modalName: 'Terms',
    },
    makePurchase: {
      isOpen: false,
    },
    emailError: {
      isOpen: false,
      email: undefined,
    },
    productInCart: {
      isOpen: false,
    },
  },

  loading: {
    login: false,
    register: false,
    passReset: false,
    passChange: false,
    createPass: false,
  },

  cart: {
    addToCart: false,
  },

  editing: {
    delivery: false,
    user: false,
  },

  activatedTabs: {
    profile: sessionStorage.getItem('profileTab') ?? 'personal_data',
    menu: sessionStorage.getItem('cartTab') ?? 'all__menu',
  },

  errorMessages: {
    infoSelectedProducts: {
      message: '',
      show: false,
    },
  },

  districts: [],
  documentTypes: [],
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case typesUi.districts:
      return {
        ...state,
        districts: action.payload,
      };
    case typesUi.documentTypes:
      return {
        ...state,
        documentTypes: action.payload,
      };
    case typesUi.toggleModalDays:
      return {
        ...state,
        modals: {
          ...state.modals,
          days: {
            ...state.modals.days,
            isOpen: action.payload,
          },
        },
      };
    case typesUi.addScreenModalDays:
      return {
        ...state,
        modals: {
          ...state.modals,
          days: {
            ...state.modals.days,
            productsOf: action.payload,
          },
        },
      };
    case typesUi.addDaysModalDays:
      return {
        ...state,
        modals: {
          ...state.modals,
          days: {
            ...state.modals.days,
            arr_days: action.payload,
          },
        },
      };
    case typesUi.showSelectDays:
      return {
        ...state,
        modals: {
          ...state.modals,
          days: {
            ...state.modals.days,
            selectedDays: action.payload,
          },
        },
      };
    /* ----------------------------------------------------- */
    case typesUi.openModalProduct:
      return {
        ...state,
        modals: {
          ...state.modals,
          product_: {
            ...state.modals.product_,
            ...action.payload,
            isOpen: true,
            productsOf: action.payload.productsOf,
          },
        },
      };
    case typesUi.toggleModalProductInCart:
      return {
        ...state,
        modals: {
          ...state.modals,
          productInCart: {
            isOpen: action.payload,
          },
        },
      };
    case typesUi.closedModalProduct:
      return {
        ...state,
        modals: {
          ...state.modals,
          product_: {
            ...initialState.modals.product_,
          },
          days: {
            ...state.modals.days,
            isOpen: false,
            arr_days: [],
          },
        },
        errorMessages: { ...initialState.errorMessages },
      };

    case typesUi.toggleModalTermsNPolicity:
      return {
        ...state,
        modals: {
          ...state.modals,
          termsConditions: action.payload,
        },
      };
    case typesUi.toggleModalMakePurchase:
      return {
        ...state,
        modals: {
          ...state.modals,
          makePurchase: {
            isOpen: action.payload,
          },
        },
      };
    case typesUi.toggleModalEmailError:
      return {
        ...state,
        modals: {
          ...state.modals,
          emailError: action.payload,
        },
      };

    /* -----------------------------------------  */
    case typesUi.loadingLogin:
      return {
        ...state,
        loading: {
          ...state.loading,
          login: action.payload,
        },
      };
    case typesUi.loadingRegister:
      return {
        ...state,
        loading: {
          ...state.loading,
          register: action.payload,
        },
      };
    case typesUi.loadingPasswordReset:
      return {
        ...state,
        loading: {
          ...state.loading,
          passReset: action.payload,
        },
      };
    case typesUi.loadingPasswordChange:
      return {
        ...state,
        loading: {
          ...state.loading,
          passChange: action.payload,
        },
      };
    case typesUi.loadingCreatePassword:
      return {
        ...state,
        loading: {
          ...state.loading,
          createPass: action.payload,
        },
      };
    /* --------------------------------------------- */

    case typesUi.editingUserInformation:
      return {
        ...state,
        editing: {
          ...state.editing,
          user: action.payload,
        },
      };
    case typesUi.editingDeliveryInformation:
      return {
        ...state,
        editing: {
          ...state.editing,
          delivery: action.payload,
        },
      };
    /* ----------------------------------------------------- */
    case typesUi.activatedProfileTab:
      return {
        ...state,
        activatedTabs: {
          ...state.activatedTabs,
          profile: action.payload,
        },
      };
    case typesUi.activatedCartTab:
      return {
        ...state,
        activatedTabs: {
          ...state.activatedTabs,
          menu: action.payload,
        },
      };
    /* -------------------------------------------------- */

    case typesUi.errorMessageSelectedProducts:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          infoSelectedProducts: action.payload,
        },
      };

    /* ----------------------------------------------- */

    case typesUi.addProductToCart:
      return {
        ...state,
        cart: {
          addToCart: action.payload,
        },
      };
    default:
      return state;
  }
};
