import { Children } from 'react';
import { Gallery  , Item } from 'react-photoswipe-gallery'
import Carousel from 'react-elastic-carousel';
import img1 from '../../../images/sections/history/img-1.webp';
import img2 from '../../../images/sections/history/img-2.webp';
import img3 from '../../../images/sections/history/img-3.webp';
import img4 from '../../../images/sections/history/img-4.webp';
import img5 from '../../../images/sections/history/img-5.webp';
import img6 from '../../../images/sections/history/img-6.webp';
import img7 from '../../../images/sections/history/img-7.webp';
import img8 from '../../../images/sections/history/img-8.webp';

import { breakPointsHistoryImgs } from '../../../helpers/breakPoints';
import { ArrowLeft, ArrowRight, Dot } from '../parts';

export const CarouselHistory = () => {
  const arrImages1 = [img1];
  const arrImages2 = [img2];
  const arrImages3 = [img3];
  const arrImages4 = [img4];
  const arrImages5 = [img5];
  const arrImages6 = [img6];
  const arrImages7 = [img7];
  const arrImages8 = [img8];


  let carousel;
  return (
    <Carousel
      // enableAutoPlay
      // autoPlaySpeed={3000}
      outerSpacing={18}
      itemPadding={[0, 4]}
      breakPoints={breakPointsHistoryImgs}
      ref={(ref) => (carousel = ref)}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <div className="flex__dots__custom">
            {pages.length > 1 && (
              <ArrowLeft action={() => carousel.slidePrev()} />
            )}

            <div className="list__dots">
              {pages.length > 1
                ? pages.map((page) => {
                    const isActivePage = activePage === page;
                    return (
                      <Dot
                        action={() => onClick(page)}
                        activated={isActivePage}
                        key={page}
                      />
                    );
                  })
                : null}
            </div>
            {pages.length > 1 && (
              <ArrowRight action={() => carousel.slideNext()} />
            )}
          </div>
        );
      }}
    >
      <Gallery>
        {arrImages1.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages2.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages3.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages4.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages5.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages6.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages7.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
      <Gallery>
        {arrImages8.map((image,i) => {
          return(
            <Item
            key={i}
            original={image}
            thumbnail={image}
            width="500"
            height="500"
            >
            {({ ref, open }) => (
                  <img
                  alt="Fundadores de Don Santi"
                  ref={ref} onClick={open}
                  src={image}
                />
            )}
            </Item>
          )
        })}
      </Gallery>
    </Carousel>
  );
};
