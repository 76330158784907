import { Link } from 'react-router-dom';

export const Banner = ({ text, name }) => {
  const banner = {
    textFeatured: 'banner__section',
    delicacies: 'banner__page banner__delicias',
    profile: 'banner__page banner__profile',
    history: 'banner__page banner__history banner__pg__history',
    plans: 'banner__section banner__plans',
  };
  return (
    <section className={`container-fluid banner__container ${banner[name]}`}>
      {
        <div className="row">
          <div className="col-lg-9 mx__auto text__center">
            <div className="banner__content">
              <h1>{text}</h1>
              {name === 'plans' && (
                <Link to="plan-personalizado">
                  <button className="btn btn__primary mt__2">
                    Personalizar mi plan
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      }
    </section>
  );
};
