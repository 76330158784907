import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsPaginations } from '../../../../../redux/actions/e-commerce/products';
import { Products } from './Products';
import { buildPaginationNum } from '../../../../../helpers/e_commerce';
import { SectionCategories } from '../../sections/SectionCategories';

export const Categories = () => {
  const { categories } = useSelector((state) => state.products);
  const [step, setStep] = useState(1);
  const [categoryNameSelect, setCategoryNameSelect] = useState('');
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState(0);

  const nextStep = (id_category, nameCategory) => {
    setStep(step + 1);
    setCategoryNameSelect(nameCategory);
    setCategoryId(id_category);
    dispatch(getProductsPaginations(id_category));

    window.scroll({
      top: 730,
      left: 100,
      behavior: 'smooth',
    });
    setStep(step + 1);
  };

  const backStep = () => {
    setStep(step - 1);
  };
  if (step === 1) {
    return (
      <>
        <SectionCategories
          list={categories.list}
          nextStep={nextStep}
          loading={categories.loading}
          products_of="all__products"
        />
      </>
    );
  } else {
    return (
      <>
        <section className="container text__yellow  pointer" onClick={backStep}>
          <div className="row">
            <div className="col-lg-8 mx__auto title__card__products font__escafinaHigh title__lg">
              <i className="fas fa-arrow-left"></i>
              <span>Atrás</span>
            </div>
          </div>
        </section>

        <Products categoryId={categoryId} categoryName={categoryNameSelect} />
      </>
    );
  }
};
