import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { chosenPromotion } from '../../redux/actions/e-commerce/promotions';
import {
  addScreenModalDays,
  toggleModalProductInCart,
} from '../../redux/actions/ui';
import { validateLengthProductsCategories } from '../../redux/actions/e-commerce';
import Logo from '../../images/logos/logo.png';
import { validateValueEmpty } from '../../helpers/validations';
import {
  addKeyListCategories,
  productMatchingTitle,
} from '../../helpers/e_commerce';
import { default_imgs } from '../../data/ui/default_images';
import { productsNames } from '../../data/ui';
import { addProductToCart } from '../../redux/actions/e-commerce/products';
import { useSelector } from 'react-redux';

export const CardCustomisable = ({
  id,
  nombre,
  descripcion = '',
  imagen,
  precio,
  products_titles,
  productos,
  categories_titles = [],
  categorias,
  products_of,
  pronombres,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { promociones } = useSelector((state) => state.sale);
  const [promotionCategories, setPromotionCategories] = useState([]);
  const description = descripcion ?? '';

  useEffect(() => {
    if (categories_titles.length > 0) {
      const titles = [...new Set(categories_titles)];

      setPromotionCategories(
        titles.map((title) => title.charAt(0).toUpperCase() + title.slice(1))
      );
    }
  }, [categories_titles]);

  const openModal = () => {
    const product = {
      cantidad: 1,
      name: nombre,
      image: imagen ?? '',
      list: [],
      producto_id: id,
      precio,
    };
    dispatch(addProductToCart(product));
  };
  const selectedPromotion = () => {
    const products_name = promociones.map((p) => p.nombre);

    if (products_name.includes(nombre)) {
      dispatch(toggleModalProductInCart(true));
      return;
    }

    const products = productMatchingTitle(
      products_titles,
      productos,
      pronombres
    );
    // console.log('products ', products);
    dispatch(
      chosenPromotion({
        categories: addKeyListCategories(categorias),
        products,
        promotion_id: id,
        price: precio,
        promotion_name: nombre,
        image: validateValueEmpty(imagen) ? '' : imagen,
      })
    );
    dispatch(
      validateLengthProductsCategories({
        categories: categorias,
        isPlans: false,
      })
    );
    dispatch(
      validateLengthProductsCategories({
        categories: products,
        isPlans: false,
        isCategory: false,
      })
    );

    dispatch(addScreenModalDays(productsNames.promotions));

    history.push({
      pathname: '/categorias-productos',
      state: { list_products: 'promotions' },
    });
  };

  return (
    <div className="card__light">
      <div className="card__img">
        <img
          src={`${
            validateValueEmpty(imagen) ? default_imgs.product2 : imagen
          } `}
          className="delicia__bread"
          alt={nombre}
          title={nombre}
        />
        <img src={Logo} className="logo" alt="Logo" />
      </div>
      <div
        className={`card__body  ${
          (description?.length === 0 || description === '') &&
          products_of === 'all__products'
            ? 'card__body__title'
            : ''
        } ${products_of === 'all__products' ? '' : 'promos'}`}
      >
        <h1 className="card__title__cus" title={nombre}>
          {nombre?.length > 50 ? nombre.substr(0, 45) + '...' : nombre}
        </h1>
        {products_of === 'all__products' ? (
          description !== '' &&
          description &&
          !validateValueEmpty(description) &&
          description?.length > 500 ? (
            <p title={`${description}`}>{description.substr(0, 505) + '...'}</p>
          ) : (
            description.length !== 0 && (
              <p title={`${description}`}>
                {description.charAt(0).toUpperCase() + description.slice(1)}
              </p>
            )
          )
        ) : (
          <p>{description}</p>
        )}
      </div>
      <div className="card__footer">
        {products_of === 'all__products' ? (
          <button
            className="btn btn__outline__white btn__n__price"
            onClick={openModal}
          >
            Agregar al carrito <span className="price">S/ {precio}</span>
          </button>
        ) : (
          <button
            className="btn btn__outline__white btn__n__price"
            onClick={selectedPromotion}
          >
            Elegir promoción
            <br /> <span className="price">S/ {precio}</span>
          </button>
        )}
      </div>
    </div>
  );
};
