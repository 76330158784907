import { useSelector } from 'react-redux';

import { CarouselCustomisable } from '../carousels/listCarousels';

export const SectionDelicacies = ({ bg__dark = false }) => {
  const {
    featured: { list, loading },
  } = useSelector((state) => state.promotions);
  return (
    <section
      className={`container-fluid section__xl__py section__delicacies section__cards__light ${
        bg__dark ? 'bg__secundary' : 'bg__main'
      }`}
      id="section__promotions"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx__auto pb__xl__0">
            <Title />
          </div>
          <div className="col-lg-10 mx__auto pb__xl__0">
            <div className="row">
              <CarouselCustomisable
                list={list}
                loading={loading}
                name="delicies"
                products_of="promotions"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Title = () => {
  return (
    <div className="row mb__3">
      <div className="col-12 col-lg-4">
        <h2 className="font__escafinaHigh title__lg pb__md__2">
          Nuestras delicias y promociones
        </h2>
      </div>
      <div className="col-12 col-lg-8">
        <p className="font__klinicSlabbold paragraph">
          Lleva contigo una experiencia culinaria de la más alta calidad entre
          nuestra amplia variedad de alimentos, bebidas y bocadillos.
        </p>
      </div>
    </div>
  );
};
