export const ModalProductActions = ({ closeModal, addNote }) => {
  return (
    <div className="modal__buttons">
      <button className="btn btn__outline__primary" onClick={closeModal}>
        No, gracias
      </button>
      <button className="btn btn__primary" onClick={addNote}>
        Agregar nota
      </button>
    </div>
  );
};
