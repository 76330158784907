import { default_imgs } from '../../../../../data/ui';
import { productsNames } from '../../../../../data/ui/productsNames';

export const ProductInformation = ({
  name,
  cant = '',
  price,
  note,
  products,
  month,
  imagen = '',
  productOf,
  bread,
  fill,
  days,
}) => {
  return (
    <>
      <div className="container__product">
        <div className="product__image">
          <img
            src={imagen === '' ? default_imgs.product2 : imagen}
            alt={name}
            title={name}
          />
        </div>
        <div className="product__info">
          <p>{name}</p>

          <p>
            {productOf !== productsNames.plans
              ? 'Cant: ' + cant
              : 'Meses: ' + month}
          </p>

          {productOf === productsNames.personalisedBread && (
            <p>
              Pan: {bread} | Relleno: {fill}
            </p>
          )}

          {(productOf === productsNames.plans ||
            productOf === productsNames.promotions) && (
            <p>
              Productos:{' '}
              {products
                .map(({ producto_nombre }) => producto_nombre)
                .join('+ ')}
            </p>
          )}

          {productOf === productsNames.plans && (
            <p>Días de entrega: {days.join(', ')}</p>
          )}

          {note && <p>Nota: {note}</p>}
        </div>
        <div className="product__price">
          <p className="price">S/ {price}</p>
        </div>
      </div>
      <div className="divider mt__2"></div>
    </>
  );
};
