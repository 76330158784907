import { useSelector } from 'react-redux';

export const NoteField = ({ ref_note }) => {
  const {
    modals: {
      product_: { product },
    },
  } = useSelector((state) => state.ui);

  return (
    <div className="form__control">
      <textarea
        name="textarea"
        rows="5"
        ref={ref_note}
        placeholder="Agregue una nota adicional"
        defaultValue={product.note}
      ></textarea>
    </div>
  );
};
