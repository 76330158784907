import { Children } from 'react';
import { CardCategory } from '../../../../components/cards';
import { SkeletonCardCustomisable } from '../../../../components/skeleton/cards/SkeletonCardCustomisable';
import starLeft from '../../../../images/cards/categories/star_left.svg';
import starRight from '../../../../images/cards/categories/star_right.svg';

export const SectionCategories = ({
  list = [],
  loading,
  products_of,
  nextStep,
}) => {
  const skeletons = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <section className="container section__category">
        {products_of !== '' && (
          <div className="row">
            <div className="col-lg-8 mx__auto title__card__category">
              <img className="star" src={starLeft} alt="star" />
              <h1 className="font__escafinaHigh title__xxl text__yellow text__center mb__3">
                {products_of === 'promotions' && 'Promociones'}
                {products_of === 'all__products' && 'Nuestra Carta'}
              </h1>
              <img className="star" src={starRight} alt="star" />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-10 mx__auto">
            <div className="row">
              {loading
                ? Children.toArray(
                    skeletons.map(() => (
                      <div className="col-6 col-sm-6 col-lg-6 card__grid">
                        <SkeletonCardCustomisable />
                      </div>
                    ))
                  )
                : list.length > 0 &&
                  Children.toArray(
                    list.map((categorie, i) => (
                      <div className={'col-6  col-sm-6 col-lg-6 card__grid'}>
                        <CardCategory
                          i={i}
                          nextStep={nextStep}
                          {...categorie}
                        />
                      </div>
                    ))
                  )}
              {}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
