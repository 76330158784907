import { Children, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartProductsHeader } from '../ProductsParts/CartProductsHeader';
import { CartProduct } from '../ProductsParts/CartProduct';
import { productsNames } from '../../../data/ui';

export const CartProducts = () => {
  const { productos } = useSelector((state) => state.sale);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const arr_products = productos.map((product) => {
      const { producto_id, ...product_ } = product;
      return {
        ...product_,
        productsOf: productsNames.products,
      };
    });

    setProducts(arr_products);
  }, [productos]);
  return (
    <div className="info__products">
      <CartProductsHeader productsOf={productsNames.products} />

      <div className="list__grid__card__products">
        {products.length > 0 &&
          Children.toArray(
            products.map((product) => <CartProduct {...product} />)
          )}
      </div>
    </div>
  );
};
