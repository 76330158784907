import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfileTab } from '../../../../redux/actions/ui';
import { EditDeliveryInformation } from './information/EditDeliveryInformation';
import { EditUserInformation } from './information/EditUserInformation';
import { Tabs } from './information/Tabs';

export const SectionInfo = () => {
  const tabs = {
    tab1: 'personal_data',
    tab2: 'delivery_data',
  };
  const {
    activatedTabs: { profile },
    editing,
  } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  useEffect(() => {
    const profileTab = sessionStorage.getItem('profileTab');
    if (profileTab) {
      dispatch(changeProfileTab(profileTab));
    }
  }, [dispatch]);

  const changeTab = (tab) => {
    if (!editing.delivery && !editing.user) {
      sessionStorage.setItem('profileTab', tab);
      dispatch(changeProfileTab(tab));
    }
  };
  return (
    <section className="container section__info__user pt__0">
      <div className="row">
        <div className="col-lg-10 mx__auto">
          <div className="info__user__content">
            <Tabs
              tab1Activated={profile === tabs.tab1}
              action1Activated={() => changeTab(tabs.tab1)}
              tab2Activated={profile === tabs.tab2}
              action2Activated={() => changeTab(tabs.tab2)}
            />
            {profile === tabs.tab1 && <EditUserInformation />}
            {profile === tabs.tab2 && <EditDeliveryInformation />}
          </div>
        </div>
      </div>
    </section>
  );
};
